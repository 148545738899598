import React from 'react'
import { Document, Page, Text, View, StyleSheet, Image, Link } from '@react-pdf/renderer';import { dateFormat, displayBudget, payloadBudget } from '../../utils/helper';
;

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#F7FAFC',
    margin: 0,
    padding: 15,
    lineHeight: 1.5,
  },
  header: {
    backgroundColor: '#000',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    padding: 10,
    flexDirection: 'row',
  },
  headerImage: {
    width: 30,
  },
  title: {
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 10,
    fontSize: 20,
    fontWeight: 'bold',
    color: '#000',
  },
  section: {
    paddingHorizontal: 15,
    paddingVertical: 10,
  },
  toprow: {
    paddingHorizontal: 15,
    paddingVertical: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sectionTitle: {
    fontSize: 10,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: '#000',
    marginBottom: 5,
  },
  row: {
    flexDirection: 'col',
  },
  textBold: {
    fontSize: 10,
    fontWeight: 'bold',
    color: '#000',
  },
  text: {
    fontSize: 10,
    color: '#000',
  },
  summaryContainer: {
    backgroundColor: '#f3f4f6',
    borderRadius: 10,
    padding: 10,
    marginTop: 10,
  },
  summaryItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  summaryTotal: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    marginTop: 10,
  },
  contactInfo: {
    marginTop: 20,
    textAlign: 'center',
    color: '#000',
  },
  contentbox:{
    border:'1px solid #ccc',
    borderBottomLeftRadius:'10px',
    borderBottomRightRadius:'10px',
  }
});

const SubscriptionInvoicePdf = ({subscriptionDetails,FinalBid}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Image
          src="https://stripe-images.s3.amazonaws.com/emails/acct_1OhFFXF3HwezfEYa/5/twelve_degree_icon@2x.png"
          style={styles.headerImage}
        />
      </View>
      <View style={styles.contentbox}>
        <Text style={styles.title}>Receipt from SIDEHUSTL</Text>
        <View style={styles.toprow}>
          <View style={styles.row}>
            <Text style={styles.textBold}>Plan Amount</Text>
            <Text style={styles.text}>${(subscriptionDetails?.plan?.amount / 100)?.toFixed(2)}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.textBold}> Invoice Date</Text>
            <Text style={styles.text}>
            {dateFormat(subscriptionDetails?.latestInvoice?.created)}
            </Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Latest Invoice</Text>
          <View style={styles.summaryContainer}>
            <View style={styles.summaryItem}>
              <Text style={styles.textBold}>Invoice Date:</Text>
              <Text style={styles.text}>
              {dateFormat(
                  subscriptionDetails.intervalRecords[
                    subscriptionDetails.intervalRecords.length - 1
                  ].startDate
                )}
              </Text>
            </View>
            <View style={styles.summaryItem}>
              <Text style={styles.textBold}>Payment to SIDEHUSTL</Text>
              <Text style={styles.text}>
              ${FinalBid}
              </Text>
            </View>
            <View style={styles.summaryItem}>
              <Text style={styles.textBold}>Service fee</Text>
              <Text style={styles.text}>
              ${payloadBudget(`${ (0.18) * FinalBid}`)}
              </Text>
            </View>
            <View style={styles.summaryItem}>
              <Text style={styles.textBold}>Amount paid:</Text>
              <Text style={styles.text}>
              ${(subscriptionDetails?.plan?.amount / 100)?.toFixed(2)}</Text>
            </View>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={{fontSize:'10px',color:'#374151'}}>
            If you have any questions, contact us at{' '}
            <Link href="mailto:admin@sidehustl.ca" style={styles.contactInfo}>
              admin@sidehustl.ca
            </Link>
            .
          </Text>
        </View>
      </View>
    </Page>
  </Document>
);

export default SubscriptionInvoicePdf;
