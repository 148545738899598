import React, { useEffect, useState } from "react";
import { FaDollarSign } from "react-icons/fa";
// import HustlerReviews from "./HustlerReviews";
import { BsChatRightText, BsListTask } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import { authAxios } from "../../services/config";
import { toast } from "react-toastify";
import { AiFillHeart, AiOutlineCalendar, AiOutlineHeart } from "react-icons/ai";
import { useSelector } from "react-redux";
import { BudgetFormat, handleDecimalNumber } from "../../utils/helper";
import { TbMoneybag } from "react-icons/tb";
import { GrLocation } from "react-icons/gr";
import { setReportFormatDate } from "../../assets/Helper";
import { Tooltip } from "react-tooltip";
import IsLoadingHOC from "../../common/IsLoadingHOC";
import Wishlistmodal from "../become-a-hustler/Wishlistmodal";
import DeletewishlistModal from "../become-a-hustler/DeletewishlistModal";
import ShowInterestModal from "../become-a-hustler/ShowInterestModal";
import Pagination from "../../common/Pagination";
import ProfileReviews from "../../common/ProfileReview";

const DirectorProfileView = (props) => {
  const { setLoading } = props;
  const param = useParams();

  const { id } = param;

  const userType = useSelector((state) => state?.auth?.user?.userType)

  const { interestedTask } = useSelector((state) => state.app);
  const [userDetail, setuserDetail] = useState([]);
  const [comments, setcomments] = useState([]);
  const [Favoritehustlers, setFavoritehustlers] = useState([]);
  const [openTaskList, setOpenTaskList] = useState([]);
  const [wishlistJobs, setwishlistJobs] = useState([]);
  const [wishlistId, setwishlistId] = useState("");
  const [showInterestModal, setShowInterestModal] = useState(false);
  const [TaskDetails, setTaskDetails] = useState([]);
  const [taskid, setTaskId] = useState("");
  const [clickedItems, setClickedItems] = useState({});
  const [openModal, setopenModal] = useState(false);
  const [openDeleteModal, setopenDeleteModal] = useState(false);
  const [prevIntrestedTask, setPrevIntrestedTask] = useState("");
  const [appliedJobs, setappliedJobs] = useState([]);
  const [activeTab, setActiveTab] = useState("new-jobs");

  const [currentPage, setcurrentPage] = useState(1);
  const [ongoingPagination, setongoingPagination] = useState("new-jobs");
  const [postsPerPage, setpostsPerPage] = useState(10);
  const [totalPosts, settotalPosts] = useState(0);
  const paginate = (pageNumber) => setcurrentPage(pageNumber);

  const fetchuserDetail = async () => {
    await authAxios()
      .get(`users/get-specific-user/${id}`)
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          setuserDetail(resData?.data);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  // const fetchhustlerDetails = async () => {
  //   await authAxios()
  //     .get("/users/profile")
  //     .then((response) => {
  //       const resData = response.data;
  //       if (resData.status == 1) {
  //         const hustlerProvince = {
  //           label: resData.data.hustlerDetails.province,
  //           value: resData.data.hustlerDetails.province,
  //         };
  //         const hustlerCity = {
  //           label: resData.data.hustlerDetails.location,
  //           value: resData.data.hustlerDetails.location,
  //         };
  //         const data = {
  //           province: resData.data.hustlerDetails.province,
  //           location: resData.data.hustlerDetails.location,
  //         };
  //       } else {
  //         toast.error(resData?.message);
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error(error?.response?.data?.message);
  //     });
  // };


  const fetchallReview = async () => {
    await authAxios()
      .get(`review/specific-user-reviews/${id}`)
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          setcomments(resData.data);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const CheckFavoriteHustler = async () => {
    await authAxios()
      .get(`/fav-hustler/get-fav-hustler`)
      .then((response) => {
        const resData = response?.data;
        setFavoritehustlers(resData?.data?.hustlers);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const handleToggleFavorite = async () => {
    const CheckFavorite = Favoritehustlers?.some(
      (item) => item?.user?.id === id
    );

    if (CheckFavorite) {
      await authAxios()
        .delete(`/fav-hustler/remove-favorites/${id}`)
        .then((response) => {
          const resData = response.data;
          CheckFavoriteHustler();
          toast.success(resData.message);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      await authAxios()
        .post(`/fav-hustler/add-to-fav/${id}`)
        .then((response) => {
          const resData = response.data;
          CheckFavoriteHustler();
          toast.success(resData.message);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };

  const fetchDirectorOpenTask = async () => {
    await authAxios()
      .post(`task/get-all-director-open-task/${id}`)
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          setOpenTaskList(resData?.data)
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };


  const fetchAlltask = async (number) => {
    setLoading(true);
    await authAxios()
      .get(
        `/task/get-all-tasks-list?page=${number || currentPage
        }&limit=${postsPerPage}`
      )
      .then((response) => {
        setLoading(false);
        const resData = response?.data;
        if (resData.status == 1) {
          settotalPosts(resData?.count?.total);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const fetchWishlisttask = async (number) => {
    setLoading(true);
    await authAxios()
      .get(
        `/wishlist/users-wishlist?page=${number || currentPage
        }&limit=${postsPerPage}`
      )
      .then((response) => {
        setLoading(false);
        const resData = response?.data;
        if (resData.status == 1) {
          settotalPosts(resData?.count?.total);
          setwishlistJobs(resData?.data);
        } else {
          toast.error(resData?.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const fetchWishlistAllJobs = async () => {
    setLoading(true);
    await authAxios()
      .get(`/wishlist/users-wishlist`)
      .then((response) => {
        setLoading(false);
        const resData = response?.data;
        if (resData.status == 1) {
          setwishlistJobs(resData?.data);
        } else {
          toast.error(resData?.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  // const fetchappliedJobs = async (number) => {
  //   setLoading(true);
  //   await authAxios()
  //     .get(
  //       `taskproposal/hustler-interested-jobs?page=${number || currentPage
  //       }&limit=${postsPerPage}`
  //     )
  //     .then((response) => {
  //       setLoading(false);
  //       const resData = response?.data;
  //       if (resData.status == 1) {
  //         settotalPosts(resData?.count?.total);
  //         const filteredData = resData?.data.filter(
  //           (item) => !item.hasOwnProperty("cancellation")
  //         );
  //         setappliedJobs(filteredData);
  //       } else {
  //         toast.error(resData?.message);
  //       }
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       toast.error(error?.response?.data?.message);
  //     });
  // };

  const handleAddWishlistitem = async (id) => {
    setwishlistId(id);
    setopenModal(true);


    if (openModal == true) {
      setLoading(true);
      await authAxios()
        .post(`/wishlist/add-to-wishlist/${wishlistId}`)
        .then((response) => {
          setLoading(false);
          const resData = response.data;
          if (resData.status == 1) {
            setcurrentPage(1);
            toast.success(resData.message);
            fetchWishlistAllJobs();
            fetchAlltask(1);
            setcurrentPage(1);
            setopenModal(false);
            setClickedItems({ ...clickedItems, [id]: true });
          } else toast.error(resData.error);
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.response.data.message);
        });
    }
  };

  // const handleAddShowInterestItem = (item, interestedTask) => {
  //   setTaskId(item?._id);
  //   setTaskDetails(item);
  //   setShowInterestModal(true);
  //   if (interestedTask) {
  //     setPrevIntrestedTask(interestedTask);
  //   }
  // };

  const handleDeleteWishlistitem = async (id) => {
    setwishlistId(id);
    setopenDeleteModal(true);

    if (openDeleteModal == true) {
      setLoading(true);
      await authAxios()
        .delete(`/wishlist/remove-from-wishlist/${wishlistId}`)
        .then((response) => {
          setLoading(false);
          const resData = response.data;
          if (resData.status == 1) {
            toast.success(resData.message);
            setopenDeleteModal(false);
            if (ongoingPagination == "new-jobs") {
              setcurrentPage(1);
              fetchAlltask(1);
              fetchWishlistAllJobs();
            } else {
              setcurrentPage(1);

              fetchWishlisttask(1);
            }
          } else {
            toast.error(resData.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message);
        });
    }
  };

  useEffect(() => {
    fetchWishlistAllJobs();
    // fetchhustlerDetails();
  }, []);

  useEffect(() => {
    fetchDirectorOpenTask();
    fetchuserDetail();
    fetchallReview();
    if (userType == "DIRECTOR") {
      CheckFavoriteHustler();
    }

  }, []);

  return (
    <>
      <div className="h-100px"></div>
      {userDetail.userType === "HUSTLER" ? (
        <section className="container--profile--view intersted--hustler--profile">
          <div className="profile--view--header">
            <div className="profile--info">
              <div className="thumbnail--profile">
                <div className="thumbnail--profile--image">
                  <img
                    src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${userDetail?.profilePic}`}
                    alt=""
                  />
                </div>
                <div className="thumbnail--info">
                  <h2 style={{ marginBottom: "0px" }}>
                    {userDetail?.fname} {userDetail?.lname}
                  </h2>
                  <p className="location--hustler">
                    {userDetail?.hustlerDetails?.province},{" "}
                    {userDetail?.hustlerDetails?.location}
                  </p>
                  <div>
                    <p className="search--category mb-1">
                      <img
                        src="/assets/images/hustler--taskdone.png"
                        width={15}
                      />{" "}
                      {userDetail?.hustlerDetails?.completedTask} Completed Jobs
                    </p>

                    <p className="starting--price mb-1">
                      <img src="/assets/images/cancel.png" width={15} /> {""}
                      {userDetail?.hustlerDetails?.cancelledTask || 0} Cancelled
                      Jobs
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="add--favorite">
              <button
                onClick={handleToggleFavorite}
                className="custom--btn ligghtbtn-hover transparent--btn minbutton"
              >
                <span>
                  {Favoritehustlers?.some((item) => item?.user?.id === id)
                    ? "Remove from Favorites"
                    : "Add to Favorites"}
                </span>
              </button>

              <Link
                to={`/hustlr-booking/${userDetail?._id}`}
                className="custom--btn btn-dark darkbtn-hover minbutton text-center"
              >
                <span>
                  Hire Now
                </span>
              </Link>
            </div>
          </div>

          <div className="profile--sarvice--category">
            {userDetail?.hustlerDetails?.services.length > 0 && userDetail?.hustlerDetails?.services.map((service, index, array) => (
              <span key={service._id}>{service.serviceName}{index !== array.length - 1 && ', '}</span>
            ))}
          </div>

          <ul className="category--child">
            {userDetail?.hustlerDetails?.services.map(service => (
              <>
                {service.category.map(category => (
                  <li key={category._id}>
                    {category.label}
                  </li>
                ))}
              </>
            ))}
          </ul>

          <div className="profile--content--area">
            <div className="profile--content--leftside">
              <div className="profile--description mb-3">
                <p>{userDetail.description}</p>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="container--profile--view intersted--hustler--profile">
          <div className="profile--view--header">
            <div className="profile--info">
              <div className="thumbnail--profile">
                <div className="thumbnail--profile--image">
                  <img
                    src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${userDetail?.profilePic}`}
                    alt=""
                  />
                </div>
                <div className="thumbnail--info">
                  <h2>
                    {userDetail?.fname} {userDetail?.lname}
                  </h2>
                  <p className="location--hustler">
                    {userDetail?.directorDetails?.province},{" "}
                    {userDetail?.directorDetails?.location}
                  </p>
                  <div>
                    <p className="search--category mb-1">
                      <img
                        src="/assets/images/hustler--taskdone.png"
                        width={15}
                      />{" "}
                      {userDetail?.directorDetails?.completedTask || 0}{" "}
                      Completed Jobs
                    </p>

                    <p className="starting--price mb-1">
                      <img src="/assets/images/cancel.png" width={15} /> {""}
                      {userDetail?.directorDetails?.cancelledTask || 0} Closed
                      Jobs
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="profile--content--area">
            <div className="profile--content--leftside">
              <div className="profile--description mb-3">
                <p>{userDetail.description}</p>
              </div>
            </div>
          </div>

          <div className="director--task--open">
            <div className="list--tasks">
              {openTaskList && openTaskList?.length > 0 ? (
                openTaskList.map((item, index) => (
                  <div
                    key={index}
                    className="created--task--lists process--task--list"
                  >
                    <div
                      className="new-jobs new--jobs-h"
                      style={{ zIndex: "auto" }}
                    >
                      <span>
                        <BsListTask /> New Job
                      </span>
                    </div>
                    <div className="task--work">
                      <div
                        className="task--items--header"
                        style={{ marginBottom: "0px" }}
                      >
                        {/* <div className="task--add--info">
                          <div className="user--task--info">
                            <div className="task--info--img">
                              <Link
                                to={`/user-info/${item?.user?._id}`}
                              >
                                <img
                                  src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${item?.user?.profilePic}`}
                                />
                              </Link>
                            </div>
                            <div className="task--info--details">
                              <span>
                                {item?.user?.fname}{" "}
                                {item?.user?.lname}{" "}
                              </span>
                              <p>
                                <img src="../assets/images/hustler-star.png" />{" "}
                                {handleDecimalNumber(
                                  item?.user?.directorDetails?.ratings
                                )}{" "}
                              </p>
                            </div>
                          </div>
                          <h3>{item?.title}</h3>
                        </div> */}

                        {/* <div className="task--items--edit--delete">
                          <Tooltip
                            id="add--wishlist--task"
                            className="tooltip--task--wishlist"
                          />
                          <div
                            className="delete--taks--items wislishadd"
                            data-tooltip-id="add--wishlist--task"
                            data-tooltip-content={
                              wishlistJobs?.some(
                                (job) => job?.taskId === item?._id
                              )
                                ? "Remove Job"
                                : "Save Job"
                            }
                            data-tooltip-place="top"
                            onClick={() => {
                              if (
                                wishlistJobs?.some(
                                  (job) => job?.taskId === item?._id
                                )
                              ) {
                                handleDeleteWishlistitem(item._id);
                              } else {
                                handleAddWishlistitem(item._id);
                              }
                            }}
                          >
                            {wishlistJobs?.some(
                              (job) => job?.taskId === item?._id
                            ) ? (
                              <AiFillHeart />
                            ) : (
                              <AiOutlineHeart />
                            )}
                          </div>

                          <div className="edit--taks--items">
                            <button
                              className="custom--btn ligghtbtn-hover transparent--btn"
                              onClick={() =>
                                handleAddShowInterestItem(item)
                              }
                            >
                              <span>Show Interest</span>
                            </button>
                          </div>
                        </div> */}
                      </div>

                      <div className="task--items--details">
                        <div className="task--detail-full" style={{width:'100%'}}>
                          <Link to={`/job-info/${item?._id}`} style={{color:'#212529',textDecoration:'none'}}>
                          <h2
                            style={{
                              cursor: "pointer",
                              width:'100%',
                            }}
                          // onClick={() =>
                          //   handleAddShowInterestItem(item)
                          // }
                          >
                            {item?.title}
                          </h2>
                          </Link>
                          <div className="service--category--task">
                            <h3>{item?.serviceName}</h3>
                          </div>
                        </div>
                      </div>

                      <div className="task--items--content">
                        <p>
                          <span className="task--items--content--icon">
                            <AiOutlineCalendar />
                          </span>
                          {setReportFormatDate(item?.dueDate)}
                        </p>
                        <p>
                          <span className="task--items--content--icon">
                            <TbMoneybag />
                          </span>{" "}
                          Budget: ${BudgetFormat(`${item?.budget}`)}
                        </p>
                        <p>
                          <span className="task--items--content--icon">
                            <GrLocation />
                          </span>{" "}
                          Area: {item?.cityName}
                        </p>
                      </div>
                      <div className="task--items-footer">
                        {/* <p>{item?.description}</p> */}
                        <p>
                          {item?.description?.length > 250 ? `${item?.description?.substring(0, 250)}...` : `${item?.description}`}
                        </p>
                      </div>
                      <div className="task--items-application">
                        <p>
                          <img src="../assets/images/hustler--taskdone.png" />{" "}
                          {item.applications} Applications
                        </p>
                      </div>
                    </div>
                    {item?.oneTimePayment ? null : (
                      <div className="task--items-down--footer new--jobs-h">
                        <p>
                          Please note that this is a{" "}
                          <b>{item?.subscriptionInterval}ly</b> job,
                          and payment will be made on a{" "}
                          <b>{item?.subscriptionInterval}ly</b> basis
                          upon completion.{" "}
                        </p>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div>No new jobs to display</div>
              )}
            </div>

            <Pagination
              currentPage={currentPage}
              totalPosts={totalPosts}
              paginate={paginate}
              postsPerPage={postsPerPage}
            />
          </div>

        </section>
      )}
      <div className="custom--container m-auto">
        <ProfileReviews userDetail={userDetail} comments={comments} />
      </div>

      {openModal && (
        <Wishlistmodal
          setopenModal={setopenModal}
          handleAddWishlistitem={handleAddWishlistitem}
        />
      )}

      {openDeleteModal && (
        <DeletewishlistModal
          handleDeleteWishlistitem={handleDeleteWishlistitem}
          setopenDeleteModal={setopenDeleteModal}
        />
      )}

      {/* {showInterestModal && (
        <ShowInterestModal
          prevIntrestedTask={prevIntrestedTask}
          fetchWishlisttask={fetchWishlisttask}
          fetchAlltask={fetchAlltask}
          fetchappliedJobs={fetchappliedJobs}
          fetchhustlerDetails={fetchhustlerDetails}
          TaskDetails={TaskDetails}
          taskid={taskid}
          setLoading={setLoading}
          ongoingPagination={ongoingPagination}
          setShowInterestModal={setShowInterestModal}
          setcurrentPage={setcurrentPage}
          setongoingPagination={setongoingPagination}
          setActiveTab={setActiveTab}
        />
      )} */}
    </>
  );
};

export default IsLoadingHOC(DirectorProfileView);