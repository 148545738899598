import React, { useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'

const DirectorDeniedJobModel = ({handleDeniedReccuringProgressTask, setTaskDetail,TaskDetail,setshowDirectorDeniedJobModel, handleDeniedProgressTask }) => {
  const [formdata, setformdata] = useState(
    {
      reason: "",
    },
  );

  const handleChange = (e) => {
    const { name, value } = e.target;

    setformdata((prev) => ({
      ...prev,

      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if(TaskDetail?._id){
     handleDeniedReccuringProgressTask(formdata.reason)
    }else{
      handleDeniedProgressTask(formdata.reason)
    }

  //  

   

  };

  const handleClose=()=>{
    setshowDirectorDeniedJobModel(false)
    if(setTaskDetail){
      setTaskDetail([])
    }
   

  }
  return (
    <div>
      <div
        className={`deletemodal modal ${setshowDirectorDeniedJobModel ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-lg" role="document">
        <form onSubmit={handleSubmit}>
          <div className="modal-content">
            <div className="modal-header flex-column">
              <h4 className="modal-title w-100 text-center">Please Confirm</h4>
              <button
                type="button"
                className="close"


                onClick={ handleClose}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pt-5 pb-5 confirmation--modal">
              <h5 className="mb-0">
                Are you sure the Hustlr has not completed their job?
              </h5>
              <div class="request--task--work">
                <div class="field--div">
                  <label>Reason <span className="mendotry-field">*</span></label>
                  <TextareaAutosize
                    minRows={3}
                    maxRows={4}
                    value={formdata.reason}
                    onChange={handleChange}
                    required
                    name="reason"
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="custom--btn ligghtbtn-hover transparent--btn"
                //onClick={setshowDirectorDeniedJobModel}
                onClick={ handleClose}
              >
                <span>Cancel</span>
              </button>
              <button
                type="submit"
                className="btn btn-dark darkbtn-hover"
                
                // onClick={handleDeniedProgressTask}
              >
                <span>Submit</span>
              </button>
            </div>
          </div>
          </form>
        </div>
      </div>
      <div
        className={`modal-backdrop ${setshowDirectorDeniedJobModel ? "show" : ""}`}
      ></div>
    </div>
  )
}

export default DirectorDeniedJobModel