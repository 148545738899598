import React from "react";
import { Link } from "react-router-dom";
import { LuPartyPopper } from "react-icons/lu";
import { MdOutlineRemoveRedEye, MdReviews } from "react-icons/md";
import { AiOutlineCalendar } from "react-icons/ai";
import { TbMoneybag } from "react-icons/tb";
import { GrLocation } from "react-icons/gr";

const DirectorJobCompleted = () => {
  const staticData = {
    hustlerId: "12345",
    hustlerName: "HUSTLR PROFILE",
    hustlerProfilePic: "/assets/images/hustler-profile-img/hustler--profile-4.png",
    hustlerRatings: 4.5,
    directorReviewed: false,
    hustlerReviewed: false,
    taskId: "67890",
    taskTitle: "I need my lawn mowing",
    serviceName: "lawn mowing",
    budget: "65.00",
    dueDate: "2024-12-01",
    endDate: "2024-12-10",
    cityName: "Kamloops",
    description: "My lawn is 100 meter square",
    oneTimePayment: true,
    subscriptionInterval: "monthly",
  };

  return (
    <div className="created--task--lists process--task--list">
      <div className="mark-status">
        <span>
          <LuPartyPopper /> Completed Job
        </span>
      </div>

      <div className="task--work">
        <div className="task--items--header" style={{ marginBottom: "0px" }}>
          <div className="task--add--info">
            <div className="user--task--info">
              <div className="task--info--img">
                <Link to={`/user-info/${staticData.hustlerId}`}>
                  <img src={staticData.hustlerProfilePic} alt="Hustler" />
                </Link>
              </div>

              <div className="task--info--details">
                <span>{staticData.hustlerName}</span>
                <br />
                <p>
                  <img src="../assets/images/hustler-star.png" alt="Rating" />
                  {staticData.hustlerRatings}
                </p>
              </div>
              
            </div>
          </div>
          <div>
            <div className="task--items--edit--delete completed-jobs">
              {staticData.directorReviewed ? (
                <button className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn">
                  <span>
                    <MdOutlineRemoveRedEye /> My Review
                  </span>
                </button>
              ) : (
                <button className="page-twelve-element custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn">
                  <span>
                    <MdReviews /> Add Review
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>

        {/* <div className="task--items--details">
          <h2>{staticData.taskTitle}</h2>
          <div className="service--category--task">
            <h3>{staticData.serviceName}</h3>
          </div>
        </div> */}
        <div className="task--items--details">
                            <div
                              className="task--detail-full"
                              style={{ width: "100%" }}
                            >
                              <Link
                                // to={`/task/${item?.task?._id}`}
                                style={{
                                  color: "#212529",
                                  textDecoration: "none",
                                }}
                              >
                                <h2 className="hover-text">{staticData.taskTitle}</h2>
                              </Link>
                              <div className="service--category--task">
                                <h3> {staticData.serviceName}</h3>
                              </div>
                            </div>
                          </div>

        <div className="task--items--content">
          <p>
            <span className="task--items--content--icon">
              <AiOutlineCalendar />
            </span>
            {staticData.dueDate} to {staticData.endDate}
          </p>
          <p>
            <span className="task--items--content--icon">
              <TbMoneybag />
            </span>
            Budget: ${staticData.budget}
          </p>
          <p>
            <span className="task--items--content--icon">
              <GrLocation />
            </span>
            Area: {staticData.cityName}
          </p>
        </div>

        <div className="task--items-footer">
          {staticData.description}
        </div>
      </div>
    </div>
  );
};

export default DirectorJobCompleted;
