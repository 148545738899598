// import React from "react";
import React, { useState } from 'react';



const DirectBookingPopup = ({ handleCancelPopup, handleSubmit }) => {
  const [hoverState, setHoverState] = useState(false);

  const handleHoverEnterCancel = () => setHoverState("cancelActive");
  const handleHoverEnterSend = () => setHoverState("sendActive");
  const handleHoverLeave = () => setHoverState(false);


  return (
    <div>
      <div
        className={`deletemodal modal ${handleCancelPopup ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-lg page-nineteen-element" role="document">
          <div className="modal-content">
            <div
              className="modal-header flex-column"
            >
              <h4 className="modal-title w-100 text-center">
                Please Confirm
              </h4>
              <button
                type="button"
                className="close"
                onClick={handleCancelPopup}
                // onClick={()=>handleCancelPopup(false)}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="modal-body confirmation--modal"
              style={{ paddingTop: "0px !important" }}
            >
              <h5 className="mb-0">
              Are you sure you want to send a job request to this Hustlr?
                {/* Are you sure you want to hire this hustlr? */}
              </h5>
            </div>
            {/* <div className="modal-footer">
              <button
                type="button"
                className="custom--btn ligghtbtn-hover transparent--btn"
                // className="btn btn-dark darkbtn-hover"

                onClick={handleCancelPopup}
                // onClick={()=>handleCancelPopup(false)}
              >
                <span>Cancel</span>
              </button>
              <button
                type="button"
                className="btn btn-dark darkbtn-hover"
                // className="custom--btn ligghtbtn-hover transparent--btn"

                onClick={handleSubmit}
              >
                <span>Send</span>
              </button>
            </div> */}

<div className="modal-footer">
      <button
        type="button"
        className={hoverState === false ?`custom--btn ligghtbtn-hover transparent--btn` :
          hoverState === "cancelActive" ?`btn btn-dark `:
          `custom--btn ligghtbtn-hover transparent--btn`
          }
          // className={hoverState === "cancelActive" ? "btn btn-dark darkbtn-hover" : "custom--btn ligghtbtn-hover transparent--btn"}

        onClick={handleCancelPopup}
        onMouseEnter={handleHoverEnterCancel}
        onMouseLeave={handleHoverLeave}
      >
        <span>Cancel</span>
      </button>
      <button
        type="button"
        // className={`btn ${hoverState === "sendActive" ? "btn-dark darkbtn-hover" : "custom--btn ligghtbtn-hover transparent--btn"}`}
        className={hoverState === false ?`btn btn-dark` :
          hoverState === "cancelActive" ?`custom--btn ligghtbtn-hover transparent--btn`:
          `btn btn-dark `
          }

        onClick={handleSubmit}
        onMouseEnter={handleHoverEnterSend}
        onMouseLeave={handleHoverLeave}
      >
        <span>Send</span>
      </button>
    </div>
            
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop ${handleCancelPopup ? "show" : ""}`}
      ></div>
    </div>
  );
};

export default DirectBookingPopup;
