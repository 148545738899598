import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { authAxios } from "../../services/config";
import { toast } from "react-toastify";
import { BudgetFormat, displayBudget, showDecimalNumber } from "../../utils/helper";
import { Link } from "react-router-dom";

const PaymentFormStart = ({ paymentIntentId, setshowPaymentForm, finaltaskDetails, onSuccess, handleCancelPopup }) => {

  return (
<>
  <div>
    <div
      className="deletemodal modal show "
    //   tabIndex="-1"
      role="dialog"
    >
      <div className="page-eight-element modal-dialog modal-lg " role="document">
        <div className="modal-content">
          <div className="modal-header flex-column">
            <h4 className="  modal-title w-100 text-center">
              Please Confirm Your Payment
            </h4>
            <button
              type="button"
              className="close"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body pt-5 pb-5 confirmation--modal">
            <form>
              <div
                className="request--task--work"
                style={{ marginTop: 0, marginBottom: '10px' }}
              >
                <p className="mb-0">
                  <b>Task Budget:</b> $500
                </p>
                <p className="mb-0">
                  <b>Service Fee (18%):</b> $90
                </p>
                <p className="mb-0">
                  <b>Total Payment:</b> $590
                </p>
              </div>
              <img 
  src="/assets/images/cardadd.png"  // No need for `process.env.PUBLIC_URL`
  alt="Card Image"
        style={{ maxWidth: '100%', height: 'auto' }} 
      />

              {/* <div className="payment-card">
                <p>Card number</p>

              </div> */}
              
              
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="button--link custom--btn ligghtbtn-hover transparent--btn"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span>Cancel</span>
            </button>
            <button
              type="button"
              className="btn btn-dark darkbtn-hover"
            >
              <span>Pay Now</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="modal-backdrop show"></div>
  </div>
</>
  );
};

export default PaymentFormStart;
