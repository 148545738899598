import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AiOutlineCalendar, AiOutlineHeart } from "react-icons/ai";
import { GrLocation } from "react-icons/gr";
import { IoCloseSharp } from "react-icons/io5";
import { TbMoneybag } from "react-icons/tb";
import Select from "react-select";
import { authAxios } from "../../services/config";
import { toast } from "react-toastify";
import { CancelReasons } from "../../utils/helper";

const CanceljobModal = ({ setshowCanceljobModal, CancelTask }) => {
  const [selectedService, setSelectedService] = useState(null);
  const taskOptions = useSelector((state) => state.app.tasks);

  const handleSelectTask = (option) => {
    setSelectedService(option);
  };
  const handleClick = (value) => {
    setSelectedService(value);
  };

  const [formData, setformData] = useState({
    reasons: [],
    comment: "",
  })


  const handleCheckboxChange = (reason) => {
    setformData((prev) => {
      const updatedReasons = [...prev.reasons];

      // Check if the reason is already in the array
      const index = updatedReasons.indexOf(reason);

      // If it's not in the array, add it; otherwise, remove it
      if (index === -1) {
        updatedReasons.push(reason);
      } else {
        updatedReasons.splice(index, 1);
      }

      return {
        ...prev,
        reasons: updatedReasons,
      };
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setformData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  const cancelJobsHandle = (e) => {
    e.preventDefault()
    CancelTask(formData)
  }



  return (
    <>
      <div className="show--interest--modal--overlay">
        <div className="show--interest--modal" style={{ height: 'auto' }}>
          <div className="showInsterest--header">
            <h2 className="playFairDisplay heading-h3">Cancel Job</h2>
            <div className="close--popup" onClick={() => setshowCanceljobModal(false)}>
              <IoCloseSharp />
            </div>
          </div>

          <form onSubmit={cancelJobsHandle} className="showInsterest--cancel showInsterest--content--area">
            <div className="showInsterest--content--area--left" style={{maxWidth:'100%'}}>
              <div className="task--description--info">
                <p className="director--reviews--heading">
                  Please Select a reasons
                </p>

                <ul>
                  {CancelReasons && CancelReasons.map((item) => (
                    <>
                      <li onChange={() => handleCheckboxChange(item)}>
                        <div className="checkbox--custom" >
                          <input
                            type="checkbox"

                            checked={formData.reasons.includes(item)}
                          />
                          <span className="checkmark"></span>
                        </div>
                        <span>
                          {item}
                        </span>
                      </li>
                    </>
                  ))}
                </ul>
              </div>

              <div className="showInsterest--form">


                <div className="showInsterest--textarea">
                  <h3 className="mb-3">Add your Reason <span className="mendotry-field">*</span></h3>
                  <textarea required name="comment" onChange={handleChange} value={formData.comment}   >

                  </textarea>
                </div>

                <div class="sign--btn flex items-center hide--mb justify-content-between mt-4 mb-2 cancelJob--form">
                  <button type="submit" class="btn transparent-btn btn--black--hover">
                    <span >Cancel Job</span>
                  </button>
                  <a
                    class="btn btn-light ligghtbtn-hover"
                    onClick={() => setshowCanceljobModal(false)}
                  >
                    <span>Exit</span>
                  </a>
                </div>
              </div>
            </div>
          </form>

        </div>
      </div>
    </>
  );
};

export default CanceljobModal;
