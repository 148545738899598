import React, { useEffect, useState } from "react";
import Select from "react-select";
import { IoIosCloseCircleOutline, IoIosSearch } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { CanadianCity } from "../../utils/constants";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { Tooltip } from "antd"; // Import Tooltip from antd

import {
  BsChatRightText,
  BsChevronDown,
  BsListCheck,
  BsListTask,
  BsSend,
} from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import {
  AiFillHeart,
  AiOutlineCalendar,
  AiOutlineHeart,
  AiOutlineInfoCircle,
} from "react-icons/ai";
import { TbMoneybag } from "react-icons/tb";
import ShowOfferModal from "./ShowOfferModal";
import { GrLocation } from "react-icons/gr";
import { BiSolidCaretLeftSquare } from "react-icons/bi";
import FilterRightSide from "./FilterRightSide";
import { authAxios, withoutAuthAxios } from "../../services/config";
import { toast } from "react-toastify";
import { setReportFormatDate } from "../../assets/Helper";
import ShowInterestModal from "./ShowInterestModal";
import Wishlistmodal from "./Wishlistmodal";
import DeletewishlistModal from "./DeletewishlistModal";
import IsLoadingHOC from "../../common/IsLoadingHOC";
import CanceljobModal from "./CanceljobModal";
import { saveServices, saveTaskOptions } from "../../Redux/Reducers/appSlice";
import { State, City } from "country-state-city";
import { PiEye } from "react-icons/pi";
import IsLoggedinHOC from "../../common/IsLoggedInHOC";
import ShowIntersetDisableModal from "../../common/Modal/ShowIntersetDisableModal";
import ShowCancelDisableModal from "../../common/Modal/ShowCancelDisableModal";
import { IoInformationCircleOutline } from "react-icons/io5";
import {
  BudgetFormat,
  displayBudget,
  handleDecimalNumber,
  payloadBudget,
  staticHustlerFilter,
  toCamelCase,
} from "../../utils/helper";
import { LuListChecks, LuPartyPopper } from "react-icons/lu";
import { GoArrowRight, GoCheckbox } from "react-icons/go";
import { Tooltip } from "react-tooltip";
import { ImCancelCircle } from "react-icons/im";
import CounterOfferJobModel from "../../common/Modal/CounterOfferJobModel";
import { useSocket } from "../../contexts/SocketContext";
import Pagination from "../../common/Pagination";
import AcceptIncomingJobModel from "./AcceptIncomingJobModel";
import { FaRegCircleCheck } from "react-icons/fa6";
import ShowRejectedReason from "./ShowRejectedReason";
import CancelIncomingJobModel from "./CancelIncomingJobModel";
import ConfirmDeniedJobModel from "../../common/ConfirmDeniedJobModel";
import HustlerHomeSave from "./HustlerHomeSave";
import HustlerHomeNewJob from "./HustlerHomeNewJob";

  
  const HustlerHomeApply = () => {

    return (
        <div
        // key={index}
        className="created--task--lists process--task--list"
      >
                      <div
                className="confirmation-task"
                style={{ zIndex: "auto" }}
              >
                <span>
                  <BsListCheck /> Applied Job
                </span>
              </div>


{/* 
        {
        item?.directorInvitation === true ?
         (

          <div className="waiting--msg" 
          key={index}>
            <span>
              <AiOutlineInfoCircle /> Creatr has sent you
              an offer!
            </span>
            <span
              className="view--reason"
              onClick={() =>
                handleViewOffer(item.taskDetails)
              }
            >
              View Offer <GoArrowRight />
            </span>
          </div>
        ) : (
          <>
            {item.taskDetails.status == "open" ? (
              <div
                className="confirmation-task"
                style={{ zIndex: "auto" }}
              >
                <span>
                  <BsListCheck /> Applied Job
                </span>
              </div>
            ) 
            : (
              <>
                {item?.taskDetails?.status ===
                "in-progress" ? (
                  <div
                    className="confirmation-task"
                    style={{ zIndex: "auto" }}
                  >
                    <span>
                      <BsListCheck />
                      This Job is assigned to another
                      Hustlr
                    </span>
                  </div>
                ) : item?.taskDetails?.status ===
                  "completed" ? (
                  <div
                    className="confirmation-task"
                    style={{ zIndex: "auto" }}
                  >
                    <span>
                      <BsListCheck /> This Job is
                      completed by another Hustlr
                    </span>
                  </div>
                ) : item?.taskDetails?.status ===
                  "assigned" ? (
                  <div
                    className="confirmation-task"
                    style={{ zIndex: "auto" }}
                  >
                    <span>
                      <BsListCheck /> This job has been
                      directly assigned to another Hustlr
                    </span>
                  </div>
                ) : item?.taskDetails?.status ===
                  "closed" ? (
                  <div
                    className="confirmation-task"
                    style={{ zIndex: "auto" }}
                  >
                    <span>
                      <BsListCheck /> This Job is closed
                      by Creatr
                    </span>
                  </div>
                ) : null}
              </>
            )}
          </>
        )} */}



        <div className="task--work">
          <div
            className="task--items--header"
            style={{ marginBottom: "0px" }}
          >
            <div className="task--add--info">
              <div className="user--task--info">
                <div className="task--info--img">
                  <Link
                    // to={`/user-info/${item?.directorId}`}
                  >
                    <img
                      src="../assets/images/hustler-profile-img/hustler--profile-1.png"

                    // src="public\assets\images\hustler-profile-img\hustler--profile-1.png"
                    //   src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${item?.taskDetails?.directorDetails?.profilePic}`}
                    />
                  </Link>
                </div>
                <div className="task--info--details">
                  <span>

                    {/* {
                      item?.taskDetails?.directorDetails
                        ?.name
                    } */}
                    CREATR MALIK
                  </span>
                  <p>
                    <img src="../assets/images/hustler-star.png" />{" "}
                    5.0
                    {/* {handleDecimalNumber(
                      item?.taskDetails?.directorDetails
                        ?.ratings
                    )} */}
                  </p>
                </div>
              </div>
              {/* <h3>{item?.taskDetails?.title}</h3> */}
            </div>
            <div className="task--items--edit--delete">
              {
            //   item.cancellation 
            false
              ? (
                <>
                  <div
                    className="delete--taks--items wislishadd"
                    style={{
                      // width: "55px",
                      background: "var(--darkblack)",
                      color: "var(--white)",
                    }}
                    // onClick={() =>
                    //   handleShowCancelDisable(item)
                    // }
                  >
                    <PiEye />
                  </div>
                  <span className="cancelled--interested-job">
                    <IoInformationCircleOutline />{" "}
                    Cancelled Job
                  </span>
                </>
              ) : (
                <>
                  <div
                    className="button--lists-view"
                    style={{ display: "flex" }}
                  >
                    {
                    // item.taskDetails.status 
                    // ==
                    //   "open"
                      true && (
                      <>
                        {" "}
                        <button
                        //   onClick={() =>
                        //     handleShowDisableInterest(
                        //       item
                        //     )
                        //   }
                          className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                        >
                          <span>
                            <PiEye /> View Details
                          </span>
                        </button>
                        <button
                        //   onClick={() =>
                        //     handleViewCounterOffer(item)
                        //   }
                          className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                        >
                          <span>
                            <BsSend /> View Offer
                          </span>
                        </button>
                        <button
                        //   onClick={() =>
                        //     handleCancelPopup(item?._id)
                        //   }
                          className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                        >
                          <span>
                            <ImCancelCircle /> Cancel Job
                          </span>
                        </button>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="task--items--details">
            <div
              className="task--detail-full"
              style={{ width: "100%", marginTop: "10px" }}
            >
              <h2>
                I need my lawn mowing
                {/* {item?.taskDetails?.title} */}
                </h2>
              <div className="service--category--task">
                <h3>
                    lawn mowing
                    {/* {item?.taskDetails?.serviceName} */}
                    </h3>
              </div>
            </div>
          </div>

          <div className="task--items--content">
            <p>
              <span className="task--items--content--icon">
                <AiOutlineCalendar />
              </span>
              01 Jan 2024 to 10 Jan 2024

              {/* {setReportFormatDate(
                item?.taskDetails?.dueDate
              )} */}

          {/* { item?.taskDetails?.dueDate ?
       (
        <> {setReportFormatDate(item?.taskDetails?.dueDate)} to  {setReportFormatDate(item?.taskDetails?.endDate)}</>)
       :(setReportFormatDate(item?.taskDetails?.dueDate))
       } */}
            </p>
            <p>
              <span className="task--items--content--icon">
                <TbMoneybag />
              </span>{" "}
              Budget: $50.00

            </p>
            <p>
              <span className="task--items--content--icon">
                <GrLocation />
              </span>{" "}
              Area: Kamloops

              {/* {item?.taskDetails?.cityName} */}
            </p>
          </div>
          <div className="task--items-footer">
            <p>
              {/* {item?.taskDetails?.description?.length >
              250
                ? `${item?.taskDetails?.description?.substring(
                    0,
                    250
                  )}...`
                : `${item?.taskDetails?.description}`} */}
            </p>
          </div>
          <div className="task--items-application">
            <p>
              <img src="../assets/images/hustler--taskdone.png" />{" "}
              {/* {item?.applications} */}
               Applications
            </p>
          </div>
        </div>
        {/* {item?.taskDetails?.oneTimePayment ? null : (
          <div className="task--items-down--footer confirmation-task">
            <p>
              Please note that this is a{" "}
              <b>
                {item?.taskDetails?.subscriptionInterval}
                ly
              </b>{" "}
              job, and payment will be made on a{" "}
              <b>
                {item?.taskDetails?.subscriptionInterval}
                ly
              </b>{" "}
              basis upon completion.{" "}
            </p>
          </div>
        )} */}
      </div>
    );
  };
  
  export default HustlerHomeApply;
  