import React, { useEffect, useState } from "react";
import { CanadianCity } from "../../utils/constants";
import Select from "react-select";
import { useSelector } from "react-redux";
// import DatePicker from "react-datepicker";
import { setReportFormatDate } from "../../assets/Helper";
import { State, City } from "country-state-city";
import { displayBudget, payloadBudget } from "../../utils/helper";
import { IoCheckmarkCircle, IoCloseOutline } from "react-icons/io5";
import CreatableSelect from 'react-select/creatable';
import { DatePicker, Space, Typography } from 'antd';
import moment from 'moment';
const { RangePicker } = DatePicker;
const { Text } = Typography;


// import { useServices } from "../../contexts/ServiceContext";


const EditcreateTask = ({ singleTask, setshoweditModal, EditupdateTask }) => {
  const taskServices = useSelector((state) => state.app?.services);
  const [provinceList, setprovinceList] = useState([]);
  const [cityList, setcityList] = useState([]);
  const [selectedProvince, setselectedProvince] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [stateCode, setStateCode] = useState("");
  const [option, setOption] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");
  const MAX_UPLOAD_LIMIT = 10;
  const [uploadLimitExceeded, setUploadLimitExceeded] = useState(false);
  const [data, setdata] = useState({
    id: "",
    title: "",
    serviceName: "",
    cityName: "",
    province: "",
    budget: "",
    dueDate: "",
    endDate:"",
    oneTimePayment: "",
    subscriptionInterval: "",
    description: "",
    prev_images: [],
    job_image: [],
  });

  const [startDate, setstartDate] = useState(new Date());
  const [EndDate, setEndDate] = useState(new Date());



  const handleChange = (e) => {
    const { name, value } = e.target;

    setdata((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  
  const handleSelectCity = (selectedOption) => {
    if (selectedOption) {
      setdata((prev) => ({
        ...prev,
        cityName: {
          label: selectedOption.label,
          value: selectedOption.value,
        },
      }));
    } else {
      setdata((prev) => ({
        ...prev,
        cityName: "",
      }));
    }
  };

  const handleSelectService = (selectedOption) => {
    if (selectedOption) {
      setdata((prev) => ({
        ...prev,
        serviceName: {
          label: selectedOption.label,
          value: selectedOption.value,
        },
      }));
    } else {
      setdata((prev) => ({
        ...prev,
        serviceName: "",
      }));
    }
  };

  const handleInputChange=(value,e)=>{
    if (e.action == "menu-close") {
      if (e.prevInputValue.length > 0) {
        const newOption = { label: e.prevInputValue, value: e.prevInputValue };
        setdata((prev) => ({
          ...prev,
          serviceName: newOption
        }));
      }
    }
  }

  const handleSelectProvince = (option) => {
    if (option) {
      setdata((prev) => ({
        ...prev,
        province: option,
        cityName: "",
      }));

      setStateCode(option.value);
      setcityList([]);
    } else {
      setdata((prev) => ({
        ...prev,
        province: "",
        cityName: "",
      }));
      setStateCode("");
      setcityList([]);
    }
  };

  const handleOptionChange = (event) => {
    if (true) {
      setdata((prev) => ({
        ...prev,
        oneTimePayment: true,
        subscriptionInterval: "",
      }));
    } else {
      setdata((prev) => ({
        ...prev,
        oneTimePayment: event.target.id,
      }));
    }
  };

  const handleSubscriptionChange = (option) => {
    setdata((prev) => ({
      ...prev,
      subscriptionInterval: `${option}`,
      oneTimePayment: "false",
    }));
  };

  const handleMediaUpload = (e) => {
    const files = e.target.files;
    if (data.job_image.length + files.length > MAX_UPLOAD_LIMIT) {
      setUploadLimitExceeded(true);
      return;
    }


    setdata((prevStepForm) => ({
      ...prevStepForm,
      job_image: [...prevStepForm.job_image, ...Array.from(files)],
    }));
  };


  const removeImage = (index) => {
    const updatedImages = [...data.job_image];
    updatedImages.splice(index, 1);
    setdata((prevStepForm) => ({
      ...prevStepForm,
      job_image: updatedImages,
    }));
  };

  const handleRemovePrevimages = (image) => {

    const prevImagesFilter = data.prev_images.filter(item => item !== `${image}`);


    setdata((prev) => ({
      ...prev,
      prev_images: prevImagesFilter
    }))


  };


  const handleSubmit = (e) => {
    e.preventDefault();



    const mergeImagesarray = data.prev_images.concat(data.job_image)



    const payload = {
      id: data.id,
      title: data.title,
      serviceName: data.serviceName.value,
      province: data.province.label,
      cityName: data.cityName.value,
      job_image: mergeImagesarray,
      budget: payloadBudget(`${data.budget}`), //payloadBudget(data.budget),
       dueDate: startDate,
       endDate: EndDate,
      oneTimePayment: data.oneTimePayment,
      subscriptionInterval: data.subscriptionInterval,
      description: data.description,
    };

    EditupdateTask(payload);
  };

  useEffect(() => {
    const ignoreCity = {
      label: singleTask.cityName,
      value: singleTask.cityName,
    };

    const ignoreService = {
      label: singleTask.serviceName,
      value: singleTask.serviceName,
    };

    const ignoreProvince = {
      label: singleTask.province,
      value: singleTask.province,
    };

    setdata((prev) => ({
      ...prev,
      id: singleTask._id || "",
      title: singleTask.title || "",
      serviceName: ignoreService || "",
      province: ignoreProvince || "",
      cityName: ignoreCity || "",
      budget: singleTask.budget || "",
      description: singleTask.description || "",
      dueDate: singleTask.dueDate,
      prev_images: singleTask.attachments || "",
      oneTimePayment: `${singleTask.oneTimePayment}` || "",
      subscriptionInterval: singleTask.subscriptionInterval || ""
    }));
    // setstartDate(new Date(singleTask.dueDate));

    // setstartDate(singleTask.dueDate); 
    // setEndDate(singleTask.endDate);

    const start = moment(singleTask.dueDate);
    const end = moment(singleTask.endDate);

    // Set values for the states
    setstartDate(start);
    setEndDate(end);
    setDates([start, end]);



    const data = State.getStatesOfCountry('CA').map(({isoCode, name}) => ({
      value: isoCode,
      label: name,
    }));

    const filterData = data.filter(item => item.label == ignoreProvince.value);
    // setcityList(
    //   City.getCitiesOfState('CA', filterData[0]?.value).map(({name}) => ({
    //     value: name,
    //     label: name,
    //   }))
    // );

  }, []);


  useEffect(() => {
  
    // setprovinceList(
    //   State.getStatesOfCountry("CA").map(({ isoCode, name }) => ({
    //     value: isoCode,
    //     label: name,
    //   }))
    // );

    setprovinceList(
      [ { value: "BC", label: "British Columbia" },
        {value: "SK",label: "Saskatchewan"}
     ]
      );
  }, []);

  useEffect(() => {
    setcityList([])
    if(stateCode=="BC"){
        setcityList(
          [ {"value": "Kamloops", "label": "Kamloops"},
            {"value": "Kelowna","label": "Kelowna"},
            {"value": "Peachland","label": "Peachland"},
            {"value": "Penticton","label": "Penticton"},
            {"value": "Vernon","label": "Vernon"},
            {"value": "West Kelowna","label": "West Kelowna"},
          ]
        )
      }
      else{
        setcityList(
          [ {value: "Saskatoon",label: "Saskatoon"}
          ]
        )
    
    
      }
      if (stateCode) {
      // setcityList(
      //   City.getCitiesOfState("CA", stateCode).map(({ name }) => ({
      //     value: name,
      //     label: name,
      //   }))
      // );
    }
  }, [stateCode]);

  const handleStartDateChange = (date) => {
    setstartDate(date);
    if (data.subscriptionInterval === 'month') {

      const nextMonthDate = new Date(date);
      nextMonthDate.setMonth(nextMonthDate.getMonth()+1); 
  } else {
  }
  };

  useEffect(()=>{
    handleOptionChange()
  },[])

  const [dates, setDates] = useState([]); // Initialize dates state


const handleChange1 = (value) => {
 
  if (value) {
    setstartDate(value[0].$d); 
    setEndDate(value[1].$d);   
    setDates(value);           
  } 
  else {
    setstartDate(null);         
    setEndDate(null);           
    setDates(null);            
  }

};




const [isOpenToOffers, setIsOpenToOffers] = useState(false);
const [budget, setBudget] = useState("");

// Toggle between showing budget input and "Open to Offers"
const handleToggleClick = () => {
  setIsOpenToOffers((prev) => !prev);
};

// const handleChange = (e) => {
//   setBudget(e.target.value);
// };

const displayBudget = (value) => {
  return value || "";
};


// setcreateTaskData((prev) => ({
//   ...prev,
//   [name]: value,
// }));

const todayDate = new Date()
useEffect(() => {
  if (isOpenToOffers) {
    setdata((prev) => ({
      ...prev,
      budget: 0, // Set budget to 0 when isOpenToOffers is true
    }));
  }
}, [isOpenToOffers]);

const jobList = [
  { "label": "Appliance Help", "value": "Appliance Help" },
  { "label": "Assembly & Installation", "value": "Assembly & Installation" },
  { "label": "Cleaning Services", "value": "Cleaning Services" },
  { "label": "Curtain & Blind Setup", "value": "Curtain & Blind Setup" },
  { "label": "Deck & Outdoor Assistance", "value": "Deck & Outdoor Assistance" },
  { "label": "Electrical Help", "value": "Electrical Help" },
  { "label": "Errands and Personal Assistance", "value": "Errands and Personal Assistance" },
  { "label": "Furniture Moving & Packing", "value": "Furniture Moving & Packing" },
  { "label": "Garage Organization", "value": "Garage Organization" },
  { "label": "Gutter Cleaning", "value": "Gutter Cleaning" },
  { "label": "Hanging & Mounting", "value": "Hanging & Mounting" },
  { "label": "Home Organization", "value": "Home Organization" },
  { "label": "Junk Removal", "value": "Junk Removal" },
  { "label": "Minor Repairs", "value": "Minor Repairs" },
  { "label": "Painting", "value": "Painting" },
  { "label": "Plumbing", "value": "Plumbing" },
  { "label": "Power Washing", "value": "Power Washing" },
  { "label": "Quick Fixes", "value": "Quick Fixes" },
  { "label": "Seasonal Jobs", "value": "Seasonal Jobs" },
  { "label": "Snow Removal", "value": "Snow Removal" },
  { "label": "Window Cleaning", "value": "Window Cleaning" },
  { "label": "Yard Work", "value": "Yard Work" }
];



  return (
    <>
      <div
        className={`edit-created--task modal ${singleTask ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <form onSubmit={handleSubmit}>
              <div className="modal-header">
                <h5 className="modal-title">Edit Created Task </h5>
                <button
                  onClick={() => setshoweditModal(false)}
                  type="button"
                  className="close"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group mb-3">
                  <br />

                  <div className="job--type">

                    {data.oneTimePayment === "false" ? (
                      <>
                        <label className="mt-3 mb-2">
                          How frequently would you like this job to repeat?{" "}
                          <span className="mendotry-field">*</span>
                        </label>
                        <div className="what--subscription">
                          <label
                            className={`ratio--box custom--btn minbutton ${data.subscriptionInterval === "week"
                              ? "darkbtn-hover"
                              : "ligghtbtn-hover transparent--btn"
                              }`}
                            onClick={() => handleSubscriptionChange("week")}
                          >
                            <input
                              type="radio"
                              required
                              name="subcriptionoption"
                              id="week"
                              checked={data.subscriptionInterval == "week"}
                            />

                            <span>
                              {data.subscriptionInterval == "week" && (
                                <IoCheckmarkCircle />
                              )}{" "}
                              Weekly
                            </span>
                          </label>

                          <label
                            className={`ratio--box custom--btn minbutton ${data.subscriptionInterval === "month"
                              ? "darkbtn-hover"
                              : "ligghtbtn-hover transparent--btn"
                              }`}
                            onClick={() => handleSubscriptionChange("month")}
                          >
                            <input
                              type="radio"
                              required
                              name="subcriptionoption"
                              id="month"
                              checked={data.subscriptionInterval === "month"}
                            />
                            <span>
                              {data.subscriptionInterval === "month" && (
                                <IoCheckmarkCircle />
                              )}{" "}
                              Monthly
                            </span>
                          </label>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="Select Date" className="mb-2">
                  What dates would you like this job to be completed between?{" "}
                    <span className="mendotry-field">*</span>
                  </label>
                  <br />
                                              <Space direction="vertical" size={12} style={{ width: '100%' }} >
      <div style={{ display: 'flex', marginTop: '8px' }}>
        {/* <Text type="secondary" strong  style={{ width: '50%', marginLeft:"15px"}}>Start Date</Text> */}
        <p style={{ width: '50%', marginLeft:"15px", margin:"0"}}>Start Date</p>
        <p style={{ width: '50%', marginLeft:"15px", margin:"0"}}>End Date</p>
        {/* <Text type="secondary" strong  style={{ width: '50%',marginLeft:"10px", textAlign: 'left' }}>End Date</Text> */}
      </div>
      
      <RangePicker
        value={dates}
        onChange={handleChange1}
        // format="YYYY-MM-DD"
        format="MM/DD/YYYY"

        defaultValue={[moment().subtract(7, 'days'), moment()]}
        style={{ width: '100%' }}
        required
        allowClear
        // minDate={moment()}
        disabledDate={(current) => current && current.isBefore(moment(), 'day')}

      />
    </Space>

    <style jsx global>{`
        .ant-picker-dropdown {
          z-index: 99999999 !important; /* Ensure the calendar is on top */
        }

        .custom-range-picker-dropdown {
          z-index: 999999999 !important; /* Ensure the calendar is on top */
        }

        /* Make the RangePicker more mobile-friendly */
        .ant-picker {
          width: 100%;
          font-size: 14px; /* Adjust font size for better touch experience */
        }

        /* Ensure calendar dropdown is full-width on mobile devices */
        @media (max-width: 768px) {
          .ant-picker-dropdown {
            width: 100% !important;
            left: 0 !important;
            right: 0 !important;
            max-width: 100% !important;
          }

          /* Optionally, adjust font size for mobile */
          .ant-picker-input input {
            font-size: 14px;
          }
        }

        /* Make sure calendar is more responsive on smaller screens */
        @media (max-width: 480px) {
          .ant-picker-dropdown {
            width: 100% !important;
            max-width: 100% !important;
            font-size: 12px;
          }

          .ant-picker-input input {
            font-size: 12px;
            padding: 10px; /* Make it more touch-friendly */
          }
        }


        @media (max-width: 992px) {
  .ant-picker-panel:last-child .ant-picker-date-panel {
    display: none !important;
  }

  .ant-picker-panel:first-child
    .ant-picker-date-panel
    .ant-picker-header-next-btn,
  .ant-picker-panel:first-child
    .ant-picker-date-panel
    .ant-picker-header-super-next-btn {
    visibility: initial !important;
  }

  .ant-picker-panel.ant-picker-range-wrapper {
    min-width: 288px !important;
  }

  .ant-picker-presets {
    display: none !important;
  }
}
      `}</style>




                </div>


                {/* {data.oneTimePayment === "false" ? (<>  
                  {data.subscriptionInterval? <p>Your job will reoccur <b>{data.subscriptionInterval=="week"? 'weekly':'monthly'}</b> from your selected start date and your credit card will be charged approximately 48 hours prior.</p>  : <p>Your job will reoccur <b>{`weekly/monthly`}</b> from your selected start date and your credit card will be charged approximately 48 hours prior.</p>}

                  </>
                ):null}
 */}
                <div className="form-group mb-3">
                  <label htmlFor="Budget" className="mb-2">
                    Title <span className="mendotry-field">*</span>
                  </label>
                  <br />
                  <input
                    type="text"
                    required
                    className="form-control"
                    name="title"
                    onChange={handleChange}
                    value={data.title}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="Select Job" className="mb-2">
                    Select Service <span className="mendotry-field">*</span>
                  </label>
                  <CreatableSelect
                    required
                    options={jobList}
                    className="select--custom"
                    placeholder={`Service`}
                    value={data.serviceName}
                    onChange={handleSelectService}
                    onInputChange={handleInputChange}
                    isClearable

                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="Select City" className="mb-2">
                    Select Province <span className="mendotry-field">*</span>
                  </label>
                  <Select
                    required
                    options={provinceList}
                    isClearable={false}
                    className="select--custom province--select"
                    value={data.province}
                    placeholder={`Select Province`}
                    onChange={handleSelectProvince}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="Select City" className="mb-2">
                    Select City <span className="mendotry-field">*</span>
                  </label>
                  <Select
                    required
                    options={cityList}
                    isClearable
                    className="select--custom"
                    value={data.cityName}
                    placeholder={`City / Zip`}
                    onChange={handleSelectCity}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="Budget" className="mb-2">
                    Budget <span className="mendotry-field">*</span>
                  </label>
                  <br />

                  { isOpenToOffers ?
                        <div className="input-like-box">Open to Offers</div>
                        :

                  <div className="input--dolldar">
                    <span className="dollar-icon">$</span>
                    <input
                      type="text"
                      required
                      className="form-control"
                      value={displayBudget(`${data.budget}`)}
                      name="budget"
                      onChange={handleChange}
                      placeholder="0"
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/^0+/, '');
                      }}

                    />
                  </div>}

                  <div className="upload--media " style={{marginTop:"20px"}}>

<button type="button" className="btn btn-dark darkbtn-hover"             
                      onClick={handleToggleClick}
                      >
                <span>{isOpenToOffers ? "Enter Budget" : "Open to Offers"}</span>
                </button>
                </div>
                <style jsx>{`
        .input-like-box {
           display: inline-block;
          width: 100%;
          padding: 8px;
          border: 1px solid #ccc;
         border-radius: 4px;
         background-color: #f9f9f9;
          color: #333;
          font-size: 1em;
          line-height: 1.8;
          box-sizing: border-box;
        }
      `}</style>

                </div>

                <div className="form-group">
                  <label htmlFor="Add Your Images" className="mb-2">
                    Add Your Images
                  </label>
                  <br />
                  <div className="upload--media">
                    <div className="btn btn-dark darkbtn-hover">
                      Upload Media
                    </div>
                    <input
                      type="file"
                      name="mediaupload"
                      multiple="multiple"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={handleMediaUpload}
                    />
                  </div>

                  <div className="media--list--task">
                    {data.prev_images.map((item, index) => (
                      <div className="media--item" key={index}>
                        <p
                          className="remove--media"
                          onClick={() => handleRemovePrevimages(item)}

                        //    onClick={() => removeImage(index)}
                        >
                          <IoCloseOutline />
                        </p>
                        <img src={`${process.env.REACT_APP_IMAGE_URL}job_attachments/${item}`} />
                      </div>
                    ))}

                    {data.job_image.map((image, index) => (
                      <div className="media--item" key={index}>
                        <p
                          className="remove--media"
                          onClick={() => removeImage(index)}
                        >
                          <IoCloseOutline />
                        </p>

                        <img
                          src={URL.createObjectURL(image)}
                          alt={`Image ${index + 1}`}
                        />
                      </div>
                    ))}
                  </div>

                  {uploadLimitExceeded && (
                    <p style={{ color: "red" }} className="pt-3">
                      Upload limit exceeded. You can upload a maximum of{" "}
                      {MAX_UPLOAD_LIMIT} images.
                    </p>
                  )}

                </div>

                <div className="form-group">
                  <label htmlFor="Description" className="mb-2">
                    Description <span className="mendotry-field">*</span>
                  </label>
                  <br />
                  <textarea
                    name="description"
                    value={data.description}
                    onChange={handleChange}
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    required
                  ></textarea>
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-dark darkbtn-hover">
                  <span>Save Changes</span>
                </button>
                <button
                  onClick={() => setshoweditModal(false)}
                  type="button"
                  className="custom--btn ligghtbtn-hover transparent--btn"
                >
                  <span>Cancel</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className={`modal-backdrop ${singleTask ? "show" : ""}`}></div>
    </>
  );
};

export default EditcreateTask;
