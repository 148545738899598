import React, { useState } from 'react';
import axios from 'axios'; // Import axios for API requests
import './ChangePassword.css'; // Import the CSS file for styling
import { authAxios } from "../../services/config";
import { toast } from "react-toastify";
import {Link} from "react-router-dom"
import { PiEyeLight, PiEyeSlash } from "react-icons/pi";

// import { Link, useNavigate } from "react-router-dom";



const ChangePassword = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [showPassword, setShowPassword] = useState({
        current: false,
        new: false,
        confirm: false,
    });


    const baseURL = process.env.REACT_APP_BASEURL;

    const validatePassword = () => {
        // Example password validation criteria
        if (newPassword.length < 8) {
            setError('New password must be at least 8 characters long.');
            return false;
        }
        if (!/\d/.test(newPassword)) {
            setError('New password must contain at least one number.');
            return false;
        }
        if (!/[!@#$%^&*]/.test(newPassword)) {
            setError('New password must contain at least one special character.');
            return false;
        }
        if (newPassword !== confirmPassword) {
            setError('New password and confirmation do not match.');
            return false;
        }
        return true;
    };

    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        if (!validatePassword()) {
            return;
        }

        const payload = {
            "oldPassword": currentPassword,
            "newPassword": newPassword,
            "confirmPassword": confirmPassword
        };

        try {
            const response = await authAxios().post(`/users/change-password`, payload);
            toast.success(response?.data.message);

            setSuccess('Password changed successfully.');
        } catch (error) {
            toast.error(error?.response?.data?.message);

            setError(error?.response?.data?.message || 'An error occurred. Please try again.');
        }
    };

    const togglePasswordVisibility = (field) => {
        setShowPassword({
            ...showPassword,
            [field]: !showPassword[field],
        });
    };

    return (
        <div style={{paddingTop:"100px"}}>


      
<div style={{background:"#f4f4f4", paddingTop:"50px", paddingBottom:"50px"}}>
            <div className="change-password-container">
                <h2 className="playFairDisplay">Change Password</h2>
                <form onSubmit={handleSubmit} className="change-password-form ">
                    <div className="form-group">
                        <label className="playFairDisplay" htmlFor="currentPassword">Current Password 
                            <span style={{color:"red"}}>*</span>
                        </label>
                        <div className="input-wrapper">

                        <input
                            // type="password"
                            type={showPassword.current ? "text" : "password"}

                            id="currentPassword"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            required
                        />
                                <span onClick={() => togglePasswordVisibility('current')} className="eye-icon">
                                    {showPassword.current ? <PiEyeSlash /> : <PiEyeLight />}
                                </span>
                                </div>

                    </div>
                    
                    <div className="form-group">
                        <label htmlFor="newPassword" className="playFairDisplay">New Password
                        <span style={{color:"red"}}>*</span>
                        </label>
                        <div className="input-wrapper">

                        <input
                            // type="password"
                            type={showPassword.new ? "text" : "password"}

                            id="newPassword"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                         <span onClick={() => togglePasswordVisibility('new')} className="eye-icon">
                                    {showPassword.new ? <PiEyeSlash /> : <PiEyeLight />}
                        </span>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="confirmPassword" className="playFairDisplay">Confirm New Password
                        <span style={{color:"red"}}>*</span>
                        </label>
                        <div className="input-wrapper">

                        <input
                            // type="password"
                            type={showPassword.confirm ? "text" : "password"}

                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                       <span onClick={() => togglePasswordVisibility('confirm')} className="eye-icon">
                                    {showPassword.confirm ? <PiEyeSlash /> : <PiEyeLight />}
                                </span>
                                </div>

                    </div>

                    {error && <p className="error">{error}</p>}
                    {success && <p className="success">{success}</p>}
                    <button type="submit" className="change-password-button playFairDisplay" >Change Password</button>
                </form>
            </div>
            </div>
        </div>
    );
};

export default ChangePassword;
