import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CustomArrow from "../../component/TrendingServicesArrow";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import { toast } from "react-toastify";
import { authAxios } from "../../services/config";
import IsLoadingHOC from "../../common/IsLoadingHOC";
import { handleDecimalNumber, staticCategory } from "../../utils/helper";
import { Link } from "react-router-dom";

function RecommenedHustler(props) {
  const { setLoading } = props;
  const [hustler, sethustler] = useState([]);

  const fetchallUsers = async () => {
    setLoading(true);
    await authAxios()
      .get(`/search/get-all-hustlers`)
      .then((response) => {
        const resData = response?.data;

        setLoading(false);

        if (resData.status == 1) {
          sethustler(resData?.data);
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    fetchallUsers();
  }, []);


  return (
    <>
      <section className="trending--services py-50 recommended--hustler">
        <div
          className="slider--container"
          data-aos="fade-up"
          data-aos-offset="300"
          data-aos-duration="1000"
        >
          {true && (
            <Carousel
              additionalTransfrom={0}
              autoPlaySpeed={3000}
              arrows={false}
              containerClass="carousel-container services--list"
              renderButtonGroupOutside={true}
              customButtonGroup={<CustomArrow title={"Recommended Hustlers"} />}
              infinite
              draggable={false}
              partialVisible
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1366,
                  },
                  items: 3,
                  partialVisibilityGutter: 100,
                },
                tablet: {
                  breakpoint: {
                    max: 1080,
                    min: 991,
                  },
                  items: 2,
                  partialVisibilityGutter: 30,
                },
                mobile: {
                  breakpoint: {
                    max: 991,
                    min: 0,
                  },
                  items: 1,
                  partialVisibilityGutter: 30,
                },

              }}
              slidesToSlide={1}
              swipeable={false}
            >
              {hustler &&
                hustler.map((item, index) => (
                  <div key={index} className="hustler--thumbnail--items">
                    <div className="custom--scroll hustler--thumbnail-scroll">
                      <div className="thumbnail--hustler">
                        <Link to={`/user-info/${item?.user?.id}`} className="thumbnail--hustler--profile">
                          <img

                            src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${item?.user?.profilePic}`}
                            alt="Profile"
                          />
                        </Link>
                        <div className="thumbnail--hustler--info" style={{ width: '90%' }}>
                          <h2>
                            {item?.user?.fname} {item?.user?.lname}
                          </h2>
                          <p
                            style={{
                              marginBottom: "0px",
                              fontSize: "12px",
                              color: " #949494",
                            }}
                          >
                            {item?.user?.hustlerDetails?.province},{" "}
                            {item?.user?.hustlerDetails?.location}
                          </p>
                          <p className="search--category">


                            {item?.user?.hustlerDetails?.services?.length > 0 && item?.user?.hustlerDetails?.services.map((service, index, array) => (
                              <span key={service._id}>
                                {service.serviceName}{index !== array.length - 1 && ', '}
                              </span>
                            ))}

                          </p>
                          {/* 
                          <p className="starting--price">
                            <small>Starting at: </small>
                            ${item?.user?.hustlerDetails?.price || 20}.00/h
                          </p>
                          */}
                        </div>
                      </div>
                      <div className="items-content-hustler">
                        <div>
                          <p className="hutler--taskdone">
                            <img src="./assets/images/hustler--taskdone.png" />
                            {item?.user?.hustlerDetails?.completedTask}{" "}
                            Completed Tasks
                          </p>
                          <p className="working--rating">
                            <img src="../assets/images/hustler-star.png" />
                            {handleDecimalNumber(item?.user?.hustlerDetails?.ratings)}
                          </p>
                          <ul className="featured--work--list">

                            {item?.user?.hustlerDetails?.services.map(service => (
                              <>
                                {service.category.map(category => (
                                  <li key={category._id} style={{ textTransform: 'capitalize' }}>
                                    {category.label}
                                  </li>
                                ))}
                              </>
                            ))}


                          </ul>
                          <p className="discription--hustler--profile">
                            {item.user.description == "" || item.user.description == null ? <></> : <>   {item.user.description.length > 100 ? <> {item?.user?.description?.substring(0, 100) + "..."} </> : <>{item?.user?.description} </>}  </>}
                          </p>
                        </div>

                      </div>
                    </div>
                    <div className="hustler-profile-view">
                      <Link
                        to={`/hustlr-booking/${item?.user?.id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <button className="custom--btn mt-50 darkbtn-hover">
                          <span>View Profile</span>
                        </button>
                      </Link>
                    </div>
                  </div>
                ))}
            </Carousel>
          )}
        </div>
      </section>
    </>
  );
}

export default IsLoadingHOC(RecommenedHustler);