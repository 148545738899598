import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import TextareaAutosize from "react-textarea-autosize";
import { BudgetFormat, dateFormat, displayBudget, payloadBudget } from "../../utils/helper";
import { setReportFormatDate } from "../../assets/Helper";

const DirectorCounterOfferModel = ({
  allCounterOfferdetails,
  setshowdirectorCounterOffermodel,
  incomingJobtaskId,
  fetchCounterOfferDetails,
  handleAcceptcounterOffer,
  singleTask,
  handleDeniedJob,
  hustlerId,
  setallCounterOfferdetails,
  CounterOffer,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [formdata, setformdata] = useState({
    reason: "",
    offerprice: "",
    offerdate: `${startDate}`,
  });
  const [activeTab, setActiveTab] = useState(1);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformdata((prev) => ({
      ...prev,

      [name]: value,
    }));
  };


  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    

    const payload = {
      hustlerId: hustlerId || singleTask?.hustlerId?._id,
      taskId: incomingJobtaskId,
      counterOffer: {
        reason: formdata.reason,
        offerPrice: payloadBudget(`${formdata.offerprice}`),

        proposalDate: startDate,
      },
    };


    CounterOffer(payload);
  };

  useEffect(() => {
    setStartDate(new Date(singleTask?.dueDate));
  }, []);




  const handleCancel = () => {
    setshowdirectorCounterOffermodel(false);
    setallCounterOfferdetails([]);
    if (fetchCounterOfferDetails) {
      fetchCounterOfferDetails();
    }
  };


  return (
    <div>
      <div
        className={`deletemodal modal ${
          setshowdirectorCounterOffermodel ? "show" : ""
        } ${
          allCounterOfferdetails && allCounterOfferdetails
            ? "counteroffer--modal"
            : ""
        }`}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal--dilog" style={{maxHeight:'80vh'}}>
          <div className="modal-content">
            <form onSubmit={handleSubmit}>
              <div className="modal-header flex-column">
                <h4 className="modal-title w-100 text-center">
                  {allCounterOfferdetails && allCounterOfferdetails
                    ? "Counter Offers"
                    : "Please Confirm"}
                </h4>
                <button
                  type="button"
                  className="close"
                  onClick={handleCancel}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body pt-5 pb-5 confirmation--modal counter--body">
                <div className="offerleft--side">
                  <div className="header--counteroffer pb-3">
                    <ul className="tab--counter">
                      <li
                        onClick={() => handleTabClick(1)}
                        className={activeTab === 1 ? "active" : ""}
                      >
                        From Hustlr
                      </li>
                      <li
                        onClick={() => handleTabClick(0)}
                        className={activeTab === 0 ? "active" : ""}
                      >
                        From You
                      </li>
                    </ul>
                  </div>
                  {activeTab === 1 && (
                    <div
                      className={
                        allCounterOfferdetails?.hustlerCounterOffers &&
                        allCounterOfferdetails?.hustlerCounterOffers?.length > 0
                          ? "request--lists custom--scroll"
                          : ""
                      }
                    >
                      {allCounterOfferdetails?.hustlerCounterOffers &&
                        allCounterOfferdetails?.hustlerCounterOffers?.length >
                          0 &&
                        allCounterOfferdetails?.hustlerCounterOffers?.map(
                          (item, index) => (
                            <div className="request--list--item" key={item.id}>
                              

                              <h3>
                                {item._id ==
                                allCounterOfferdetails?.hustlerCounterOffers[0]
                                  ._id ? (
                                  <span>Latest Offer </span>
                                ) : (
                                  <span>Old Offer </span>
                                )}
                                <span className="date">
                                  {dateFormat(item.createdAt)}
                                </span>{" "}
                              </h3>

                              <p>
                              The Hustlr has submitted a counter offer to the Creatr, adjusting the offer price to <strong>${BudgetFormat(`${item?.offerPrice}`)}</strong> and the date to <br /><strong>{setReportFormatDate(item.proposalDate)}</strong>, with the rationale being: <strong>{item.reason}</strong>
                              </p>

                               {item._id ==
                                allCounterOfferdetails?.hustlerCounterOffers[0]
                                  ._id ? (
                                  <div className="button-group">
                                    <button
                                      type="button"
                                      className="custom--btn btn-dark darkbtn-hover"
                                      onClick={()=>handleAcceptcounterOffer(singleTask._id)}
                                    >
                                      <span>Accept</span>
                                    </button>

                                    <button
                                      type="button"
                                      className="custom--btn btn-dark darkbtn-hover"
                                      onClick={handleDeniedJob}
                                     // onClick={()=>handleAcceptcounterOffer(singleTask._id)}
                                    >
                                      <span>Decline</span>
                                    </button>
                                  </div>
                                ) : null }
                            </div>
                          )
                        )}
                    </div>
                  )}

                  {activeTab === 0 && (
                    <div
                      className={
                        allCounterOfferdetails?.directorCounterOffers &&
                        allCounterOfferdetails?.directorCounterOffers.length > 0
                          ? "request--lists custom--scroll"
                          : ""
                      }
                    >
                      {allCounterOfferdetails?.directorCounterOffers &&
                        allCounterOfferdetails?.directorCounterOffers.length >
                          0 &&
                        allCounterOfferdetails?.directorCounterOffers.map(
                          (item, index) => (
                            <div className="request--list--item" key={item.id}>
                                <h3>
                                {item._id ==
                                allCounterOfferdetails?.directorCounterOffers[0]
                                  ._id ? (
                                  <span>Latest Offer </span>
                                ) : (
                                  <span>Old Offer </span>
                                )}
                                <span className="date">
                                  {dateFormat(item.createdAt)}
                                </span>{" "}
                              </h3>
                              <p>
                                You have submitted a counter offer to the
                                Hustlr, adjusting the offer price to{" "}
                                <strong>${BudgetFormat(`${item?.offerPrice}`)}</strong> as of{" "}
                                <strong>
                                  {setReportFormatDate(item.proposalDate)}
                                </strong>
                                , with the rationale being{" "}
                                <strong>{item.reason}</strong>
                              </p>

                              
                            </div>
                          )
                        )}
                    </div>
                  )}
                </div>

                <div className="offerright--side">
                  <h5 className="mb-0 pt-0 wordkeep">
                    Are you sure you want to make a counter offer?
                  </h5>

                  <div class="request--task--work">
                    <div class="field--div" style={{zIndex:'2'}}>
                      <label>
                        Enter Your Job Date {" "}
                        <span className="mendotry-field">*</span>
                      </label>
                      <DatePicker
                        className="form-control"
                        minDate={new Date()}
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="MMM-dd-yy"
                        required
                      />
                    </div>
                    <div class="field--div">
                      <label>
                        Enter Your Offer Price{" "}
                        <span className="mendotry-field">*</span>
                      </label>
                      <div className="input--dolldar">
                        <span className="dollar-icon">$</span>
                        <input
                          type="text"
                          name="offerprice"
                          value={displayBudget(`${formdata?.offerprice}`)}
                          onChange={handleChange}
                          required
                          placeholder="0"
                        />
                      </div>
                    </div>
                    <div class="field--div">
                      <label>
                        Reason <span className="mendotry-field">*</span>
                      </label>
                      <TextareaAutosize
                       required
                        minRows={3}
                        maxRows={4}
                        value={formdata.reason}
                        onChange={handleChange}
                        name="reason"
                      />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="custom--btn ligghtbtn-hover transparent--btn"
                      onClick={handleCancel}
                    >
                      <span>Cancel</span>
                    </button>
                    <button
                      type="submit"
                      className="btn btn-dark darkbtn-hover"
                    >
                      <span>Submit</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop ${
          setshowdirectorCounterOffermodel ? "show" : ""
        }`}
      ></div>
    </div>
  );
};

export default DirectorCounterOfferModel;
