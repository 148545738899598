// src/context/TourContext.js
import React, { createContext, useContext, useState ,useEffect} from 'react';
import { useNavigate } from 'react-router-dom'; // Make sure to import useNavigate
import Notifications from '../common/Notifications';
import { useDispatch, useSelector } from "react-redux";


const TourContext = createContext();

export const useTour = () => useContext(TourContext);

export const TourProvider = ({ children }) => {
  const userType = useSelector((state) => state.auth?.user?.userType);


  const navigate = useNavigate(); // Initialize the navigate function
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [isTourRunning, setIsTourRunning] = useState(false);
  const [YesPopup, setYesPopup] = useState(0);
  const [HustlerYesPopup, setHustlerYesPopup] = useState(0);

  
  // Define steps that span across three pages

  const steps = userType=="HUSTLER" ?[

    // { target: '.page-one', title: 'FILTER JOB', content: 'Filter jobs according to your preference.', path: '/home', },
    { target: '.page-one', title: 'NEW JOBS', 
      // content: 'Filter jobs according to your preference.',
      content: 'Discover new job opportunities posted  by Creatrs.',
       path: '/home', },

    { target: '.page-two', title: 'SHOW INTEREST', content: 'Click "Show Interest" to send your proposal for a job.', path: '/home', state: { id: 'saved-jobs' },placement: 'top'  },
    { target: '.page-three', title: 'APPLIED JOBS',
       content: 'Once you show interest, the job moves to your "Applied Jobs" section for easy tracking.',
        path: '/home', state: { id: "interested-jobs" } },
    { target: '.page-four', title: 'IN PROGRESS JOBS',
       content: 'When the Creatr accepts your proposal, you will receive a notification, and the job will move to "In Progress" under your jobs navigation.',
        path: '/hustlr-jobs',  placement: 'top',},
      { target: '.page-four-one',title: 'MESSAGES', content: "Messages sent to you by the Creatr will appear here.", path: '',placement: 'top', },
    { target: '.page-five',title: 'NOTIFICATION', content: "This is where you can view all your notifications.", path: '',placement: 'bottom-end', },
    { target: '.page-six',title: 'COMPLETE JOB', content: 'Mark the job as completed once it’s done to receive payment.' , path: '/hustlr-jobs',placement: 'top' , },
    { target: '.page-six-one',title: 'COMPLETED JOBS', content: 'Your completed job will now be listed under the "Completed Jobs" tab.' ,path: '/hustlr-jobs', state: { id: "completed-jobs" },placement: 'top' , },

    { target: '.page-seven',title: 'ADD REVIEW', content: 'Leave a review for the Creatr based on your experience.', path: '/hustlr-jobs', state: { id: "completed-jobs" },placement: 'top', },  
    { target: '.page-eight',title: 'SITE TOUR', content: 'Let’s get started! You can restart the tour at any time by clicking here.', path: '/hustlr-jobs', state: { id: "completed-jobs" },placement: 'top', },     
  
  ]
  :
  
  [
    //1
    { target: '.page-one-element', title: 'POST A JOB', content: 'Create a new job to get started.', path: '/create-task', },
    //2
  //   { target: '.page-one-element-one',title: 'ENTER DATE', content: "Specify the date or date range when you'd like the job completed.", path: '/create-task',placement: 'top', },
  //  //3
  //   { target: '.page-one-element-two', title: 'ENTER JOB TITLE', content: 'Provide a clear and concise title for your job.', path: '/create-task' },
  //  //4 
  //   { target: '.page-one-element-three', title: 'SELECT THE JOB TYPE', content: 'Choose the type of job from the available options or type in your own job type.', path: '/create-task' },
  //   //5
  //   { target: '.page-one-element-four', title: 'CHOOSE THE LOCATION', content: 'Select the province and city where the job needs to be completed.', path: '/create-task' },
  //   //6
  //   { target: '.page-one-element-five', title: 'ENTER BUDGET', content: 'Set a budget for your job. Unsure of the budget? Select "Open to Offers" and let a Hustlr suggest a price.', path: '/create-task' },
  //  //7
  //   { target: '.page-one-element-six', title: 'WRITE THE  DESCRIPTION', content: 'Add a detailed description of your job to help Hustlrs understand your requirements.', path: '/create-task' },
  //  //8 
    { target: '.page-two-element',title: 'NEW JOB', content: 'When your job has been successfully created it will show here.', path: '/your-task-list',placement: 'top' , },
    //9
    { target: '.page-three-element',title: 'NOTIFICATION', content: 'You’ll receive a notification when a Hustlr shows interest in your job.', path: '',placement: 'top', },
   //10
    { target: '.page-four-element',title: 'VIEW APPLICANTS', content: "Review the list of Hustlrs who have expressed interest in your job." , path: '' },
    
    //{ target: '.page-four-element', content: 'four, this is Page Three!', path: '/your-task-list' },
//11

    { target: '.page-five-element' ,title: 'VIEW PROPOSAL', content: "Check out each Hustlr's profile and their proposal for your job.", path: '/task/6739bbf62354bab8b466f5e7' },
   //12
    { target: '.page-six-element',title: 'COUNTER OFFER', content: "Not satisfied with a proposal? Send a counter offer to the Hustlr.", path: '/intersted-hustlr-profile/66de9a6e8616ac16cea959e4/673b2a8e45b87448bf32d923' },
   //13
    { target: '.page-seven-element',title: 'ACCEPT OFFER', content: "Happy with a proposal? Accept the offer to move forward.", path: '' },
   //14 
    { target: '.page-eight-element',title: 'PAYMENT PORTAL',    content: 'Enter your payment details and click "Pay Now" to confirm the job.', path: '' },
   //15 
    { target: '.page-nine-element',title: 'IN PROGRESS JOBS', content: 'Once accepted, your job will be listed under "In Progress Jobs".', path: '/your-task-list/in-progress' },
   //16 
   { target: '.page-nine-element-one',title: 'SEND MESSAGE', content: 'Click here to send a message to your Hustlr.', path: '/your-task-list/in-progress' },
   { target: '.page-nine-element-two',title: 'MESSAGES', content: 'View your messages by clicking here.', path: '/your-task-list/in-progress' },

    { target: '.page-ten-element',title: 'MARK AS COMPLETED', content: 'When the job is done, mark it as completed.', path: '/your-task-list/in-progress' },
   //17 
    { target: '.page-eleven-element',title: 'COMPLETED JOBS', content: 'Your job will now appear in the "Completed Jobs" tab.', path: '/your-task-list/completed' },
  //18  
    { target: '.page-twelve-element',title: 'ADD REVIEW', content: 'Share your feedback by adding a review for the Hustlr.', path: '/your-task-list/completed' },
   //19 
    // { target: '.page-thirteen-element',title: 'SUBMIT', content: 'Write about your experience with the Hustlr and click "Submit."', path: '' },
    //20 
    { target: '.page-fourteen-element',title: 'SEARCH HUSTLR', content: 'Use the Hustlr navigation to find a Hustlr.', path: '/hustlrs-lists' },
   //21 
    { target: '.page-fifteen-element',title: 'VIEW PROFILE', content: 'View the profile of a Hustlr to learn more about their skills and experience.', path: '/hustlrs-lists' },
    // { target: '.page-sixteen-element',title: 'HIRE NOW', content: 'Step 16: Keep it up.', path: '/hustlr-booking/673ed12978e7a51eedd3de87',  },
    //22
    { target: '.page-seventeen-element',title: 'HIRE NOW', content: 'Hire this Hustlr for an existing job or create a custom job just for them.', path: '/hustlr-booking/673ed12978e7a51eedd3de87',  },
    //23
    // { target: '.page-eighteen-element',title: 'CREATE JOB FOR HUSTLR', content: 'Create a custom job specifically for this Hustlr.', path: '',  },
    // //24
    // { target: '.page-nineteen-element',title: 'CONFIRMATION' ,content: "Review and confirm the job proposal for the selected Hustlr.", path: '/hustlr-booking/673ed12978e7a51eedd3de87',  },
   
    {
      target: '.page-twenty-element',
      title: 'SITE TOUR',
      content: 'Let’s get started! You can restart the tour at any time by clicking here.',
      path: '',
    },

    // {
    //   target: '.page-twenty-one-element',
    //   title: 'YOUR JOBS',
    //   content: 'Track your new jobs, in-progress jobs, and completed jobs.',

    //   path: '/your-task-list',
    // },

    // {
    //   target: '.page-twenty-two-element',
    //   title: 'HELP CENTER',
    //   content: 'Access FAQs and support to resolve any issues quickly.',
    //   path: '',
    // }

  
  ];

  const startTour = () => {
    setCurrentStepIndex(0);
    setYesPopup(0)
    setIsTourRunning(true);
    navigate(steps[0].path);
  };

  const endTour = () => {
    setIsTourRunning(false);
    setCurrentStepIndex(0);
    if(userType=="HUSTLER" ){
      navigate('/home'); 
    }
    else{
      navigate('/create-task'); 
    }
    // console.log("Tour ended");
  };
  
  const nextStep = () => {
    const nextIndex = currentStepIndex + 1;
  
    if (nextIndex < steps.length) {
      setCurrentStepIndex(nextIndex);
  
      const { path, state } = steps[nextIndex];
      if (path) {
        navigate(path, { state }); // Pass state during navigation
      }
    } else {
      endTour();
    }
  };
  

  
  const prevStep = () => {
    const prevIndex = currentStepIndex - 1;
    if (prevIndex >= 0) {
      setCurrentStepIndex(prevIndex);
      const { path, state } = steps[prevIndex];
      if (path) {
        navigate(path, { state });
      }
    }
  };


  const resetTour = () => {
    setCurrentStepIndex(0);
    setIsTourRunning(false);
    navigate(steps[0].path);
  };



  return (
    <TourContext.Provider
      value={{ currentStepIndex, startTour, nextStep, resetTour, prevStep, isTourRunning, steps, YesPopup, setYesPopup, HustlerYesPopup, setHustlerYesPopup }}
    >
      {children}

    </TourContext.Provider>
  );
};


