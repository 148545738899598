import React, { useEffect, useState } from "react";
import { FaDollarSign } from "react-icons/fa";
import HustlerReviews from "./HustlerReviews";
import { BsChatRightText } from "react-icons/bs";
import { Link, useParams, useNavigate } from "react-router-dom";
import { authAxios } from "../../services/config";
import { toast } from "react-toastify";
import {
  BudgetFormat,
  displayBudget,
  handleDecimalNumber,
  serviceFeePercentage,
} from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { setParticipantDetails } from "../../Redux/Reducers/chatSlice";
import AddReviews from "../../common/AddReviews";
import BookHustlerPopup from "./BookHustlerPopup";
import IsLoadingHOC from "../../common/IsLoadingHOC";
import { useSocket } from "../../contexts/SocketContext";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
// import PaymentForm from "./PaymentForm";
import PaymentFormStart from "./PaymentFormStart";


// import BeforePaymentForm from "./BeforePaymentForm";

import DirectorCounterOfferModel from "./DirectorCounterOfferModel";
import DirectorFirstCounterOfferModel from "../../common/DirectorFirstCounterOfferModel";
import { setReportFormatDate } from "../../assets/Helper";
import { AiOutlineCalendar } from "react-icons/ai";
import ConfirmDeniedJobModel from "../../common/ConfirmDeniedJobModel";
import { IoClose } from "react-icons/io5";
import ProfileReviews from "../../common/ProfileReview";
import { useTour } from '../../contexts/TourContext';

const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`
);

const InterstedHustlerStart = (props) => {
    const { currentStepIndex, isTourRunning, steps, nextStep, resetTour ,YesPopup} = useTour();


  const { setLoading } = props;
  const param = useParams();
  const { hustlerId, taskId, finalPrice } = param;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userDetail, setuserDetail] = useState([]);
  const [TaskDetail, setTaskDetail] = useState([]);
  const [showPaymentForm, setshowPaymentForm] = useState(false);
  const [BeforeShowPaymentForm, setBeforeShowPaymentForm] = useState(false);
  const [paymentIntentId, setPaymentIntentId] = useState(null);
  const [paymentAmount, setpaymentAmount] = useState("");
  const [finaltaskDetails, setfinaltaskDetails] = useState({
    budget: "",
    date: "",
  });
  const [proposalSend, setProposalSend] = useState([]);
  const [showdenieJobModel, setshowdenieJobModel] = useState(false);
  const [proposalStatus, setProposalStatus] = useState(false);
  const [showdirectorCounterOffermodel, setshowdirectorCounterOffermodel] =
    useState(false);
  const [allCounterOfferdetails, setallCounterOfferdetails] = useState([]);
  const [singleTask, setSingleTask] = useState({});
  const [incomingJobtaskId, setincomingJobtaskId] = useState("");
  const [firstTimeCounterOffer, setfirstTimeCounterOffer] = useState(false);
  const [counterOfferCancelledModal, setCounterOfferCancelledModal] =
    useState(false);
  const { _id } = useSelector((state) => state?.auth?.user);
  const socket = useSocket();

  const [showReviewModel, setshowReviewModel] = useState(false);
  const [showBookHustler, setshowBookHustler] = useState(false);
  const [stripeAccountId, setStripeAccountId] = useState("");
  const [comments, setcomments] = useState([]);


  useEffect(()=>{
    // if(YesPopup==13){
    // setshowPaymentForm(true)
    // }
    // if(YesPopup==12){
    //   setshowPaymentForm(false)
    //   }

      if(YesPopup==7){
        setshowPaymentForm(true)
        }
        if(YesPopup==6){
          setshowPaymentForm(false)
          }
    
  
  },[YesPopup])


  return (
    <>

    <div >
    {showPaymentForm?   
    <PaymentFormStart ></PaymentFormStart>:""
}
    </div>

    <div className="h-100px"></div>
  
    <section className="container--profile--view intersted--hustler--profile">
      <div className="profile--view--header">
        <div className="profile--info">
          <div className="thumbnail--profile">
            <div className="thumbnail--profile--image">
              <img
                // src={`${process.env.REACT_APP_IMAGE_URL}profile_image/default.jpg`}
                src={`/assets/images/hustler-profile-img/hustler--profile-4.png`}

                alt="Profile"
              />
            </div>
            <div className="thumbnail--info">
              <h2>HUSTLR PROFILE</h2>
              <p className="location--hustler">New York, USA</p>
              <div>
                <p className="search--category mb-1">
                  <img src="/assets/images/hustler--taskdone.png" alt="Task Icon" />{" "}
                  14 Completed Jobs
                </p>
                <p className="starting--price">
                  <img src="/assets/images/hustler-star.png" alt="Rating Icon" />{" "}
                  4.3
                </p>
              </div>
            </div>
          </div>
        </div>
  
        <div className="profile--book--save">
          {/* <h4 className="playFairDisplay">The Hustler is not active.</h4> */}
          <button className="custom--btn ligghtbtn-hover transparent--btn minbutton">
            <span>Send Message</span>
          </button>
          <button className="page-seven-element custom--btn ligghtbtn-hover transparent--btn minbutton">
            <span>Accept Offer</span>
          </button>
          <button className="page-six-element custom--btn darkbtn-hover minbutton">
            <span>Counter Offer</span>
          </button>

        </div>
      </div>
  
      <div className="profile--sarvice--category">
        <span>Furniture Assembly, Home Repairs</span>
      </div>
  
      <ul className="category--child">
        <li style={{ textTransform: "capitalize" }}>Oil Changes</li>
        <li style={{ textTransform: "capitalize" }}>Living Room Furniture</li>
      </ul>
  
      <p>Description about the hustler goes here.</p>
  
      <div className="profile--content--area" style={{ marginTop: "10px" }}>
        <div className="profile--content--leftside w-100">
          <div
            className="profile--price"
            style={{ flexDirection: "column", alignItems: "flex-start" }}
          >
            <div className="container--counter--table">
              <div className="director--counter--table">
                <div className="first-initial">
                  <p>
                    <span> <FaDollarSign /> Job Budget: $65.00</span>
                  </p>
                  <p>
                    <span>
                    <AiOutlineCalendar
                          style={{ background: "black", color: "white" }}
                        />
                        
                        Job Date: 2024-12-01 to 2024-12-05</span>
                  </p>
                </div>
              </div>
  
              <div className="hustler--counter--table">
                <div className="first-initial">
                  <p>
                    <span>
                    <FaDollarSign />
                        Updated Bid: $80.00</span>
                  </p>
                  <p>
                    <span>
                    <AiOutlineCalendar
                          style={{ background: "black", color: "white" }}
                        />
                        Updated Job Date: Oct-19-24</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
  
          <div className="profile--description">
            <p>Description about services offered goes here.</p>
          </div>
  
          <div className="message--description">
            <h2>John's Comment</h2>
            <p>Additional details about the task go here.</p>
          </div>
        </div>
      </div>
    </section>
  </>
  
  );
};

export default IsLoadingHOC(InterstedHustlerStart);
