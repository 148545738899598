import React, { useEffect, useState } from "react";
import { authAxios } from "../services/config";
import { toast } from "react-toastify";
import {  BsTrash,BsPencil,BsEye } from "react-icons/bs";
import IsLoadingHOC from "../common/IsLoadingHOC";
import { Link } from "react-router-dom";

const Task = (props) => {
    const { setLoading } = props;

  const [Task, setTask] = useState([]);

  const fetchtask = async () => {
    setLoading(true)
    await authAxios()
      .get("/task/get-all-tasks-list")
      .then((response) => {
        setLoading(false)
        const resData = response.data;
        if (resData.status == 1) {
          setTask(resData.data);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(error.response.data.message);
      });
  };

  const handleDelete=async(id)=>{
    const itemId=id
    await authAxios()
    .delete(`/task/delete-tasks/${itemId}`)
    .then((response) => {
        setLoading(false)
        const resData = response.data;
        if (resData.status == 1) {
          toast.success(resData.message)
          fetchtask()
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(error.response.data.message);
      });

  }


  useEffect(() => {
    fetchtask();
  }, []);
  return (
    <div className="container table-responsive py-5">
      <table className="table table-bordered table-hover">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Title</th>
            <th scope="col">Last</th>
            <th scope="col">Status</th>
            <th scope="col">Assign Date</th>
            <th scope="col">Due Date</th>
            <th scope="col">Description</th>
            <th scope="col">View</th>
            <th scope="col">Edit</th>
            <th scope="col">Delete</th>
            
            {/*
            <th scope="col">Image</th>
            <th scope="col">Attachment</th>
             */}
          </tr>
        </thead>
        <tbody>
          {Task &&
            Task.map((item, index) => (
              <tr key={index}>
                <th>{index+1}</th>
                <td>{item.title}</td>
                <td>{item.budget}</td>
                <td>{item.status}</td>
                <td>{item.datePosted}</td>
                <td>{item.dueDate}</td>

                <td>{item.description}</td>
                {/* */}
                <td  > <Link to={`/view-task/${item.id}`}>   <BsEye/></Link></td>
                <td  ><BsPencil/></td>
                <td onClick={()=>handleDelete(item.id)} ><BsTrash/></td>
               
               

              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default IsLoadingHOC(Task)
