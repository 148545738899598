import React, { useEffect, useState } from "react";
import { IoIosCloseCircleOutline, IoIosSearch } from "react-icons/io";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { AiOutlineCalendar, AiOutlineHeart } from "react-icons/ai";
import { TbMoneybag } from "react-icons/tb";
import { GrLocation } from "react-icons/gr";
import FilterRightSide from "./FilterRightSide";
import { authAxios } from "../../services/config";
import { toast } from "react-toastify";
import { setReportFormatDate } from "../../assets/Helper";
import { MdReviews } from "react-icons/md";
import HustlerAddReview from "../../common/HustlerAddReview";
import AddReviews from "../../common/AddReviews";

import { FaRegCircleCheck } from "react-icons/fa6";
import AcceptIncomingJobModel from "./AcceptIncomingJobModel";
import CancelIncomingJobModel from "./CancelIncomingJobModel";
import IsLoadingHOC from "../../common/IsLoadingHOC";
import { PiEye } from "react-icons/pi";
import HustlerCompletedJobModal from "../../common/Modal/HustlerCompletedJobModal";
import { BiSolidCaretLeftSquare, BiTimeFive } from "react-icons/bi";
import { BudgetFormat, handleDecimalNumber } from "../../utils/helper";
import { useSocket } from "../../contexts/SocketContext";
import { useLocation } from "react-router-dom";
import ShowCancelDisableModal from "../../common/Modal/ShowCancelDisableModal";

import { GoArrowRight } from "react-icons/go";
import { LuPartyPopper } from "react-icons/lu";
import CounterOfferJobModel from "../../common/Modal/CounterOfferJobModel";
import DirectorcancelJobReason from "./DirectorcancelJobReason";
import HustlerDeclinedJobCompletedModal from "./HustlerDeclinedJobCompletedModal";
import JobFrequently from "../../common/JobFrequently";
import Pagination from "../../common/Pagination";
import HustlerJobsCompleted from "./HustlerJobsCompleted";


function HustlerJobsInProgress() {
  return (


                                    <div
                            //   key={index}
                              className="created--task--lists process--task--list"
                            >
                                                                              <div class="start--work">
                                                <span>
                                                  <BiTimeFive /> The task is
                                                  active. You can begin working
                                                  now.
                                                </span>
                                                <span className="view--reason">
                                                  <div className="task--link">
                                                    <Link
                                                    //   to={`/job-details/${item._id}`}
                                                      state={{
                                                        id: "jobDetails--view",
                                                      }}
                                                    >
                                                      Job Details <GoArrowRight />
                                                    </Link>
                                                  </div>
                                                </span>
                                              </div>

              
{/* ========================================================================= */}


                              <div className="task--work">

                                <div
                                  className="task--items--header"
                                  style={{ marginBottom: "0px" }}
                                >
                                  <div className="task--add--info">
                                    <div className="user--task--info">
                                      <div className="task--info--img">
                                        <Link
                                        //   to={`/user-info/${item?.user?._id}`}
                                        >
                                          <img
                                           src="../assets/images/hustler-profile-img/hustler--profile-1.png"

                                            // src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${item?.user?.profilePic}`}
                                          />
                                        </Link>
                                      </div>
                                      <div className="task--info--details">
                                        <span>
                                          {/* {item?.user?.fname}{" "}
                                          {item?.user?.lname} */}
                                          CREATR MALIK
                                        </span>
                                        <p>
                                          <img src="../assets/images/hustler-star.png" />{" "}
                                          5.0
                                          {/* {handleDecimalNumber(
                                            item?.user?.directorDetails?.ratings
                                          )} */}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="task--items--edit--delete">
  <div className="button--lists-view">
    <div className="custom--btn-container">
      <button
        // onClick={() => handleCompleteJob(item?._id)}
        className="page-six custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
      >
        <span style={{ display: "flex", alignItems: "center" }}>
          <FaRegCircleCheck />
          &nbsp; Complete Job
        </span>
      </button>
    </div>
  </div>
</div>

                                  {/* <div className="task--items--edit--delete">
                                    {item?.taskCompletionId
                                      ?.hustlerMarkedCompleted ||
                                      item.taskCompletionId
                                        ?.reasonForNotCompleted ||
                                      item?.cancel ||
                                      !item?.stripePaymentStatus ? null :
                                      (
                                        <div className="button--lists-view">
                                          {item.oneTimePayment == false ? (
                                            <>
                                              {item?.subscription
                                                ?.intervalRecords[
                                                item?.subscription
                                                  ?.intervalRecords.length - 1
                                              ]?.jobCompletion?.director
                                                .reason ? (
                                                <> </>
                                              ) : item?.subscription
                                                ?.intervalRecords[
                                                item?.subscription
                                                  ?.intervalRecords.length - 1
                                              ]?.jobCompletion?.director
                                                .isCompleted == true &&
                                                item?.subscription
                                                  ?.intervalRecords[
                                                  item?.subscription
                                                    ?.intervalRecords.length - 1
                                                ]?.jobCompletion?.hustler
                                                  .isCompleted == true ? (
                                                <> </>
                                              ) : item?.subscription
                                                ?.intervalRecords[
                                                item?.subscription
                                                  ?.intervalRecords.length - 1
                                              ]?.jobCompletion?.hustler
                                                .isCompleted == true ? (
                                                <> </>
                                              ) : (
                                                <>
                                                  {item?.latestCounterOffer
                                                    ?.finalDate &&
                                                    (moment().format("LL") ===
                                                      moment(
                                                        item?.latestCounterOffer
                                                          ?.finalDate
                                                      ).format("LL") ||
                                                      moment().format("LL") ===
                                                      moment(
                                                        item?.nextDueDate
                                                      ).format("LL")) ?
                                                      
                                                      (
                                                    <button
                                                      onClick={() =>
                                                        handleCompleteRecurringJob(
                                                          item
                                                        )
                                                      }
                                                      className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                                                    >
                                                      <span style={{ display: "flex", alignItems: "center" }}>
                                                        <FaRegCircleCheck />
                                                        &nbsp; Complete Job
                                                      </span>

                                                    </button>
                                                  ) : 
                                                  item?.taskProposal
                                                    ?.proposalDate &&
                                                    (moment().format("LL") ===
                                                      moment(
                                                        item?.taskProposal
                                                          ?.proposalDate
                                                      ).format("LL") ||
                                                      moment().format("LL") ===
                                                      moment(
                                                        item?.nextDueDate
                                                      ).format("LL")) ? (
                                                    <button
                                                      onClick={() =>
                                                        handleCompleteRecurringJob(
                                                          item
                                                        )
                                                      }
                                                      className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                                                    >
                                                      <span style={{ display: "flex", alignItems: "center" }}>
                                                        <FaRegCircleCheck />
                                                        &nbsp; Complete Job
                                                      </span>
                                                    </button>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              {item?.latestCounterOffer
                                                ?.finalDate &&
                                                (moment().format("LL") ===
                                                  moment(
                                                    item?.latestCounterOffer
                                                      ?.finalDate
                                                  ).format("LL") ||
                                                  moment().format("LL") ===
                                                  moment(
                                                    item?.nextDueDate
                                                  ).format("LL")) ? (
                                                <button
                                                  onClick={() =>
                                                    handleCompleteJob(item?._id)
                                                  }
                                                  className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                                                >
                                                  <span style={{ display: "flex", alignItems: "center" }}>
                                                    <FaRegCircleCheck />
                                                    &nbsp; Complete Job
                                                  </span>
                                                </button>
                                              ) :
                                                item?.taskProposal?.proposalDate &&

                                                  (moment().isSameOrAfter(moment(item?.taskProposal?.proposalDate), 'day') ||
                                                    moment().isSameOrAfter(moment(item?.nextDueDate), 'day'))


                                                  ? (
                                                    <button
                                                      onClick={() =>
                                                        handleCompleteJob(item?._id)
                                                      }
                                                      className="page-six custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                                                    >
                                                      <span style={{ display: "flex", alignItems: "center" }}>
                                                        <FaRegCircleCheck />
                                                        &nbsp; Complete Job 
                                                      </span>
                                                    </button>
                                                  )
                                                  : (
                                                    <></>
                                                  )}
                                            </>
                                          )}
                                        </div>
                                      )}
                                  </div> */}
                                </div>

                                {/* {item.oneTimePayment == false &&
                                  item?.nextDueDate && (
                                    <div>
                                      {item?.subscription?.intervalRecords[
                                        item?.subscription?.intervalRecords
                                          .length - 1
                                      ]?.jobCompletion?.director.reason ||
                                        (item?.subscription?.intervalRecords[
                                          item?.subscription?.intervalRecords
                                            .length - 1
                                        ]?.jobCompletion?.director.isCompleted ==
                                          true &&
                                          item?.subscription?.intervalRecords[
                                            item?.subscription?.intervalRecords
                                              .length - 1
                                          ]?.jobCompletion?.hustler.isCompleted ==
                                          true) ||
                                        item?.subscription?.intervalRecords[
                                          item?.subscription?.intervalRecords
                                            .length - 1
                                        ]?.jobCompletion?.hustler.isCompleted ==
                                        true ? (
                                        <> </>
                                      ) : (
                                        <>
                                          {" "}
                                          <div className="next-due-date">
                                            <div className="task--items--content--icon">
                                              <AiOutlineCalendar />
                                            </div>
                                            {item?.latestCounterOffer
                                              ?.finalDate ? (
                                              <span>
                                                Next Job Date:{" "}
                                                {moment().isBefore(
                                                  moment(
                                                    item?.latestCounterOffer
                                                      .finalDate
                                                  )
                                                )
                                                  ? moment(
                                                    item?.latestCounterOffer
                                                      .finalDate
                                                  ).format("LL")
                                                  : moment(
                                                    item?.nextDueDate
                                                  ).format("LL")}
                                              </span>
                                            ) : (
                                              <span>
                                                Next Job Date:{" "}
                                                {moment().isBefore(
                                                  moment(
                                                    item?.taskProposal
                                                      ?.proposalDate
                                                  )
                                                )
                                                  ? moment(
                                                    item?.taskProposal
                                                      ?.proposalDate
                                                  ).format("LL")
                                                  : moment(
                                                    item?.nextDueDate
                                                  ).format("LL")}
                                              </span>
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  )}
 */}
                             
                                {/* <div className="task--items--details">
                                  <div
                                    className="task--detail-full"
                                    style={{ width: "100%" }}
                                  >
                                    <Link
                                      to={`/job-details/${item._id}`}
                                      style={{
                                        textDecoration: "none",
                                        color: "#000",
                                      }}
                                    >
                                      <h2 className="hover-text">
                                        {item?.title}
                                      </h2>
                                    </Link>
                                    <div className="service--category--task">
                                      <h3>{item?.serviceName}</h3>
                                    </div>
                                  </div>
                                </div> */}
                                <div className="task--items--details">
  <div
    className="task--detail-full"
    style={{ width: "100%" }}
  >
    <Link
    //   to={`/job-details/12345`} {/* Static value */}
      style={{
        textDecoration: "none",
        color: "#000",
      }}
    >
      <h2 className="hover-text">I need my lawn mowing</h2> {/* Static value */}
    </Link>
    <div className="service--category--task">
      <h3>lawn mowing</h3> {/* Static value */}
    </div>
  </div>
</div>


                                {/* <div className="task--items--content">
                                  <p>
                                    <span className="task--items--content--icon">
                                      <AiOutlineCalendar />
                                    </span>
                                    {item?.latestCounterOffer?.finalDate ? (
                                      <>
                                        Final Date: {setReportFormatDate(item?.latestCounterOffer?.finalDate)}
                                      </>
                                    ) : item?.endDate ? (
                                      <>
                                        Final Date: {setReportFormatDate(item?.taskProposal?.proposalDate)}

                                      </>
                                    ) : (
                                      <>
                                        {setReportFormatDate(item?.dueDate)}
                                      </>
                                    )}
                                  </p>
                                  <p>
                                    <span className="task--items--content--icon">
                                      <TbMoneybag />
                                    </span>{" "}
                                    {item?.finalPrice ? (
                                      <p>
                                        Final Price: $ {BudgetFormat(`${item?.finalPrice}`)}
                                      </p>
                                    ) : (
                                      item?.budget === 0 ? (
                                        <p>
                                          Budget: Open to Offer
                                        </p>
                                      ) :
                                        (
                                          <p>
                                            Budget: $ {BudgetFormat(`${item?.budget}`)}
                                          </p>
                                        )
                                    )}

                                  </p>
                                  <p>
                                    <span className="task--items--content--icon">
                                      <GrLocation />
                                    </span>{" "}
                                    Area: {item?.cityName}
                                  </p>
                                </div> */}

<div className="task--items--content">
  <p>
    <span className="task--items--content--icon">
      <AiOutlineCalendar />
    </span>
    Final Date: January 1, 2024 {/* Static date */}
  </p>
  <p>
    <span className="task--items--content--icon">
      <TbMoneybag />
    </span>
    <p>Final Price: $65.00</p> {/* Static price */}
  </p>
  <p>
    <span className="task--items--content--icon">
      <GrLocation />
    </span>
    Area: Kamloops
    {/* Area: Static City Name Static city */}
  </p>
</div>

                                {/* <div className="task--items-footer">
                                  <p>
                                    {item?.description?.length > 350
                                      ? `${item?.description?.substring(
                                        0,
                                        350
                                      )}...`
                                      : `${item?.description}`}
                                  </p>
                                </div> */}
                                <div className="task--items-footer">
  <p>
  My lawn is 100 meter square    
  </p>
</div>


                              </div>

                              {/* {item?.oneTimePayment ? null : (
                                <JobFrequently item={item} />
                              )} */}

                            </div>
      
  )
}

export default HustlerJobsInProgress
