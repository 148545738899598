import React, { useState, useEffect } from "react";
import { PiNotePencilDuotone } from "react-icons/pi";
import { authAxios } from "../services/config";
import { toast } from "react-toastify";
import { CanadianCity } from "../utils/constants";
import Select from "react-select";
import ProfileRattingDetails from "./ProfileRattingDetails";
import { useSelector } from "react-redux";
import { setReportFormatDate } from "../assets/Helper";
import { FaEye } from "react-icons/fa6";
import ChatIdTask from "./ChatIdTask";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { handleDecimalNumber } from "../utils/helper";
import { State, City } from "country-state-city";
import IsLoadingHOC from "./IsLoadingHOC";
import { FaUser, FaBriefcase, FaStar } from "react-icons/fa6";
import { Tooltip } from "react-tooltip";
import { AiOutlineInfoCircle } from "react-icons/ai";


const DirectorProfile = (props) => {
  const { setLoading } = props;
  const [activeLink, setActiveLink] = useState("my-profile");
  const [comments, setcomments] = useState([]);
  const [showTaskDetailsModel, setshowTaskDetailsModel] = useState(false);
  const [taskDetails, settaskDetails] = useState([]);

  const userId = useSelector((state) => state?.auth?.user?.userId);

  const [provinceList, setprovinceList] = useState([]);
  const [cityList, setcityList] = useState([]);
  const [stateCode, setStateCode] = useState("");

  const [userDetails, setuserDetails] = useState({
    personalDetails: {
      email: "",
      fname: "",
      lname: "",
      phone: "",
      description: "",
      status:"",
      profilePic: "",
    },
    securityDetails: {
      securityArea: "",
      securityLevel: "",
    },
    workDetails: {
      province: "",
      location: "",
    },
  });

    // Handle button click to toggle status
    const toggleStatus = () => {
      setuserDetails((prev) => ({
        ...prev,
        personalDetails: {
          ...prev.personalDetails,
          status: prev.personalDetails.status === "active" ? "inactive" : "active",
        },
      }));
    };
  
  const [useradditionalDetails, setuseradditionalDetails] = useState({
    location: "",
    province: "",
  });
  const [expandedComments, setExpandedComments] = useState([]);

  const toggleReadMore = (index) => {
    if (expandedComments.includes(index)) {
      setExpandedComments(expandedComments.filter((i) => i !== index));
    } else {
      setExpandedComments([...expandedComments, index]);
    }
  };

  const fetchallReview = async () => {
    await authAxios()
      .get(`review/specific-user-reviews/${userId}`)
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          setcomments(resData.data);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const fetchTaskDetails = async (id) => {
    await authAxios()
      .get(`/task/get-specific-task/${id}`)
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          settaskDetails(resData.data);
          setshowTaskDetailsModel(true);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleChange = (e) => {

    const { name, value } = e.target;

    setuserDetails((prev) => ({
      ...prev,
      personalDetails: {
        ...prev.personalDetails,
        [name]: value,
      },
    }));
  };

  const handleAlphabet = (e) => {
    const { name, value } = e.target;
    // const regex = /^[A-Za-z ]*$/;
    const regex = name === "lname" ? /^[A-Za-z\- ]*$/ : /^[A-Za-z ]*$/;

    if (regex.test(value)) {
      setuserDetails((prev) => ({
        ...prev,
        personalDetails: {
          ...prev.personalDetails,
          [name]: value,
        },
      }));
    }
  };
  const navLinks = [
    { id: "my-profile", text: "My Profile", icon: <FaUser /> },
    { id: "work-profile", text: "Work Profile", icon: <FaBriefcase /> },
    { id: "reviews-profile", text: "View Reviews", icon: <FaStar /> },
  ];
  

  // const navLinks = [
  //   { id: "my-profile", text: "My Profile" },
  //   { id: "work-profile", text: "Work Profile" },
  //   { id: "reviews-profile", text: "View Reviews" },
  // ];

  const handleProfileChange = async (e) => {
    const file = e.target.files[0];
    setLoading(true);
    const formData = new FormData();
    formData.append("profile_picture", file);
    await authAxios()
      .put("/users/update-profile-picture", formData)
      .then((response) => {
        const resData = response.data;
        setLoading(false);
        if (resData.status == 1) {
          toast.success(resData.message);
          fetchUserDetails();
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };

  const fetchUserDetails = async () => {
    await authAxios()
      .get("profile/view-profile")
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          setuseradditionalDetails((prev) => ({
            ...prev,
            location: {
              label: resData.data.workDetails.location,
              value: resData.data.workDetails.location,
            },
            province: {
              label: resData.data.workDetails.province,
              value: resData?.data?.workDetails?.province,
            },
          }));

          const data = State.getStatesOfCountry("CA").map(
            ({ isoCode, name }) => ({
              value: isoCode,
              label: name,
            })
          );

          const filterData = data.filter(
            (item) => item.label == resData?.data?.workDetails?.province
          );

          // setcityList(
          //   City.getCitiesOfState("CA", filterData[0]?.value).map(
          //     ({ name }) => ({
          //       value: name,
          //       label: name,
          //     })
          //   )
          // );
          
          setuserDetails(resData?.data);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    // setprovinceList(
    //   State.getStatesOfCountry("CA").map(({ isoCode, name }) => ({
    //     value: isoCode,
    //     label: name,
    //   }))
    // );
     setprovinceList(
      [ { value: "BC", label: "British Columbia" },
        {value: "SK",label: "Saskatchewan"}
     ]
      );
  }, []);


  // {value: "Saskatoon",label: "Saskatoon"}
  useEffect(() => {

    setcityList([])
if(stateCode=="BC"){
    setcityList(
      [ {"value": "Kamloops", "label": "Kamloops"},
        {"value": "Kelowna","label": "Kelowna"},
        {"value": "Peachland","label": "Peachland"},
        {"value": "Penticton","label": "Penticton"},
        {"value": "Vernon","label": "Vernon"},
        {"value": "West Kelowna","label": "West Kelowna"},
      ]
    )
  }
  else{

    setcityList(
      [ {value: "Saskatoon",label: "Saskatoon"}
      ]
    )


  }
  // if(stateCode=="Sk"){
  //   setcityList(
  //     [ {value: "Saskatoon",label: "Saskatoon"}
  //     ]
  //   )
  // }

// cityList
    // if (stateCode) {
    //   setcityList(
    //     City.getCitiesOfState("CA", stateCode).map(({ name }) => ({
    //       value: name,
    //       label: name,
    //     }))
    //   );
    // }


  }, [stateCode]);


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!useradditionalDetails?.location?.label) {
      toast.info("Please enter your city.");
      return false;
    } else if (userDetails.personalDetails.fname == "") {
      toast.info("Please enter your first name.");
      return false;
    } else if (userDetails.personalDetails.lname == "") {
      toast.info("Please enter your last name.");
      return false;
    }else if (!userDetails.personalDetails.phone.startsWith('+1')) {
      toast.info("Phone number must start with +1.");
      return false;
    } else if (!/^\+1\d{10}$/.test(userDetails.personalDetails.phone)) {
      toast.info("Phone number must be in the format +1 followed by 10 digits.");
      return false;
    }
    setLoading(true);
    const payLoad = {
      personalDetails: {
        email: userDetails.personalDetails.email,
        fname: userDetails.personalDetails.fname,
        lname: userDetails.personalDetails.lname,
        phone: userDetails.personalDetails.phone,
        description: userDetails.personalDetails.description,
        status:userDetails.personalDetails.status,
        profilePic: userDetails.personalDetails.profilePic,
      },
      securityDetails: {
        securityArea: "",
        securityLevel: "",
      },
      workDetails: {
        location: useradditionalDetails.location.value,
        province: useradditionalDetails.province.value,
      },
    };

    await authAxios()
      .put("/profile/update-profile", payLoad)
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          toast.success(resData?.message);
          fetchUserDetails();
          setLoading(false);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };

  const handleAreaChange = (e, data) => {
    if (e == "province") {
      setStateCode(data?.value);

      setuseradditionalDetails((prev) => ({
        ...prev,
        province: { label: data?.label, value: data?.label },
        location: "",
      }));
    } else if (e == "location") {
      setuseradditionalDetails((prev) => ({
        ...prev,
        location: { label: data?.label, value: data?.label },
      }));
    }
  };

  useEffect(() => {
    fetchUserDetails();
    fetchallReview();
  }, []);

  const handleScrollChange = (divId) => {
    const element = document.getElementById(divId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setActiveLink(divId)

  };

  const handleVerify = async () => {
    await authAxios()
      .post("/stripe/reverify-account")
      
      .then((response) => {
        window.open(response.data.data.url);
      })
      .catch((error) => {
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="pt-117"></div>
        <div className="heading--page px-3">
          <h1 className="text--dark playFairDisplay heading-h1 mb-2">
            Profile
          </h1>
        </div>
        <div className="hustlers--wrapper setting--profile px-3 profile--setting-edit">
          <div className="sidebar">
            <ul>

              {navLinks.map((item) => (
                <>
                  <li
                    onClick={() => handleScrollChange(item.id)}
                    className={item.id == activeLink ? "active" : ""}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '10px',
                      cursor: 'pointer',
                      backgroundColor: item.id === activeLink ? "active" : '',
                      // transition: 'background-color 0.3s',
                    }}
                  >
                    {item.icon}
                    <a> {item.text}</a>
                  </li>
                </>
              ))}
            </ul>
          </div>
          <div className="main_content profile--details profile--setting-edit">
            <div id="my-profile" className="section">
              <div className="created--task--lists">
                <div className="task--items--header mb-2">
                  <h4 className="mb-3">Personal Details</h4>
                </div>
                <div className="task--items-footer">
                  <div id="contact" className="form" role="form">
                    <div className="row profile--image">
                      <div className="col-xs-12 col-md-12 form-group">
                        <div className="img--profile">
                          <input
                            type="file"
                            onChange={handleProfileChange}
                            accept=".jpg, .png .jpeg"
                          />

                          <img
                            src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${userDetails?.personalDetails?.profilePic}`}
                          />

                          <div className="input--upload--img">
                            <p>Profile Image</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xs-6 col-md-6 form-group">
                        <input
                          className="form-control"
                          id="firstName"
                          name="fname"
                          placeholder="First Name"
                          type="text"
                          autoFocus=""
                          onChange={handleAlphabet}
                          value={userDetails.personalDetails.fname}
                        />
                      </div>
                      <div className="col-xs-6 col-md-6 form-group">
                        <input
                          className="form-control"
                          id="lastName"
                          name="lname"
                          placeholder="Last Name"
                          type="text"
                          onChange={handleAlphabet}
                          value={userDetails.personalDetails.lname}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xs-12 col-md-12 form-group">
                        <input
                          className="form-control"
                          disabled
                          id="email"
                          name="email"
                          placeholder="Email"
                          type="email"
                          onChange={handleChange}
                          value={userDetails.personalDetails.email}
                        />
                      </div>
                    </div>
<div className="row">
  <div className="col-xs-12 col-md-12 form-group">
    <input
      className="form-control"
      id="phone"
      name="phone"
      placeholder="Mobile Phone"
      type="tel"
      autoFocus=""
      onChange={(e) => {
        // Ensure the value always starts with +1
        const value = e.target.value;
        if (value.startsWith('+1')) {
          handleChange(e);
        } else {
          handleChange({ ...e, target: { ...e.target, value: '+1' + value.replace(/^\+1/, '') } });
        }
      }}
      value={userDetails.personalDetails.phone.startsWith('+1') 
        ? userDetails.personalDetails.phone 
        : '+1' + userDetails.personalDetails.phone}
    />
  </div>
</div>

{/* <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "10px clamp(10px,2vw,23px)",
                            backgroundColor: "#f9f9f9",
                            borderRadius: "8px",
                            marginBottom:"15px",
                            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#333",
                            }}
                          >
                            Status
                          </span>
                          <div>
                            <span
                              onClick={toggleStatus} // Trigger toggle on click
                              data-tooltip-id={`Active-InActive`}

                              style={{
                                background: "black",
                                color: "white",
                                // color: userDetails.personalDetails.status === "active" ? 'white' : 'black',
                                // backgroundColor: userDetails.personalDetails.status === "active" ? 'black' : 'white', // Colors based on status
                          backgroundColor:"black",
                                padding: "5px 15px",
                                border: "none",
                                borderRadius: "10px",
                                cursor: "pointer",
                                transition: "background 0.3s ease",
                              }}
                            >
{userDetails.personalDetails.status === "active" ? 'Active' : 'Inactive'}&nbsp;<AiOutlineInfoCircle />
                            </span>
                          </div>
                        </div>
                        {" "}
                        <Tooltip
                      id={`Active-InActive`}
                      style={{ width: "210px" }}
                    >
Please click to set status                    
</Tooltip> */}


                    {/* <div className="row">
                      <div className="col-xs-12 col-md-12 form-group">
                        <input
                          className="form-control"
                          id="phone"
                          name="phone"
                          placeholder="Mobile Phone"
                          type="phone"
                          autoFocus=""
                          onChange={handleChange}
                          value={userDetails.personalDetails.phone}
                        />
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="col-xs-12 col-md-12 form-group">
                        <textarea
                          className="form-control"
                          name="description"
                          placeholder="Enter your description"
                          type="text"
                          autoFocus=""
                          onChange={handleChange}
                          value={userDetails.personalDetails.description}
                        />
                      </div>
                        {/* =========================================================================================                   */}
                
                  {/* <Tooltip
                      id={`tooltip-verfiy--account`}
                      style={{ width: "210px" }}
                    >
                      You can see the status of your stripe account here
                    </Tooltip>

                    {userDetails.personalDetails.isStripeAccountCreated ==
                      false ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "10px clamp(10px,2vw,23px)",
                            backgroundColor: "#f9f9f9",
                            borderRadius: "8px",
                            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#333",
                            }}
                          >
                            Account:
                          </span>
                          <div>
                            <span
                              data-tooltip-id={`tooltip-verfiy--account`}
                              onClick={handleVerify}
                              style={{
                                background: "black",
                                color: "white",
                                padding: "5px 15px",
                                border: "none",
                                borderRadius: "10px",
                                cursor: "pointer",
                                transition: "background 0.3s ease",
                              }}
                            >
                              Connect <AiOutlineInfoCircle />
                            </span>
                          </div>
                        </div>{" "}
                      </>
                    ) : (
                      <>
                        {" "}
                        {userDetails.personalDetails.stripeAccountStatus ==
                          "verified" ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "10px clamp(10px,2vw,23px)",
                              backgroundColor: "#f9f9f9",
                              borderRadius: "8px",
                              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                            }}
                          >

                            <span
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#333",
                              }}
                            >
                              Account:
                            </span>
                            <span
                              style={{
                                background: "black",
                                color: "white",
                                padding: "5px 15px",
                                border: "none",
                                borderRadius: "10px",
                                cursor: "pointer",
                                transition: "background 0.3s ease",
                              }}
                              data-tooltip-id={`tooltip-verfiy--account`}
                            >
                              Verified
                              <img
                                src="/assets/images/completetask.png"
                                alt="Verified"
                                style={{ marginLeft: "8px" }}
                              />
                            </span>
                          </div>
                        ) 
                        : (

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "10px clamp(10px,2vw,23px)",
                              backgroundColor: "#f9f9f9",
                              borderRadius: "8px",
                              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#333",
                              }}
                            >
                              Account:
                            </span>
                            <div>
                              <span
                                data-tooltip-id={`tooltip-verfiy--account`}
                                onClick={handleVerify}
                                style={{
                                  background: "black",
                                  color: "white",
                                  padding: "5px 15px",
                                  border: "none",
                                  borderRadius: "10px",
                                  cursor: "pointer",
                                  transition: "background 0.3s ease",
                                }}
                              >
                                Verify <AiOutlineInfoCircle />
                              </span>
                            </div>
                          </div>
                        )}{" "}
                      </>
                    )} */}

{/* ==================================================================================================== */}


                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="work-profile" className="section zIndex2">
              <div className="created--task--lists">
                <div className="task--items--header">
                  <h4 className="mb-3">Work Details</h4>
                </div>
                <div className="task--items-footer">
                  <div id="workProfileForm" className="form" role="form">
                    <div className="row">

                      <div className="col-xs-12 col-md-12 form-group ">
                        <label>Select your province</label>
                        <Select
                          required
                          className="select--custom"
                          options={provinceList}
                          placeholder={`Select your province`}
                          isClearable={false}
                          // value={userDetails.workDetails.location}
                          // onChange={handleAreaChange}
                          value={useradditionalDetails.province}
                          onChange={(e) => handleAreaChange("province", e)}
                        />

                      </div>

                    </div>

                    <div className="row">

                      <div className="col-xs-12 col-md-12 form-group ">
                        <label>Select your city</label>
                        <Select
                          className="select--custom"
                          options={cityList}
                          placeholder={`Select your city`}
                          // value={userDetails.workDetails.location}
                          // onChange={handleAreaChange}
                          value={useradditionalDetails.location}
                          onChange={(e) => handleAreaChange("location", e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="reviews-profile" >
              <div >
                <ProfileRattingDetails />
              </div>


              <div className="comments--profile--lists">
                <div className="reviews--section">
                  {!comments && (
                    <div className="no--comments">
                      <h5 style={{ textAlign: "center", color: "#ada7a7" }}>
                        No Reviews
                      </h5>
                    </div>
                  )}

                  {comments &&
                    comments.map((item, index) => {
                      const isExpanded = expandedComments.includes(index);
                      const commentText =
                        item?.comments.length > 100 && !isExpanded
                          ? `${item?.comments.substring(0, 100)}...`
                          : item?.comments;

                      return (
                        <div className="user--comment--items" key={item._id}>
                          <div className="user--comment--header">
                            <div className="user--comment--details">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${item?.reviewSender.profilePic}`}
                                alt=""
                                className="user--profile--img"
                                style={{
                                  height: "80px",
                                  width: "80px",
                                  borderRadius: "10px",
                                  objectFit: "cover",
                                }}
                              />
                              <div className="user--comment--detail">
                                <h3>
                                  {item?.reviewSender.fname} {item?.reviewSender.lname}
                                </h3>
                                <span className="user--rating--comment">
                                  <img src="/assets/images/hustler-star.png" alt="rating" />
                                  {handleDecimalNumber(item?.rating)}
                                </span>{" "}
                                {setReportFormatDate(item?.createdAt)}
                              </div>
                            </div>
                            <div
                              className="view--task--comment"
                              onClick={() => fetchTaskDetails(item?.taskId)}
                            >
                              <MdOutlineRemoveRedEye
                                style={{ cursor: "pointer" }}
                                color="white"
                              />
                            </div>
                          </div>

                          <div className="user--comment--description">
                            <p>{commentText}</p>
                            {item?.comments.length > 100 && (
                              <span
                                className="read-more-toggle"
                                onClick={() => toggleReadMore(index)}
                                style={{ cursor: "pointer", color: "#1f1f28", textDecoration: "underline", fontWeight:'600' }}
                              >
                                {isExpanded ? "Read Less" : "Read More"}
                              </span>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>

            </div>


            <button
              type="submit"
              className="custom--btn ligghtbtn-hover transparent--btn minbutton mb-3"
            >
              <span>Save Changes</span>
            </button>
          </div>
        </div>
      </form>

      {showTaskDetailsModel && (
        <ChatIdTask
          text="profile"
          setshowTaskDetailsModel={setshowTaskDetailsModel}
          taskDetails={taskDetails}
        />
      )}
    </>
  );
};

export default IsLoadingHOC(DirectorProfile);
