import React from "react";
import { Helmet } from "react-helmet";

const Metadata = ({ 
  title, 
  description, 
  robots = "index, follow",// Default to allow indexing and following links
  keywords = "" // Optional keywords meta tag 
 
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="robots" content={robots} />
      {keywords && <meta name="keywords" content={keywords} />} {/* Include keywords only if provided */}

    </Helmet>
  );
};

export default Metadata;


// import React from "react";
// import { Helmet } from "react-helmet";

// const Metadata = ({ title, description }) => {
//   return (
//     <Helmet>
//       <title>{title}</title>
//       <meta name="description" content={description} />
//     </Helmet>
//   );
// };

// export default Metadata;
