import React, { useEffect, useRef, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { Rating } from "react-simple-star-rating";
import { handleDecimalNumber, handleEmailandPhoneCheck } from "../utils/helper";
import SensitiveModel from "./Modal/SensitiveModel";
import WarningPopup from "./WarningPopup";
import { toast } from "react-toastify";

const AddReviews = ({
  setshowReviewModel,
  SensitiveWords,
  handleSubmitReview,
  taskReviewDetails,
  settaskReviewDetails,
  handleEditReview,
  review // using review prop to disable form fields and hide update button
}) => {
  const [data, setdata] = useState({
    reviewReceiver: "",
    rating: 0,
    behavior: 0,
    availability: 0,
    skills: 0,
    communication: 0,
    comment: "",
    taskId: "",
  });
  const [showSensitveModel, setshowSensitveModel] = useState(false);
  
  useEffect(() => {
    if (taskReviewDetails) {
      setdata((prev) => ({
        ...prev,
        rating: taskReviewDetails?.rating || 0,
        behavior: taskReviewDetails?.behavior || 0,
        availability: taskReviewDetails?.availability || 0,
        skills: taskReviewDetails?.skills || 0,
        communication: taskReviewDetails?.communication || 0,
        comment: taskReviewDetails?.comments || "",
        taskId: taskReviewDetails?._id || "",
      }));
    }
  }, [taskReviewDetails]);

  const inputRef = useRef(null);

  const handleRating = (key, rate) => {
    setdata((prev) => ({
      ...prev,
      [key]: rate,
    }));
  };

  const handleSubmit = () => {
    if (data.behavior === 0) {
      toast.error("Please rate Professionalism");
    } else if (data.availability === 0) {
      toast.error("Please rate availability");
    } else if (data.communication === 0) {
      toast.error("Please rate communication");
    } else if (data.skills === 0) {
      toast.error("Please rate skills");
    } else {
      handleSubmitReview(data);
    }
  };

  const handleEdit = () => {
    handleEditReview(data);
  };

  const handlecommentCheck = (e) => {
    const inputValue = e.target.value;
    setdata((prev) => ({ ...prev, comment: inputValue }));

    if (inputRef.current) {
      const containsSensitiveWord = SensitiveWords.some((word) =>
        inputValue.toLowerCase().includes(word)
      );
      const checkEmailandPhone = handleEmailandPhoneCheck(inputValue);

      if (checkEmailandPhone || containsSensitiveWord) {
        inputRef.current.style.color = "red";
        setshowSensitveModel(true);
      } else {
        inputRef.current.style.color = "";
        setshowSensitveModel(false);
      }
    }
  };

  const handleCancelPopup = () => {
    settaskReviewDetails([]);
    setshowReviewModel(false);
  };

  return (
    <>
      <div className="show--interest--modal--overlay addview--overlay">
        <div className="show--interest--modal add--reviews--modal custom--scroll--thumb" style={{ height: "auto" }}>
          <div className="showInsterest--header">
            {Object.keys(taskReviewDetails).length === 0 ? (
              <h2 className="playFairDisplay heading-h3">Add Your Review</h2>
            ) : (
              <h2 className="playFairDisplay heading-h3">View Review</h2>
            )}

            <div className="right--side--header">
              <div className="rating--text">
                <p className="flex gap-2 items-center">
                  {data.rating > 0 && (
                    <>
                      <img src="/assets/images/bigstar.png" alt="rating" />
                      {handleDecimalNumber(data?.rating)}/5
                    </>
                  )}
                  <div className="close--popup" onClick={handleCancelPopup}>
                    <IoCloseSharp />
                  </div>
                </p>
              </div>
            </div>
          </div>

          <div className="add--reviews--list">
            <div className="form--field--ratting">
              <label>
                How do you evaluate <b><i>professionalism?</i></b> <span className="mendotry-field">*</span>
              </label>
              <Rating
                initialValue={data.behavior}
                onClick={(rate) => handleRating("behavior", rate)}
                readonly={review} // Disable if review is true
              />
            </div>
            <div className="form--field--ratting">
              <label>
                How do you evaluate <b><i>availability?</i></b> <span className="mendotry-field">*</span>
              </label>
              <Rating
                initialValue={data.availability}
                onClick={(rate) => handleRating("availability", rate)}
                readonly={review} // Disable if review is true
              />
            </div>
            <div className="form--field--ratting">
              <label>
                How do you evaluate <b><i>communication?</i></b> <span className="mendotry-field">*</span>
              </label>
              <Rating
                initialValue={data.communication}
                onClick={(rate) => handleRating("communication", rate)}
                readonly={review} // Disable if review is true
              />
            </div>
            <div className="form--field--ratting">
              <label>
                How do you evaluate <b><i>skills?</i></b> <span className="mendotry-field">*</span>
              </label>
              <Rating
                initialValue={data.skills}
                onClick={(rate) => handleRating("skills", rate)}
                readonly={review} // Disable if review is true
              />
            </div>
            <div className="form--field--ratting comment--rating">
              <label>Add Your Review</label>
              <textarea
                value={data.comment}
                name="comment"
                ref={inputRef}
                className="p-2"
                onChange={handlecommentCheck}
                disabled={review} // Disable if review is true
              ></textarea>
            </div>
          </div>

          {taskReviewDetails?._id && !review && ( // Hide update button if review is true
            <div className="add--ratting--footer">
              {showSensitveModel ? (
                <button
                  type="button"
                  disabled
                  style={{ cursor: "not-allowed" }}
                  className="custom--btn darkbtn-hover"
                >
                  <span>Update</span>
                </button>
              ) : (
                <button
                  type="button"
                  className="custom--btn darkbtn-hover"
                  onClick={handleEdit}
                >
                  <span>Update</span>
                </button>
              )}

              <button
                type="button"
                className="custom--btn ligghtbtn-hover transparent--btn"
                onClick={handleCancelPopup}
              >
                <span>Cancel</span>
              </button>
            </div>
          )}
          
          {!taskReviewDetails?._id && !review && (
            <div className="add--ratting--footer">
              {showSensitveModel ? (
                <button
                  type="button"
                  className="custom--btn darkbtn-hover"
                  disabled
                  style={{ cursor: "not-allowed" }}
                >
                  <span>Submit</span>
                </button>
              ) : (
                <button
                  type="button"
                  className="custom--btn darkbtn-hover"
                  onClick={handleSubmit}
                >
                  <span>Submit</span>
                </button>
              )}

              <button
                type="button"
                className="custom--btn ligghtbtn-hover transparent--btn"
                onClick={handleCancelPopup}
              >
                <span>Cancel</span>
              </button>
            </div>
          )}
          
          {showSensitveModel && <WarningPopup />}
        </div>
      </div>
    </>
  );
};

export default AddReviews;









// import React, { useEffect, useRef, useState } from "react";
// import { IoCloseSharp } from "react-icons/io5";
// import { Rating } from "react-simple-star-rating";
// import { handleDecimalNumber, handleEmailandPhoneCheck } from "../utils/helper";
// import SensitiveModel from "./Modal/SensitiveModel";
// import WarningPopup from "./WarningPopup";
// import { toast } from "react-toastify";

// const AddReviews = ({
//   setshowReviewModel,
//   SensitiveWords,
//   handleSubmitReview,
//   taskReviewDetails,
//   settaskReviewDetails,
//   handleEditReview,
//   review
// }) => {
//   const [data, setdata] = useState({
//     reviewReceiver: "",
//     rating: 0,
//     behavior: 0,
//     availability: 0,
//     skills: 0,
//     communication: 0,
//     comment: "",
//     taskId: "",
//   });
//   const [showSensitveModel, setshowSensitveModel] = useState(false);
//   useEffect(() => {
//     if (taskReviewDetails) {
//       setdata((prev) => ({
//         ...prev,

//         rating: taskReviewDetails?.rating || 0,
//         behavior: taskReviewDetails?.behavior || 0,
//         availability: taskReviewDetails?.availability || 0,
//         skills: taskReviewDetails?.skills || 0,
//         communication: taskReviewDetails?.communication || 0,
//         comment: taskReviewDetails?.comments || "",
//         taskId: taskReviewDetails?._id || "",
//       }));
//     }
//   }, [taskReviewDetails]);

//   const inputRef = useRef(null);

//   const handleRating = (key, rate) => {
//     setdata((prev) => ({
//       ...prev,
//       [key]: rate,
//     }));
//   };

//   const handleSubmit = () => {

//     if (data.behavior == 0) {
//       toast.error('Please rate Professionalism')
//     } else if (data.availability == 0) {
//       toast.error('Please rate availability')
//     } else if (data.communication == 0) {
//       toast.error('Please rate communication')
//     } else if (data.skills == 0) {
//       toast.error('Please rate skills')
//     }
//     // else if(data.comment==0){
//     //   toast.error('Please add your Comment')
//     // }
//     else {
//       handleSubmitReview(data)
//     }


//   };

//   const handleEdit = () => {
//     handleEditReview(data);
//   };

//   const handlecommentCheck = (e) => {
//     const inputValue = e.target.value;
//     setdata((prev) => ({ ...prev, comment: e.target.value }));


//     if (inputRef.current) {

//       const containsSensitiveWord = SensitiveWords.some((word) =>
//         inputValue.toLowerCase().includes(word)
//       );
//       const checkEmailandPhone = handleEmailandPhoneCheck(inputValue)

//       if (checkEmailandPhone || containsSensitiveWord) {
//         inputRef.current.style.color = "red";
//         setshowSensitveModel(true);
//       } else {
//         inputRef.current.style.color = "";
//         setshowSensitveModel(false);
//       }
//     }
//   };

//   const handleCancelPopup = () => {
//     settaskReviewDetails([]);
//     setshowReviewModel(false);
//   };

//   return (
//     <>
//       <div className="show--interest--modal--overlay addview--overlay">
//         <div
//           className="show--interest--modal add--reviews--modal custom--scroll--thumb"
//           style={{ height: "auto" }}
//         >
//           <div className="showInsterest--header">
//             {Object.keys(taskReviewDetails).length === 0 ? (
//               <h2 className="playFairDisplay heading-h3">Add Your Review</h2>
//             ) : (
//               <h2 className="playFairDisplay heading-h3">View Review</h2>
//             )}

//             <div className="right--side--header">
//               <div className="rating--text">
//                 <p className="flex gap-2 items-center">
//                   {data.rating > 0 ? (
//                     <>
//                       <img src="/assets/images/bigstar.png" />
//                       {handleDecimalNumber(data?.rating)}/5
//                     </>
//                   ) : null}
//                   <div className="close--popup" onClick={handleCancelPopup}>
//                       <IoCloseSharp />
//                     </div>
//                 </p>
//               </div>
//             </div>
//           </div>

//           <div className="add--reviews--list">
//             <div className="form--field--ratting">
//               <label>
//                 How do you evaluate{" "}
//                 <b>
//                   <i>professionalism?</i>
//                 </b> <span className="mendotry-field">*</span>
//               </label>
//               <Rating
//                 initialValue={data.behavior}
//                 onClick={(rate) => handleRating("behavior", rate)}
//               />
//             </div>
//             <div className="form--field--ratting">
//               <label>
//                 How do you evaluate{" "}
//                 <b>
//                   <i>availability?</i>
//                 </b> <span className="mendotry-field">*</span>
//               </label>
//               <Rating
//                 initialValue={data.availability}
//                 onClick={(rate) => handleRating("availability", rate)}
//               />
//             </div>
//             <div className="form--field--ratting">
//               <label>
//                 How do you evaluate{" "}
//                 <b>
//                   <i>communication?</i>
//                 </b> <span className="mendotry-field">*</span>
//               </label>
//               <Rating
//                 initialValue={data.communication}
//                 onClick={(rate) => handleRating("communication", rate)}
//               />
//             </div>
//             <div className="form--field--ratting">
//               <label>
//                 How do you evaluate{" "}
//                 <b>
//                   <i>skills?</i>
//                 </b> <span className="mendotry-field">*</span>
//               </label>
//               <Rating
//                 initialValue={data.skills}
//                 onClick={(rate) => handleRating("skills", rate)}
//               />
//             </div>
//             {/*
//             <div className="form--field--ratting">
//               <label>
//                 Overall{" "}
//                 <b>
//                   <i>Rating ?</i>
//                 </b>
//               </label>
//               <Rating
//                 initialValue={data.rating}
//                 onClick={(rate) => handleRating("rating", rate)}
//               />
//             </div>
//              */}
//             <div className="form--field--ratting comment--rating">
//               <label>Add Your Review</label>
//               <textarea
//                 value={data.comment}
//                 name="comment"
//                 ref={inputRef}
//                 className="p-2"
//                 onChange={handlecommentCheck}
//               ></textarea>
//             </div>
//           </div>

//           {taskReviewDetails?._id ? (
//             <div className="add--ratting--footer">
//               {showSensitveModel == true ? (
//                 <button
//                   type="button"
//                   disabled
//                   style={{ cursor: "not-allowed" }}
//                   className="custom--btn darkbtn-hover"
//                 // onClick={handleEdit}
//                 >
//                   <span>Update</span>
//                 </button>
//               ) : (
//                 <button
//                   type="button"
//                   className="custom--btn darkbtn-hover"
//                   onClick={handleEdit}
//                 >
//                   <span>Update</span>
//                 </button>
//               )}

//               <button
//                 type="button"
//                 className="custom--btn ligghtbtn-hover transparent--btn"
//                 onClick={handleCancelPopup}
//               >
//                 <span>Cancel</span>
//               </button>
//             </div>
//           ) : (
//             <div className="add--ratting--footer">
//               {showSensitveModel == true ? (
//                 <button
//                   type="button"
//                   className="custom--btn darkbtn-hover"
//                   disabled
//                   style={{ cursor: "not-allowed" }}
//                 >
//                   <span>Submit</span>
//                 </button>
//               ) : (
//                 <button
//                   type="button"
//                   className="custom--btn darkbtn-hover"
//                   onClick={handleSubmit}
//                 >
//                   <span>Submit</span>
//                 </button>
//               )}

//               <button
//                 type="button"
//                 className="custom--btn ligghtbtn-hover transparent--btn"
//                 onClick={handleCancelPopup}
//               >
//                 <span>Cancel</span>
//               </button>
//             </div>
//           )}
//           {showSensitveModel && <WarningPopup />}
//         </div>
//       </div>
//     </>
//   );
// };

// export default AddReviews;
