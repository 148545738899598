import React from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { IoCloudDownloadOutline } from "react-icons/io5";
import SubscriptionInvoicePdf from "./SubscriptionInvoicePdf";
import moment from "moment";
import { dateFormat, payloadBudget } from "../../utils/helper";

const SubscriptionInvoice = () => {
  const subscriptionDetails = JSON.parse(
    sessionStorage.getItem("subscriptionDetails")
  );
  const FinalBid=sessionStorage.getItem("FinalBid")


  return (
    <div style={styles.container} className="director--task--lists pt-117">
      <div style={styles.invoiceBox}>
        <div style={styles.header}>
          <div style={styles.headerContent}>
            <a target="_blank" rel="noreferrer">
              <img
                alt="Logo"
                src="https://stripe-images.s3.amazonaws.com/emails/acct_1OhFFXF3HwezfEYa/5/twelve_degree_icon@2x.png"
                style={styles.logo}
              />
            </a>
          </div>
        </div>
        <div style={styles.titleContainer}>
          <h2 style={styles.title}>Receipt from SIDEHUSTL</h2>
        </div>
        <div style={styles.infoRow}>
          <div style={styles.infoColumn}>
            <span style={styles.label}>Plan Amount</span>
            <span style={styles.value}>
              ${(subscriptionDetails?.plan?.amount / 100)?.toFixed(2)}
            </span>
          </div>
          <div style={styles.infoColumn}>
            <span style={styles.label}>Invoice Date</span>
            {subscriptionDetails?.latestInvoice?.created && (
              <span style={styles.value}>
                {" "}
                {dateFormat(subscriptionDetails?.latestInvoice?.created)}{" "}
              </span>
            )}
          </div>
        </div>
        <div style={styles.section}>
          <h3 style={styles.sectionTitle}>Latest Invoice</h3>
          <div style={styles.summaryContainer}>
            <div style={styles.summaryRow}>
              <span style={styles.label}>Invoice Date</span>
              <span style={styles.value}>
                {dateFormat(
                  subscriptionDetails.intervalRecords[
                    subscriptionDetails.intervalRecords.length - 1
                  ].startDate
                )}
              </span>
            </div>

            <div style={styles.summaryRow}>
              <span style={styles.label}>Payment to SIDEHUSTL</span>
              <span style={styles.value}>
                $
                {FinalBid}
              </span>
            </div>
            <div style={styles.summaryRow}>
              <span style={styles.label}>Service fee</span>
              <span style={styles.value}>
              ${payloadBudget(`${ (0.18) * FinalBid}`)}
              </span>
            </div>

            <div style={styles.summaryRow}>
              <span style={styles.label}>Total Amount Charged</span>
              <span style={styles.value}>
                ${(subscriptionDetails?.plan?.amount / 100)?.toFixed(2)}
              </span>
            </div>
           
          </div>
        </div>
        <div style={styles.contactInfo}>
          <p style={styles.contactText}>
            If you have any questions, contact us at{" "}
            <a href="mailto:admin@sidehustl.ca" style={styles.contactLink}>
              admin@sidehustl.ca
            </a>
          </p>
        </div>
        <div style={styles.downloadButtonContainer}>
          <PDFDownloadLink
            document={
              <SubscriptionInvoicePdf
                subscriptionDetails={subscriptionDetails}
                FinalBid={FinalBid}
              />
            }
            fileName="Subscription_Invoice"
            style={{ textDecoration: "none" }}
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                "Loading..."
              ) : (
                <button style={styles.downloadButton}>
                  Download Invoice
                  <span style={styles.icon}>
                    <IoCloudDownloadOutline />
                  </span>
                </button>
              )
            }
          </PDFDownloadLink>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "#f7fafc",
    paddingBottom: 50,
  },
  invoiceBox: {
    backgroundColor: "#ffffff",
    paddingBottom: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    width: "95%",
    maxWidth: "800px",
    margin: "0 auto",
  },
  header: {
    backgroundColor: "#000000",
    borderRadius: "8px 8px 0 0",
  },
  headerContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  logo: {
    width: "40px",
  },
  titleContainer: {
    textAlign: "center",
    padding: "40px 16px 20px",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#374151",
  },
  infoRow: {
    padding: "16px 16px 30px",
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    justifyContent: "space-between",
  },
  infoColumn: {
    display: "flex",
    flexDirection: "column",
    fontSize: "14px",
  },
  label: {
    fontWeight: "600",
  },
  value: {
    marginTop: "4px",
    color: "#000000",
  },
  section: {
    padding: "0 16px 30px",
  },
  sectionTitle: {
    fontSize: "12px",
    fontWeight: "700",
    textTransform: "uppercase",
    color: "#000000",
    paddingBottom: "10px",
  },
  summaryContainer: {
    backgroundColor: "#f3f4f6",
    borderRadius: "8px",
    padding: "16px",
  },
  summaryRow: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "14px",
    marginTop: "8px",
    fontWeight: "600",
    color: "#000000",
  },
  contactInfo: {
    padding: "16px",
    color: "#374151",
  },
  contactText: {
    fontSize: "14px",
  },
  contactLink: {
    fontWeight: "700",
    color: "#000000",
    textDecoration: "none",
  },
  downloadButtonContainer: {
    marginBottom: "20px",
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    textDecoration: "none",
  },
  downloadButton: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "600",
    color: "#ffffff",
    backgroundColor: "#000000",
    padding: "10px 20px",
    borderRadius: "8px",
    border: "none",
    cursor: "pointer",
    transition: "background-color 0.3s",
  },
  icon: {
    marginLeft: "8px",
    fontSize: "18px",
  },
};

export default SubscriptionInvoice;