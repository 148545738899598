import React,{useEffect,useState} from 'react';
import { DateTime } from 'luxon';
import LandingPageBefore from './LandingPageBefore';
import LandingPageAfter from './LandingPageAfter';

const LandingPage = () => {
  const targetDate = DateTime.fromISO("2024-10-15T11:59:59", { zone: "America/Vancouver" }).toMillis();
  const currentDate = DateTime.now().setZone("America/Vancouver").toMillis()


  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  // Function to calculate the remaining time
  function calculateTimeLeft() {
    // const now = new Date().getTime();
    const now = DateTime.now().setZone("America/Vancouver").toMillis(); 

    const difference = targetDate - now;

    // Calculate time units
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  }

  useEffect(() => {
    // Update the timer every second
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);
    }, 1000);

    // Clean up the timer on component unmount
    return () => clearInterval(timer);
  }, []);


  return (

    <div>

      {currentDate < targetDate ? <LandingPageBefore  /> : <LandingPageAfter  />}
      {/* {true ? <LandingPageBefore  /> : <LandingPageAfter  />} */}

    </div>
  );
};

export default LandingPage;
