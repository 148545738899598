import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { useTour } from '../../contexts/TourContext';

import { AiOutlineCalendar } from "react-icons/ai";
import { TbMoneybag } from "react-icons/tb";
import { GrLocation } from "react-icons/gr";
import { BiTimeFive } from "react-icons/bi";
import IsLoadingHOC from "../../common/IsLoadingHOC";
import { toast } from "react-toastify";
import { authAxios } from "../../services/config";
import { setReportFormatDate } from "../../assets/Helper";
import { IoIosPeople } from "react-icons/io";
import moment from "moment";
import DeleteCreatedTask from "./DeleteTask";
import DirectorJobsStart from "./DirectorJobsStart";
// import DirectorTask from "./DirectorTask.1js";

import {
  MdPublic ,
  MdEmojiPeople,
  MdOutlineRemoveRedEye,
  MdReviews,
} from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsFillChatLeftTextFill, BsListTask } from "react-icons/bs";
import EditcreateTask from "./EditcreateTask";
import ConfirmProgressTask from "./ConfirmProgressTask";
import AddReviews from "../../common/AddReviews";
import HustlerAddReview from "../../common/HustlerAddReview";
import DeleteProgressTaskModal from "../../common/Modal/DeleteProgressTaskModal";
import { IoCheckmarkCircle, IoCloseCircleSharp ,IoEyeSharp} from "react-icons/io5";
import Pagination from "../../common/Pagination";
import { setParticipantDetails } from "../../Redux/Reducers/chatSlice";
import { useDispatch } from "react-redux";
import DirectorDeniedJobModel from "../../common/Modal/DirectorDeniedJobModel";
import {
  BudgetFormat,
  handleDecimalNumber,
  payloadBudget,
  serviceFeePercentage,
} from "../../utils/helper";
import { useSocket } from "../../contexts/SocketContext";
import { GoArrowRight } from "react-icons/go";
import RejectedJobReason from "../become-a-hustler/RejectedJobReason";
import { IoIosCloseCircle, IoIosCloseCircleOutline } from "react-icons/io";
import { LuPartyPopper } from "react-icons/lu";
import PaymentForm from "./PaymentForm";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import DirectorCounterOfferModel from "./DirectorCounterOfferModel";
import { PiEye } from "react-icons/pi";
import TaskCompletionRejectedModal from "./TaskCompletionRejectedModal";
import PublicJobModel from "../../common/PublicJobModel";
import DeclinedJobCompletedModal from "./DeclinedJobCompletedModal";
import ConfirmDeniedJobModel from "../../common/ConfirmDeniedJobModel";
import DirectorJobFrequently from "../../common/DirectorJobFrequently";
import EndSubsciption from "../../common/Modal/EndSubsciption";
import AnotherHustlrLists from "../../common/AnotherHustlrLists";
const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`
);

const DirectorTask = () => {
  return (
    <div>
                              <div
                        key={1}
                        className="created--task--lists process--task--list hover--effect"
                      >
                        {
                        
                        // item?.subscription?.intervalRecords[
                        //   item?.subscription?.intervalRecords.length - 1
                        // ]?.jobCompletion?.director.isCompleted == true &&
                        // item?.subscription?.intervalRecords[
                        //   item?.subscription?.intervalRecords.length - 1
                        // ]?.jobCompletion?.hustler.isCompleted == true ? 
                       false ?
                        (
                          <div className="waiting--msg">
                            <div>
                              <BiTimeFive /> Job period has been completed, now
                              wait for the next subscription period
                            </div>
                          </div>
                        ) 
                        
                        :

                        // item?.subscription?.intervalRecords[
                        //     item?.subscription?.intervalRecords.length - 1
                        //   ]?.jobCompletion?.director.reason ? 
                          false?
                        (
                          <div className="cancelled--interested-job">
                            <div>
                              <BiTimeFive /> You rejected the completion of the
                              specific job by the hustlr.
                            </div>
                            <div
                              className="view--reason"
                            //   onClick={() =>
                            //     setRejectedTaskModal({
                            //       model: true,
                            //       reason:
                            //         item?.subscription?.intervalRecords[
                            //           item?.subscription?.intervalRecords
                            //             .length - 1
                            //         ]?.jobCompletion?.director.reason,
                            //     })
                            //   }
                            >
                              View Reason
                              <GoArrowRight />
                            </div>
                          </div>
                        ) 
                        
                        : 
                        // item?.subscription?.intervalRecords[
                        //     item?.subscription?.intervalRecords.length - 1
                        //   ]?.jobCompletion?.hustler.isCompleted == true ?
                        false?
                           (
                          <div className="waiting--msg">
                            <BiTimeFive /> Hustlr have Completed the job
                          </div>
                        )
                         :
                        //   item?.counterOfferIds?.length &&
                        //   !item.cancel &&
                        //   item?.counterOfferAccepted == false ? 
                        false?
                          (
                            <div>
                          <div className="waiting--msg"                               
                          style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
>                            <div>
                            <BiTimeFive /> Hustlr has made a counteroffer.
                            </div>
                                                      <span
                                              className="payment--option"
                                            //   onClick={() => navigate(`/task/${item?.taskProposal?.task}`)}
                                              >
                                              View Job Details <GoArrowRight />
                                            </span>
                          </div>
                                                  </div>
                        
                        ) 
                        : 
                        false ? 
                        (
                          <div className="waiting--msg"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
>
                            <div>
                            <BiTimeFive /> Waiting for Hustlr's acceptance
                            </div>
                            <span
                            className="payment--option"
                            // onClick={() => navigate(`/task/${item?.taskProposal?.task}`)}
                            >
                            View Job Details <GoArrowRight />
                          </span>

                          </div>
                        )
                         : 
                        (
                          <>
                            {
                            // item?.cancel 
                            false
                            ? (
                              <div className="cancelled--interested-job">
                                <span className="rejected-text">
                                  <IoIosCloseCircleOutline />{" "}
                                  
                                  {
                                //   item?.rejectionDetails?.rejectedBy 
                                  "DIRECTOR"
                                  ===
                                  "DIRECTOR"
                                    ? "You have rejected hustlr's offer"
                                    : "Hustlr has rejected this job"}
                                </span>
                                <span
                                  className="view--reason"
                                  onClick={() => window.location.href = 'https://sidehustlsupport.zendesk.com/hc/en-us/requests/new'}
                                >
                                  Help Center<GoArrowRight />
                                </span>
                                <span
                                  className="view--reason"
                                //   onClick={() => fetchRejectedJob(item._id)}
                                >
                                  View Reason <GoArrowRight />
                                </span>
                              </div>
                            ) :
                            
                            (
                              <>
                                {
                                // item?.taskCompletionId

                                //   ?.hustlerMarkedCompleted == true 
                                  
                                  false? (
                                  <span className="mark-status">
                                    <span className="rejected-text">
                                      <BiTimeFive /> Hustlr has completed your
                                      job
                                    </span>
                                  </span>
                                ) : (
                                  <>
                                    {
                                    // !item?.stripePaymentStatus 
                                    true
                                    ? (
                                      <div className="working--process">
                                        <span>
                                          <BiTimeFive /> Hustlr has accepted the
                                          job!
                                        </span>
                                        <span
                                              className="payment--option"
                                            //   onClick={() => navigate(`/task/${item?.taskProposal?.task}`)}
                                              >
                                              View Job Details <GoArrowRight />
                                            </span>

                                      </div>
                                    ) : (
                                      <>
                                        {
                                        // item.taskCompletionId
                                        //   ?.reasonForNotCompleted 
                                          
                                          false? (
                                          <div className="cancelled--interested-job">
                                            <div>
                                              <BiTimeFive /> You rejected the
                                              completion of the specific job by
                                              the hustlr.
                                            </div>
                                            <div
                                              className="view--reason"
                                            //   onClick={() =>
                                            //     setRejectedTaskModal({
                                            //       model: true,
                                            //       reason:
                                            //         item.taskCompletionId
                                            //           ?.reasonForNotCompleted,
                                            //     })
                                            //   }
                                            >
                                              View Reason
                                              <GoArrowRight />
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="working--process">
                                            <span>
                                              <BiTimeFive /> Hustlr has accepted
                                              the job!
                                            </span>
                                            <span
                                              className="payment--option"
                                            //   onClick={() =>
                                            //     handlePaymentView(item)
                                            //   }
                                            >
                                              View payment  <GoArrowRight />
                                            </span>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )
                            }
                          </>
                        )}

                        <div className="task--work">
{/* ======================== step 1 ================================== */}
                          <div
                            className={`task--items--header waiting--task--top ${
                            //   item?.directorAsked ? "waiting--task--header" : ""
                            "waiting--task--header"
                            }`}
                            style={{ marginBottom: "0px" }}
                          >
                            <div className="task--add--info">
                              <div className="user--task--info">
                                <div className="task--info--img">
                                  <Link
                                    // to={`/user-info/${item?.hustlerId?._id}`}
                                  >
                                    <img
                                    //   src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${item?.hustlerId?.profilePic}`}
                                    />
                                  </Link>
                                </div>
                                <div className="task--info--details">
                                  <span>
                                    HUSTLR PROFILE
                                    {/* {item?.hustlerId?.fname}{" "}
                                    {item?.hustlerId?.lname} */}
                                  </span>                                  <p>
                                    <img src="../assets/images/hustler-star.png" />{" "}
                                    {handleDecimalNumber(
                                        4.3
                                    //   item?.hustlerId?.hustlerDetails?.ratings
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
{/* ========================================================================= */}

                            <div
                              className={`task--items--edit--delete waiting--task--item 
                                ${
                                // item?.directorAsked
                                
                                true? "waiting--task" : ""}

                               ${
                                // item?.cancel
                                false 
                                ? "cancelled-task" : ""}
                               
                               `
                            }
                              
                              style={{
                                flexDirection: "column",
                                alignItems: "flex-end",
                              }}
                            >
                              <div className="button--lists-view">
                                <button
                                //   onClick={() => handleSendMessage(item)}
                                  className="page-nine-element-one custom--btn ligghtbtn-hover transparent--btn minbutton"
                                  style={{ textAlign: "left" }}
                                >
                                  
                                  <span>
                                    {" "}
                                    <BsFillChatLeftTextFill /> Send Message
                                  </span>

                                  <br />
                                </button>

                                {
                                // item.status == "assigned"
                                true
                                && (
                                  <>
                                    {" "}
                                    {/* <button
  className="custom--btn ligghtbtn-hover transparent--btn minbutton"
  style={{ textAlign: "left" }}
>

                                      <span>
                                        {" "}
                                        <MdEmojiPeople /> Choose Another Hustlr
                                      </span>
                                      <br />
                                    </button> */}

                                    <button
                                    //   onClick={() =>publicJob(item._id,item.hustlerId._id)
                                    //   }
                                      className="page-ten-element custom--btn ligghtbtn-hover transparent--btn minbutton"
                                      style={{ textAlign: "left" }}
                                    >
                                      {/* <span>
                                        {" "}
                                        <MdPublic  /> Make Job Public
                                      </span> */}
                                      <span>
                                                  <IoCheckmarkCircle /> Mark as Completed 
                                                </span>

                                      <br />
                                    </button>

                                    <button
                                    //   onClick={() => deleteCreateTask(item)}
                                      className="custom--btn ligghtbtn-hover transparent--btn minbutton"
                                      style={{ textAlign: "left" }}
                                    >
                                                  <span>
                                                      <IoIosCloseCircle /> Deny
                                                      Completion
                                                    </span>

                                      {/* <span>
                                        <IoCloseCircleSharp /> Cancel Job 
                                      </span> */}
                                      <br />
                                    </button>
                                  </>
                                )}


                              </div>
                            </div>

                          </div>
{/* ========================================================================= */}


                          <div className="task--items--details">
                            <div
                              className="task--detail-full"
                              style={{ width: "100%" }}
                            >
                              <Link
                                // to={`/task/${item._id}`}
                                style={{
                                  color: "#212529",
                                  textDecoration: "none",
                                }}
                              >
                                <h2 className="hover-text">
                                    {/* {item?.title} */}
                                    I need my lawn mowing
                                    </h2>
                              </Link>
                              <div className="service--category--task">
                                <h3> 
                                  lawn mowing
                                    {/* {item?.serviceName} */}
                                    </h3>
                              </div>
                            </div>
                          </div>

                          <div className="task--items--content">
                            <p>
                              <span className="task--items--content--icon">
                                <AiOutlineCalendar />
                              </span>
                              Nov-24-24 
                              {/* { item?.endDate?
                             (
                              <> {setReportFormatDate(item?.dueDate)} to  {setReportFormatDate(item?.endDate)}</>)
                             :(setReportFormatDate(item?.dueDate))
} */}

                            </p>
                            <p>
                              <span className="task--items--content--icon">
                                <TbMoneybag />
                              </span>{" "}
                              Budget: $65.00


                              {/* {item?.budget==0?
                              <>Open to Offer</>
                              :
                              <>
                              Budget: ${BudgetFormat(`${item?.budget}`)}
                              </>
} */}
                            </p>
                            <p>
                              <span className="task--items--content--icon">
                                <GrLocation />
                              </span>{" "}
                              {/* Area: cityName */}
                              Area: Kelowna
                              {/* {item?.cityName} */}
                            </p>
                          </div>

                          <div className="task--items-footer">
                            My lawn is 100 meter square
                            {/* {item?.description
                              ? item?.description?.length > 400
                                ? `${item?.description?.substring(0, 400)}...`
                                : `${item?.description}`
                              : "No description available"} */}
                          </div>
                        </div>

                        {/* {item?.oneTimePayment ? null : (
                          <DirectorJobFrequently item={item} />
                        )} */}
                      </div>
      
    </div>
  )
}

export default DirectorTask
