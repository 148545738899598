import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineHeart, AiOutlineMessage } from "react-icons/ai";
import { FaRegPaperPlane } from "react-icons/fa";

const HustlersListsStart = () => {
  return (
    // <div className="hustlers-lists">
      <div className="hustler--thumbnail--items">
        <div className="custom--scroll hustler--thumbnail-scroll">
          <div className="thumbnail--hustler">
            <Link
              to={`/user-info/static-id`}
              className="thumbnail--hustler--profile"
            >
              <img
                src={`/assets/images/hustler-star.png`}
                alt="Static Profile"
              />
            </Link>
            <div
              className="thumbnail--hustler--info"
              style={{
                width: "60%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div className="hustler--i">
                <h2 style={{ marginBottom: "0px" }}>HUSTLR PROFILE</h2>
                <p
                  style={{
                    marginBottom: "0px",
                    fontSize: "12px",
                    color: "#949494",
                  }}
                >
                  British Columbia, Kamloops
                </p>
                <p className="search--category">
                  <span>Lawn mowing</span>
                </p>
              </div>

              <div style={{ display: "flex" }}>
                <div
                  className="add--favorite--item"
                  data-tooltip-content="Add Favorite"
                  data-tooltip-place="top"
                >
                  <AiOutlineHeart />
                </div>
                <div
                  style={{ marginLeft: "10px" }}
                  data-tooltip-content="Send Message"
                  className="add--favorite--item"
                >
                  <AiOutlineMessage />
                </div>
              </div>
            </div>
          </div>

          <div className="items-content-hustler">
            <div>
              <p className="hutler--taskdone">
                <img src="./assets/images/hustler--taskdone.png" alt="Task Done" />
                10 Completed Tasks
              </p>
              <p className="working--rating">
                <img src="../assets/images/hustler-star.png" alt="Rating" />
                4.5
              </p>
              <ul className="featured--work--list">
                <li>lawn mowing</li>
                {/* <li>Category 2</li> */}
              </ul>
              <p className="discription--hustler--profile">
                A brief description of the hustler.
              </p>
            </div>
          </div>
        </div>
        <div className="hustler-profile-view">
          <Link
            to={`/hustlr-booking/static-id`}
            style={{ textDecoration: "none" }}
          >
            <button className="page-fifteen-element custom--btn mt-50 darkbtn-hover add--favorite--item">
              <span>
                <FaRegPaperPlane /> View Profile
              </span>
            </button>
          </Link>
        </div>
      </div>
    // </div>
  );
};

export default HustlersListsStart;
