import React, { useEffect, useState } from "react";
import { GoPencil } from "react-icons/go";
import { Link } from "react-router-dom";
import IsLoadingHOC from "../../common/IsLoadingHOC";
import { authAxios } from "../../services/config";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { displayBudget, handleDecimalNumber } from "../../utils/helper";

const ViewOffer = (props) => {
  const { setLoading } = props;
  const param = useParams();

  const navigate = useNavigate();

  const { hustlerId, taskId } = param;

  const [userDetail, setuserDetail] = useState([]);

  const [data, setdata] = useState({
    title: "",
    description: "",
    budget: "",
    hustlerbudget: "",
  });

  const fetchHustler = async () => {
    setLoading(true);
    await authAxios()
      .get(`users/get-specific-user/${hustlerId}`)
      .then((response) => {
        setLoading(false);
        const resData = response.data;
        if (resData.status == 1) {
          setuserDetail(resData?.data);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };

  const fetchHustlerAppliedTaskDetail = async () => {
    setLoading(true);
    await authAxios()
      .get(`/taskproposal/get-single-proposal/${hustlerId}/${taskId}`)
      .then((response) => {
        setLoading(false);
        const resData = response.data;
        if (resData.status == 1) {
          setdata((prev) => ({
            ...prev,
            hustlerbudget: resData.data[0].offer,
          }));

          //    setTaskDetail(resData?.data[0]);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };

  const fetchTask = async () => {
    setLoading(true);
    await authAxios()
      .get(`task/get-specific-task/${taskId}`)
      .then((response) => {
        const resData = response.data;
        setLoading(false);
        if (resData.status == 1) {
          setdata((prev) => ({
            ...prev,
            budget: resData?.data?.budget,
            title: resData?.data?.title,
            description: resData?.data?.description,
          }));
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setdata((prev) => ({
      ...prev,

      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    const result = displayBudget(data.budget);

    const payLoad = {
      userId: hustlerId,
      taskId: taskId,
      contractTitle: data.title,
      contractDescription: data.description,
      budget: Number(result),
    };

    setLoading(true);
    await authAxios()
      .post(`/send-offer/create-offer`, payLoad)
      .then((response) => {
        setLoading(false);
        const resData = response.data;
        if (resData.status === 1) {
          toast.success(resData?.message);
          navigate("/director-thanks");
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const viewPropsalDetail = async () => {
    const payload = {
      task: taskId,
      user: hustlerId,
    };
    await authAxios()
      .post(`/send-offer/get-single-offer`, payload)
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {         
           setLoading(false);
            setdata((prev) => ({
              ...prev,
              budget: resData?.data?.budget,
              title: resData?.data?.contractTitle,
              description: resData?.data?.contractDescription,
            }));
            
          } else {
            toast.error(resData.message);
          }
        
      })
      .catch((error) => {
      });
  };

  useEffect(() => {
    fetchHustler();
    fetchTask();
    fetchHustlerAppliedTaskDetail();
    viewPropsalDetail()
  }, []);

  return (
    <>
      <section className="send--offer--task pt-117">
        <div className="container--offer custom--container m-auto">
          <div className="heading--page" style={{ paddingBottom: "10px" }}>
            <h1 className="text--dark playFairDisplay heading-h1 mb-2">
              View Offer
            </h1>
          </div>
          {/* end heading */}

          <div className="task--information">
            <form onSubmit={handleSubmit} className="task--information--inner">
              <h2 className="mb-2">Contract terms</h2>
              <p className="mb-2">
                <Link to="#" style={{ color: "#000", fontWeight: "500" }}>
                  SideHustl Payment Protection
                </Link>
                . Only pay for the work you authorize.
              </p>

              <div className="field--option field-option-with-icon">
                <label>Pay a fixed price for your project</label>
                <div className="relative">
                  <span className="dollar-icon">$</span>
                  <input
                    type="number"
                    name="budget"
                    placeholder="0"
                    onChange={handleChange}
                    value={displayBudget(data.budget)}
                    disabled
                    style={{
                      paddingLeft: "20px",
                    }}
                  />
                  <GoPencil />
                </div>
                <p
                  style={{
                    fontSize: "10px",
                    fontWeight: "500",
                    marginBottom: "0px",
                  }}
                >
                  
                </p>
              </div>

              <div className="work--description">
                <h2 className="text--dark playFairDisplay heading-h2 mb-2">
                  Work Description
                </h2>

                <h3 className="heading-h4 mt-2">Contract title</h3>
                <div className="contract-title">
                  <div className="field--option field-option-with-icon">
                    <div className="relative" style={{ maxWidth: "100%" }}>
                      <input
                        type="text"
                        name="title"
                        onChange={handleChange}
                        placeholder="Enter Title"
                        value={data.title}
                        disabled
                      />
                      <GoPencil />
                    </div>
                  </div>
                </div>

                <h3 className="heading-h4 mt-2">
                  Add a description of the work
                </h3>
                <div className="contract-description">
                  <div className="field--option field-option-with-icon">
                    <div className="relative" style={{ maxWidth: "100%" }}>
                      <textarea
                        type="text"
                        name="description"
                        placeholder="Enter description"
                        onChange={handleChange}
                        value={data.description}
                        disabled
                      />
                      <GoPencil />
                    </div>
                  </div>
                </div>
               
               {/*
                <div className="submit--offer">
                  <div className="terms-check">
                    <div className="checkbox--custom">
                      <input type="checkbox" name="terms" id="terms" disabled />
                      <span className="checkmark"></span>

                      <label htmlFor="terms">
                        Yes, I understand and agree to the{" "}
                        <Link to="#">SideHustl Terms of Service</Link>,
                        including the <Link to="#">User Agreement</Link> and{" "}
                        <Link to="#">Privacy Policy</Link>
                      </label>
                    </div>
                  </div>
                  <div className="buttons">
                    <button
                      type="button"
                      className="custom--btn ligghtbtn-hover transparent--btn mini-btn"
                    >
                      <span>Cancel</span>
                    </button>
                    <button
                      type="submit"
                      className="custom--btn darkbtn-hover mini-btn"
                    >
                      <span>Continue</span>
                    </button>
                  </div>
                </div>
                */}
              </div>
            </form>

            {/* end task--information */}

            <div className="hustler--information">
              <div className="sticky--profile">
                <div className="profile--infor">
                  <img
                    src={`${process.env.REACT_APP_BASEURL}/${userDetail?.profilePic}`}
                    className="profile-img"
                  />
                  <div className="detail-info">
                    <h3>
                      {userDetail?.fname} {userDetail?.lname}
                    </h3>
                    <p className="location--hustler">
                      {userDetail?.hustlerDetails?.province},{" "}
                      {userDetail?.hustlerDetails?.location}
                    </p>

                    <div>
                      <p className="search--category mb-1">
                        <img src="/assets/images/hustler--taskdone.png" />{" "}
                        {userDetail?.hustlerDetails?.completedTask} Completed
                        Jobs
                      </p>

                      <p class="starting--price mb-1">
                        <img src="/assets/images/hustler-star.png" />{" "}
                        {handleDecimalNumber(
                          userDetail?.hustlerDetails?.ratings
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="profile--category--work">
                  <ul>
                    {userDetail.hustlerDetails?.category.map((item, index) => (
                      <>
                        <li key={index}>{item?.value}</li>
                      </>
                    ))}
                  </ul>
                </div>

                <div class="profile--description pb-2">
                  <p>{userDetail?.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IsLoadingHOC(ViewOffer);
