// src/components/Tour.js
import React ,{useEffect} from 'react';
import Joyride from 'react-joyride';
import { useTour } from '../contexts/TourContext';
import { useNavigate } from 'react-router-dom'; // Make sure to import useNavigate
import { useDispatch, useSelector } from "react-redux";


const Tour = () => {
  const navigate = useNavigate(); // Initialize the navigate function
  const userType = useSelector((state) => state.auth?.user?.userType);

  const { currentStepIndex, isTourRunning, steps, prevStep, nextStep, resetTour,setYesPopup,HustlerYesPopup, setHustlerYesPopup } = useTour();


  useEffect(() => {
    // Set an interval only if both conditions are true
    if (currentStepIndex === 0 && isTourRunning) {
      const interval = setInterval(() => {
        console.log(currentStepIndex, "testing beacon icon");
        const beaconButton = document.querySelector('[data-test-id="button-beacon"]');
        if (beaconButton) {
          beaconButton.click(); // Auto-click the beacon button
        }
      }, 1000); // Check every second

      // Cleanup the interval on component unmount or dependency change
      return () => clearInterval(interval);
    }
  }, [currentStepIndex, isTourRunning]); // Dependencies to trigger the effect when values change



  return (
    <>
 

    <style>
    {`

      .react-joyride__tooltip-close {
        display: none !important; /* Hides the close button */
      }
    `}
  </style>

    <Joyride
    autoStart
      steps={steps}
      stepIndex={currentStepIndex}

      run={isTourRunning}
      continuous
      showProgress
      showSkipButton
      // disableScrolling={currentStepIndex==4?false:true}
      disableScrolling={
        userType === "HUSTLER" 
          ? true 
          : currentStepIndex === 4 
          ? false 
          : true
      }
    

      locale={{
        // back: "Previous",
        // close: "Close",
        last: "GET STARTED", // Default name for the last button (optional)
        // next: "Next",
        // skip: "Skip",
      }}
      // showSkipButton={true} // Removes the "Skip" button
      // disableBeacon={true} // Disables the beacon button
      disableBeacon
      disableOverlayClose
      callback={(data) => {
      const { action, index, type } = data;
        if(userType=="HUSTLER"){
        if (type === 'step:after' && action === 'next') {
          // alert(currentStepIndex,"hii11")
          if(currentStepIndex==3){
          setHustlerYesPopup(currentStepIndex+1)
          setTimeout(() => {
            nextStep();
          },0);
        }
        else{
          setHustlerYesPopup(currentStepIndex+1)
          nextStep();

        }
      }

      }
      else{
        if (type === 'step:after' && action === 'next') {
          // if(currentStepIndex==3){
          //   // setTimeout(() => {
          //   //   window.scrollBy({ top: 400, behavior: 'smooth' }); // Scroll down 200px

          //   // }, 100);
          //   const targetElement = document.getElementById('View-Proposal'); // Replace with your id
          //   if (targetElement) {
          //     targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          //   }
            

          // }
          const stepsToNavigate = [1, 2, 3, 4, 5, 6, 7,11, 12, 13,16,17,18, 20, 21, 22];  // List of steps where navigation happens
          const nextStepWithDelay = (step) => {
            setYesPopup(step + 1);
            // setHustlerYesPopup(step + 1)
            setTimeout(() => {
              nextStep();
            }, 0);
             // Delay can be adjusted if needed
          };
        
          if (stepsToNavigate.includes(currentStepIndex)) {
            nextStepWithDelay(currentStepIndex);
            
            // Navigate to specific pages for certain steps
            if ([20, 21, 22].includes(currentStepIndex)) {
              navigate('/hustlr-booking/673ed12978e7a51eedd3de87');
            }
          } else {
            setYesPopup(currentStepIndex + 1);
            // setHustlerYesPopup(currentStepIndex + 1)

            nextStep();
          }
        }

      }
        
      if(userType=="HUSTLER"){
    // Handling the 'back' action
    if (type === 'step:after' && action === 'prev') {
      if (currentStepIndex === 2) {
        const targetPath = '/home';
        const targetState = { id: 'saved-jobs' };
        navigate(targetPath, { state: targetState });
        setHustlerYesPopup(currentStepIndex - 1)
        setTimeout(() => {
          prevStep(); // Use prevStep to go back
        },1000);
  
      }
      else{
        setHustlerYesPopup(currentStepIndex - 1)
        setTimeout(() => {
          prevStep(); // Use prevStep to go back
        },0);
      }


    }
      }
      else{
    if (type === 'step:after' && action === 'prev') {
      const stepsToNavigateBack = [2, 3, 4, 5, 6, 7,8,9,10, 13,14,15,16, 18,19, 20,21,22,23]; // List of steps where navigation happens
      const prevStepWithDelay = (step) => {
        if(step==1){
          
          navigate('/create-task'); 
        }
        if(step==4){
          navigate('/your-task-list'); 
        }

        if(step==8){
          navigate('/intersted-hustlr-profile/66de9a6e8616ac16cea959e4/673b2a8e45b87448bf32d923'); 
        }

        if(step==10){

          navigate('/intersted-hustlr-profile/66de9a6e8616ac16cea959e4/673b2a8e45b87448bf32d923'); 
        }
        if(step==13){
          navigate("/your-task-list", { state: { id: "completed-task" } });

        }
        if(step==15){
          navigate("/hustlrs-lists");
        }
        if(step==18){
          navigate("/hustlr-booking/673ed12978e7a51eedd3de87");
        }


        setYesPopup(step - 1);
        setTimeout(() => {
          prevStep();
        }, 0); 
      };

      if (stepsToNavigateBack.includes(currentStepIndex)) {
        prevStepWithDelay(currentStepIndex);
        
      } else {
        setYesPopup(currentStepIndex - 1);
        prevStep();
      }
    }
  }

    
  if (action === 'close') {
    // handleClose();
    resetTour()
  }

        if (type === 'tour:end') {
  
          resetTour();

        }
      }}




      styles={{ options: { zIndex: 9910000 },
      
        // buttonClose: {
        //   display: 'none',
        // },
      
      buttonNext: {
        backgroundColor: 'black', // Set Next button background to black
        color: 'white',           // Set Next button text to white
      },
      beacon: {
        display: 'none'
        // inner: 'black',   // Inner circle color (black)
        // outer: 'black',   // Outer circle color (black)
      },
      
      buttonBack: {
        // backgroundColor: 'black', // Set Back button background to black
        color: 'black',           // Set Back button text to white
      },

    }}
    />
    </>
  );
};

export default Tour;


