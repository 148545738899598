import React from 'react'
import { Tooltip } from "react-tooltip";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { GrLocation } from "react-icons/gr";
import { TbMoneybag } from "react-icons/tb";


function HustlerBookingStart() {
  return (
    <div key="1" className="created--task--lists">
      <div className="task--items--header" style={{ alignItems: "flex-start" }}>
        <div className="task--items--header--details hustler--book--direct max-w-991">
          <h2>Static Task Title</h2>
          <div className="service--category--task">
            <h3>Static Service Name</h3>
          </div>
        </div>
        <div className="task--items--edit--delete" style={{ width: "auto" }}>
          <div className="edit--taks--items">
            <Tooltip
              id="view--task-d"
              className="tooltip--task--wishlist"
            />
            <Link
              className="delete--taks--items"
              data-tooltip-id="view--task-d"
              data-tooltip-content="View Job Details"
              to={`/task/static-id`}
              state={{ id: "view-task-hire" }}
            >
              <MdOutlineRemoveRedEye color="white" />
            </Link>
          </div>
        </div>
      </div>

      <div className="task--items--content">
        <p>
          <span className="task--items--content--icon">
            <TbMoneybag />
          </span>{" "}
          Budget: $500
        </p>
        <p>
          <span className="task--items--content--icon">
            <GrLocation />
          </span>{" "}
          Area: Static City Name
        </p>
      </div>

      <div className="task--items-footer">
        This is a static description of the task, providing details about the job.
      </div>

      <div className="page-sixteen-element flex">
        <button
          className="page-seventeen-element custom--btn mt-3 darkbtn-hover mini-btn ml-auto"
          style={{ maxWidth: "150px" }}
          onClick={() => console.log("Hire Now clicked")}
        >
          <span>Hire Now</span>
        </button>
      </div>

      {/* <div className="task--timing">
        <p>Please note that this is a <b>monthly</b> job, and payment will be made on a <b>monthly</b> basis upon completion period.</p>
      </div> */}
    </div>
  )
}

export default HustlerBookingStart
