import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { logout } from "../Redux/Reducers/authSlice";
import { toast } from "react-toastify";


function Header() {
  const location = useLocation();
  const path = location.pathname.split('/').pop();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false);
  const user = useSelector((state) => state.auth?.user);


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <header className={`main--header ${scrolled ? "scrolled" : ""}`} data-aos="fade-in" data-aos-duration="1500">
        <div className="max-w-1920 flex items-center justify-between sm-flex-col">
          <div className="main--nav">
            <nav className="navbar navbar-expand-lg navbar-light">
              <a className="navbar-brand" onClick={() => navigate('/')}>
                <img src="./assets/images/logo.png" alt="logo" />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <Link className="nav-link text-white" to="/">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link text-white" to="/about-us">
                      About
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <a className="nav-link text-white" href="/services">
                      Services
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link text-white" href="/contact">
                      Location
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link text-white" href="/contact">
                      Career
                    </a>
                  </li> */}
                  <li className="hide-menu">

                    <div className="sign--btn flex items-center">
                   { path === 'become-a-creatr' ?
                      <Link to="/login" state={{ id: "director" }} className="btn transparent-btn" 
                      style={{
                        padding: "10px 40px"
                      }}                      >
                        Sign In 
                      </Link>
                      :
                      <Link to="/login" state={{ id: "hustler" }} className="btn transparent-btn" 
                      style={{
                        padding: "10px 40px"
                      }}                      >
                        Sign In 
                      </Link>

                      }

                      {path === 'become-a-creatr' ? (
                        <Link to="/signup" state={{ id: "director" }} className="btn btn-light ligghtbtn-hover">
                          <span>Join Now </span>
                        </Link>
                      ) : null}

                      {path === 'become-a-hustlr' ? (
                        <Link to="/signup" state={{ id: "hustler" }} className="btn btn-light ligghtbtn-hover">
                          <span>Join Now</span>
                        </Link>
                      ) : null}
                    </div>

                  </li>
                </ul>
              </div>
            </nav>
          </div>


          <div className="sign--btn flex items-center hide--mb">
            {/* <p className="text-white mb-0">Become a Hustler</p> */}
            {path === 'become-a-creatr' ?
            <Link to="/login" state={{ id: "director" }} className="btn transparent-btn btn--black--hover">
              <span>Sign In  </span>
            </Link>:
                        <Link to="/login" state={{ id: "hustler" }} className="btn transparent-btn btn--black--hover">
                        <span>Sign In  </span>
                      </Link>
}
          

            {path === 'become-a-creatr' ? (
              <Link to="/signup" state={{ id: "director" }} className="btn btn-light ligghtbtn-hover ">
                <span>Join Now</span>
              </Link>
            ) : null}

            {path === 'become-a-hustlr' ? (
              <Link to="/signup" state={{ id: "hustler" }} className="btn btn-light ligghtbtn-hover">
                <span>Join Now</span>
              </Link>
            ) : null}

            {path === 'become-a-creatr' || path === 'become-a-hustlr' ? null : (
              <Link to="/signup" state={{ id: "hustler" }} className="btn btn-light ligghtbtn-hover">
                <span>Join Now</span>
              </Link>
            )}
          </div>

        </div>
      </header>
    </>
  );
}

export default Header;
