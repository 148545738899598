import React, { useState } from 'react';



const MailchimpSignupForm = ({openNotification,errorNotification,errorOneNotification}) => {
  const [email, setEmail] = useState('');
  const [group1, setGroup1] = useState(false);
  const [group2, setGroup2] = useState(false);

  

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('EMAIL', email);
    if (group1) formData.append('group[35460][1]', 'true');
    if (group2) formData.append('group[35460][2]', 'true');


    // if (!group1 && !group2) {
    //   errorNotification()
    //   return; // Prevent form submission if neither group is selected
    // }
    // if (group1 && group2){
    //   errorOneNotification()
    //   return; // Prevent form submission if neither group is selected

    // }
  

    fetch('https://sidehustl.us11.list-manage.com/subscribe/post?u=a446787384fb0789d8393c9ec&id=57bfaab8f4&f_id=004fd0e3f0', {
      method: 'POST',
      body: formData,
      mode: 'no-cors', // This allows cross-origin POST without handling the response.
    }).then(() => {
      openNotification()
      
    }).catch(err => console.error('Error:', err));
  };

  
  

  return (<>

    <div id="mc_embed_shell">
      <div id="mc_embed_signup">
        <form
          onSubmit={handleSubmit}
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
        >
          <div id="mc_embed_signup_scroll">
            <h5>Sign up now and be the first to SideHustl at launch!</h5>
            
            <div className="mc-field-group">
              <label htmlFor="mce-EMAIL">
                Email Address
              </label>
              <input
                type="email"
                name="EMAIL"
                className="required email"
                id="mce-EMAIL"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ paddingLeft: '10px' }} // Add 10px left padding

              />
              
            <p style={{marginBottom: '0px'}}>I'm a: </p>
            </div>
            <div className="mc-field-group input-group">
              <ul>
                <li>
                  <input
                    type="checkbox"
                    name="group[35460][1]"
                    id="mce-group[35460]-35460-0"
                    value={group1}
                    onChange={() => setGroup1(!group1)}
                  />
                  <label htmlFor="mce-group[35460]-35460-0">
                     Creatr seeking job completion
                  </label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    name="group[35460][2]"
                    id="mce-group[35460]-35460-1"
                    value={group2}
                    onChange={() => setGroup2(!group2)}
                  />
                  <label htmlFor="mce-group[35460]-35460-1">
                  Hustlr ready to take on jobs
                  </label>
                </li>
              </ul>
            </div>
            <div id="mce-responses" className="clear">
              <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
              <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
            </div>
            <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
              <input type="text" name="b_a446787384fb0789d8393c9ec_57bfaab8f4" tabIndex="-1" value="" />
            </div>
            <div className="clear">
              <input type="submit" name="subscribe" id="mc-embedded-subscribe" className="button" value="Subscribe" />
            </div>
          </div>
        </form>
      </div>
    </div>
    </> );
};

export default MailchimpSignupForm;
