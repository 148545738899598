import React, { useState } from "react";
import IsLoadingHOC from "../../common/IsLoadingHOC";
import PasswordInputField from "../../component/PasswordInputField";
import { Link, useNavigate } from "react-router-dom";
import { authAxios } from "../../services/config";
import { toast } from "react-toastify";
import { PiEyeLight, PiEyeSlash } from "react-icons/pi";
import { FaCheck } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import usePasswordValidation from "../../common/usePasswordValidation";
function ForgotPassword(props) {
  const { setLoading } = props;
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showModel, setshowModel] = useState({
    email: true,
    code: false,
    password: false,
  });

  const [formData, setformData] = useState({
    email: "",
    otp: "",
    password: "",
    confirmPassword: "",
  });

  const handleReSendOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      email: formData.email,
    };
    await authAxios()
      .post(`users/resend-otp`, payload)
      .then((response) => {
        const resData = response?.data;
        setLoading(false);
        if (resData.status == 1) {
          toast.success(resData?.message);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      email: formData.email,
    };
    await authAxios()
      .post(`/users/forgot-password-send-otp`, payload)
      .then((response) => {
        const resData = response.data;
        setLoading(false);
        if (resData.status == 1) {
          toast.success(resData.message);
          setshowModel({
            email: false,
            code: true,
            password: false,
          });
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };

  
  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      email: formData.email,
      enteredOTP: Number(formData.otp),
    };
    await authAxios()
      .post(`users/verify-otp`, payload)
      .then((response) => {
        const resData = response?.data;
        setLoading(false);
        if (resData.status == 1) {
          toast.success(resData?.message);
          setshowModel({
            email: false,
            code: false,
            password: true,
          });
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };
  
  const handleSetPassword = async (e) => {
    e.preventDefault();
    
    if (formData.password.length < 8 ||
      !/(?=.*[A-Z])/.test(formData.password) ||
      !/(?=.*[!@#$%^&*])/.test(formData.password) ||
      !/(?=.*[0-9])/.test(formData.password)) {
      toast.error("Password criteria do not match.");
      return;
    }

    if (formData.password == formData.confirmPassword) {
      const payload = {
        email: formData.email,
        password: formData.password,
        confirmPassword: formData.confirmPassword,
      };

      await authAxios()
        .post(`users/reset-password`, payload)
        .then((response) => {
          const resData = response?.data;
          setLoading(false);
          if (resData.status == 1) {
            toast.success(resData?.message);

            navigate(`/login`)
          } else {
            toast.error(resData?.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message);
        });

    } else {
      toast.error("The passwords entered do not match.");
    }
  };

  const handleTogglePasswordVisibility = (field) => {
    if (field === 'password') {
      setShowPassword((prevShowPassword) => !prevShowPassword);
    } else if (field === 'confirmPassword') {
      setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
    }
  };

  const {
    hasValidLength,
    hasNumber,
    hasUpperCase,
    hasSpecialCharacter
  } = usePasswordValidation(formData.password);

  return (
    <>
      <div className="forgot--password--container">
        <div className="forgot-form">
          <Link to="/" className="text-center">
            <img
              src="./assets/images/logo.png"
              class="mb-3 w-full logo--form"
              alt="logo"
            />
          </Link>
          <div className={`form--main--container`} id="container">
            <div className="form-container">
              {showModel.email && (
                <form onSubmit={handleSendOtp}>
                  <h2>Forgot Password</h2>
                  <div className="form--group--login">
                    <label htmlFor="Email">Email</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter your email"
                      required
                    />
                  </div>
                  <button type="submit" className="custom--btn darkbtn-hover">
                    <span>Get code</span>
                  </button>
                </form>
              )}
              {showModel.code && (
                <>
                <form onSubmit={handleVerifyOtp}>
                  <div className="form--group--login remove--down--number">
                    <label htmlFor="Password">Enter Code</label>
                    <input
                      type="number"
                      name="otp"
                      placeholder="Enter One Time Password"
                      value={formData.otp}
                      onChange={handleChange}
                      required
                    />
                    <button type="submit" className="custom--btn darkbtn-hover">
                      <span>Submit</span>
                    </button>
                    <p onClick={handleReSendOtp} className="resend--password">Resend Code</p>
                  </div>
                </form>
                <p style={{fontSize:'14px',marginBottom:'0px'}}>Please verify your <b>email/SMS</b> one  time password.</p>
                </>
              )}

              {showModel.password && (
                <form onSubmit={handleSetPassword}>
                  <div className="form--group--login">
                    <div className="input-password relative">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        placeholder="Enter Password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                      />
                      <div className="passwod--visibitly" onClick={() => handleTogglePasswordVisibility('password')}>
                        {showPassword ? <PiEyeLight /> : <PiEyeSlash />}
                      </div>
                    </div>
                  </div>
                  <div className="form--group--login">
                    <div className="input-password relative">
                      <input
                        type={showConfirmPassword ? 'text' : 'password'}
                        name="confirmPassword"
                        placeholder="Enter Confirm Password"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        required
                      />
                      <div className="passwod--visibitly" onClick={() => handleTogglePasswordVisibility('confirmPassword')}>
                        {showConfirmPassword ? <PiEyeLight /> : <PiEyeSlash />}
                      </div>
                    </div>
                  </div>
                  <button type="submit" className="custom--btn darkbtn-hover">
                    <span>Submit</span>
                  </button>
                  {/* <p className='text-center dispri'>Don't have an account? <Link to="/signup" className='text-white'><b>Register here</b></Link></p> */}

                  <ul className="password--rules">
                    <li className={`${hasValidLength && 'active'}`} >
                      {hasValidLength && hasValidLength === true ? <FaCheck className="check" /> : <IoClose className="close" />} Be a miminum of 8 characters
                    </li>
                    <li className={`${hasNumber && 'active'}`}>
                      {hasNumber && hasNumber === true ? <FaCheck className="check" /> : <IoClose className="close" />} Include at least one number (0-9)
                    </li>
                    <li className={`${hasUpperCase && 'active'}`}>
                      {hasUpperCase && hasUpperCase === true ? <FaCheck className="check" /> : <IoClose className="close" />} Include at least one uppercase letter (A-Z)
                    </li>
                    <li className={`${hasSpecialCharacter && 'active'}`}>
                      {hasSpecialCharacter && hasSpecialCharacter === true ? <FaCheck className="check" /> : <IoClose className="close" />} Include at least one special characters (!@#$%^&*)
                    </li>
                  </ul>
                  
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default IsLoadingHOC(ForgotPassword);
