import React, { useState, useEffect } from "react";
// import DatePicker from "react-datepicker";
import DirectorHeader from "./DirectorHeader";
import { useDispatch, useSelector } from "react-redux";
import { State, City } from "country-state-city";
import CreatableSelect from "react-select/creatable";

import Select from "react-select";
import { CanadianCity } from "../../utils/constants";
import { authAxios, withoutAuthAxios } from "../../services/config";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../common/IsLoadingHOC";
import { useNavigate } from "react-router-dom";
import { setReportFormatDate } from "../../assets/Helper";
import { displayBudget, payloadBudget, toCamelCase } from "../../utils/helper";
import { IoCheckmarkCircle, IoCloseOutline } from "react-icons/io5";
import { GoCheck } from "react-icons/go";
import { Tooltip } from "react-tooltip";
import { DatePicker, Space, Typography } from 'antd';
import moment from 'moment';
import { useTour } from '../../contexts/TourContext';


const { RangePicker } = DatePicker;
const { Text } = Typography;
const DirectorCreateTask = (props) => {
    const { currentStepIndex, isTourRunning, steps, nextStep, resetTour ,YesPopup} = useTour();

  const { setLoading } = props;

  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [EndDate, setEndDate] = useState(new Date());


  const taskStep = [
    "Step 1",
    "Step 2",
    "Step 3",
    "Step 4",
    "Step 5",
    "Step 6",
    "Step 7",
  ];
  const [selectedService, setSelectedService] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [provinceList, setprovinceList] = useState([]);
  const [cityList, setcityList] = useState([]);
  const [selectedProvince, setselectedProvince] = useState(null);
  const [stateCode, setStateCode] = useState("");
  const [createTaskData, setcreateTaskData] = useState("");
  const [option, setOption] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");

  const taskServices = useSelector((state) => state.app?.services);

  const [filteredData, setFilteredData] = useState([]);
  const MAX_UPLOAD_LIMIT = 10;
  const [uploadLimitExceeded, setUploadLimitExceeded] = useState(false);

  const [stepForm, setStepForm] = useState({
    step: 1,
    name: "",
    email: "",
    title: "",
    password: "",
    jobType: "",
    pincode: "",
    budget: "",
    dueDate: "",
    description: "",
    oneTimePayment: "",
    subscriptionInterval: "",
    job_image: [],
  });

  const { step } = stepForm;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (stepForm.step === 6) {
      const formData = new FormData();
      stepForm.job_image.forEach((file) => {
        formData.append("job_image", file);
      });
      formData.append("title", stepForm.title.trim());
      formData.append("serviceName", toCamelCase(selectedService?.value));
      formData.append("cityName", selectedCity?.value);
      formData.append("budget", payloadBudget(`${stepForm.budget}`))
      formData.append("province", selectedProvince.label);
      formData.append("dueDate", startDate);
      formData.append("endDate", EndDate);
      formData.append("description", stepForm.description.trim());
      formData.append("status", "open");
      formData.append("oneTimePayment", stepForm.oneTimePayment);
  
      if (!stepForm.subscriptionInterval == "") {
        formData.append("subscriptionInterval", stepForm.subscriptionInterval)
      }
      setLoading(true);
      await authAxios()
        .post("/task/create-tasks", formData)
        .then((response) => {
          setLoading(false);
          const resData = response.data;

          if (resData.status === 1) {
            navigate("/your-task-list");
            toast.success(resData.message);
          } else {
            toast.error(resData.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.response.data.message);
        });
        
    } else {
      // Move to the next step
      setStepForm((prevState) => ({
        ...prevState,
        step: prevState.step + 1,
      }));
    }

  };

  const handlePrevious = () => {
    if (stepForm.step > 1) {
      setStepForm((prevState) => ({
        ...prevState,
        step: prevState.step - 1,
      }));
    }
  };

  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    const specialCharRegex = /[^a-zA-Z0-9\s]/g; // Allows only letters, numbers, and spaces
    const allowedCharsRegex = /^[a-zA-Z0-9\s'"\-–—\[\]\(\)…,;:!?\.]*$/;

    if (!allowedCharsRegex.test(value)) {
      setError("Only certain special characters are allowed.");
    } 
    
    else {
      setError(""); // Clear error if input is valid
      setStepForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleBudgetChange = (e) => {
    const { name, value } = e.target;
    let numericValue = value.replace(/,/g, "");
    numericValue = numericValue.replace(/[^0-9]/g, "");

    if (numericValue !== "") {
      numericValue = "$" + parseInt(numericValue, 10).toLocaleString("en-US");
    }

    setStepForm((prevStepForm) => ({
      ...prevStepForm,
      [name]: numericValue,
    }));
  };

  const handleSelectTask = (option) => {
    setSelectedService(option);
    if (!option) {
      setFilteredData([]);
    }
  };

  const handleInputChange=(value,e)=>{
    
    if (e.action == "menu-close") {
      if (e.prevInputValue.length > 0) {
        const newOption = { label: e.prevInputValue, value: e.prevInputValue };
        setSelectedService(newOption);
      }
    }
    
  }
 

  

  const handleSelectProvince = (e) => {
    if (e) {
      setStateCode(e?.value);
      setselectedProvince(e);
      setSelectedCity([]);
    } else {
      setcityList([]);
      setSelectedCity([]);
      setStateCode("");
      setselectedProvince([]);
    }
  };

  const handleSelectCity = (option) => {
    setSelectedCity(option);
    if (!option) {
      setFilteredData([]);
    }
  };

  const handleMediaUpload = (e) => {
    const files = e.target.files;
    if (stepForm.job_image.length + files.length > MAX_UPLOAD_LIMIT) {
      setUploadLimitExceeded(true);
      return;
    }

    setStepForm((prevStepForm) => ({
      ...prevStepForm,
      job_image: [...prevStepForm.job_image, ...Array.from(files)],
    }));
  };

  const removeImage = (index) => {
    const updatedImages = [...stepForm.job_image];
    updatedImages.splice(index, 1);
    setStepForm((prevStepForm) => ({
      ...prevStepForm,
      job_image: updatedImages,
    }));
  };

  const handleOptionChange = (event) => {
    // console.log(event.target.id,"event.target.id")

    if (true) {
      setStepForm((prev) => ({
        ...prev,
        oneTimePayment: true,
        subscriptionInterval: "",
      }));
    } else {
      setStepForm((prev) => ({
        ...prev,
        oneTimePayment: event.target.id,
      }));
    }
  };

  const handleSubscriptionChange = (option) => {
    setStepForm((prev) => ({
      ...prev,
      subscriptionInterval: `${option}`,
      oneTimePayment: "false",
    }));
  };
  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (stepForm.subscriptionInterval === 'month') {
      const nextMonthDate = new Date(date);
      nextMonthDate.setMonth(nextMonthDate.getMonth() + 1); // Move to the next month

  } else {
  }
  };


  useEffect(() => {
    setprovinceList(
      [ { value: "BC", label: "British Columbia" },
        {value: "SK",label: "Saskatchewan"}
     ]
      );
  }, []);

  useEffect(() => {
    setcityList([])
if(stateCode=="BC"){
    setcityList(
      [ {"value": "Kamloops", "label": "Kamloops"},
        {"value": "Kelowna","label": "Kelowna"},
        {"value": "Peachland","label": "Peachland"},
        {"value": "Penticton","label": "Penticton"},
        {"value": "Vernon","label": "Vernon"},
        {"value": "West Kelowna","label": "West Kelowna"},
      ]
    )
  }
  else{

    setcityList(
      [ {value: "Saskatoon",label: "Saskatoon"}
      ]
    )


  }


    if (stateCode) {
    }
  }, [stateCode]);



  const getMinDate = () => {
    if (!startDate) return new Date(); // Default to today if no start date
    const minDate = new Date(startDate);
    minDate.setDate(minDate.getDate() + 7); // Add 7 days for weekly
    minDate.setHours(0, 0, 0, 0); // Set time to 00:00:00
    return minDate;
  };

  const jobList = [
    { "label": "Appliance Help", "value": "Appliance Help" },
    { "label": "Assembly & Installation", "value": "Assembly & Installation" },
    { "label": "Cleaning Services", "value": "Cleaning Services" },
    { "label": "Curtain & Blind Setup", "value": "Curtain & Blind Setup" },
    { "label": "Deck & Outdoor Assistance", "value": "Deck & Outdoor Assistance" },
    { "label": "Electrical Help", "value": "Electrical Help" },
    { "label": "Errands and Personal Assistance", "value": "Errands and Personal Assistance" },
    { "label": "Furniture Moving & Packing", "value": "Furniture Moving & Packing" },
    { "label": "Garage Organization", "value": "Garage Organization" },
    { "label": "Gutter Cleaning", "value": "Gutter Cleaning" },
    { "label": "Hanging & Mounting", "value": "Hanging & Mounting" },
    { "label": "Home Organization", "value": "Home Organization" },
    { "label": "Junk Removal", "value": "Junk Removal" },
    { "label": "Minor Repairs", "value": "Minor Repairs" },
    { "label": "Painting", "value": "Painting" },
    { "label": "Plumbing", "value": "Plumbing" },
    { "label": "Power Washing", "value": "Power Washing" },
    { "label": "Quick Fixes", "value": "Quick Fixes" },
    { "label": "Seasonal Jobs", "value": "Seasonal Jobs" },
    { "label": "Snow Removal", "value": "Snow Removal" },
    { "label": "Window Cleaning", "value": "Window Cleaning" },
    { "label": "Yard Work", "value": "Yard Work" }
];

useEffect(()=>{
  handleOptionChange()
},[])


const [dates, setDates] = useState([]); // Initialize dates state
const handleChange1 = (value) => {
  if (value) {
    setStartDate(value[0].$d); // Set start date if available
    setEndDate(value[1].$d);   // Set end date if available
    setDates(value);            // Set the entire date range
  } else {
    setStartDate(null);         // Reset start date if cleared
    setEndDate(null);           // Reset end date if cleared
    setDates(null);             // Reset the date range if cleared
  }
};




const [isOpenToOffers, setIsOpenToOffers] = useState(false);
const [budget, setBudget] = useState("");

const handleToggleClick = () => {
  setIsOpenToOffers((prev) => !prev);
};


const displayBudget = (value) => {
  return value || "";
};


const todayDate = new Date()
useEffect(() => {
  if (isOpenToOffers) {
    setStepForm((prevState) => ({
      ...prevState,
      budget: 0, // Reset the budget value to 0
    }));
  }
}, [isOpenToOffers]);

  const handleNext = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling effect
    });
  };

//   const { currentStepIndex, isTourRunning, steps, nextStep, resetTour ,YesPopup} = useTour();
useEffect(() => {
  console.log(YesPopup, "YesPopup");
  console.log(currentStepIndex, "currentStepIndex");

  // Ensure YesPopup is within range
  if (YesPopup > 0 && YesPopup < 7) {
    setStepForm((prevState) => ({
      ...prevState,
      step: YesPopup,
    }));
  } else {
    setStepForm((prevState) => ({
      ...prevState,
      step: 1,
    }));
  }

  // Smooth scrolling to the top
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}, [YesPopup]);

const startDateCurrent = moment(new Date()).subtract(7, 'days'); // 7 days ago
const endDateCurrent = moment(new Date()); // Current date

  return (
    <>
      <section className="create--task--form">
        <div className="custom--container m-auto task--container">
          <div className="task--progress-bar">

            {taskStep.map((s, index) => (
              <div
                key={index}
                className={`step ${step === index + 1 ? "active" : ""} ${step > index + 1 ? "completed" : ""
                  }`}
              >
                {s}
              </div>
            ))}
          </div>
          <style>
  {`
    @media (max-width: 768px) { /* Mobile view */
      #remove-br {
        display: none;
      }
      .hide-br {
            display: none;
          }

    }

  `}
</style>


          <form onSubmit={handleSubmit} className="step--form--task">
            {step === 1 && (
              <div className="step--container">
                <div className="task--heading--label"
                >

                  <label>1/6</label>
                  <h2 
                  data-tooltip-id={`jobStep1`}
                  className="playFairDisplay heading-h1 text-dark" style={{ width: '90%' }}>
                    Enter Desired Date
                  </h2>
                  <p style={{marginLeft:"3px"}}> Please enter the date or date range for 
                    when you'd like <br id="remove-br" />
                    your job completed.</p>
                </div>


                <div className="task--heading--input">
                  <div className="buget--members">

                    <div className="job--type">


                      {stepForm.oneTimePayment === "false" ? (
                        <>
                          <label className="mt-3 mb-2">
                            How frequently would you like this job to repeat?{" "}
                            <span className="mendotry-field">*</span>
                          </label>
                          <div className="what--subscription">
                            <label
                              className={`ratio--box custom--btn minbutton ${stepForm.subscriptionInterval === "week"
                                ? "darkbtn-hover"
                                : "ligghtbtn-hover transparent--btn"
                                }`}
                              onClick={() => handleSubscriptionChange("week")}
                            >
                              <input

                                type="radio"
                                required
                                name="subcriptionoption"
                                id="week"
                                checked={stepForm.subscriptionInterval == "week"}
                              />

                              <span>
                                {stepForm.subscriptionInterval == "week" && (
                                  <IoCheckmarkCircle />
                                )}{" "}
                                Weekly
                              </span>
                            </label>

                            <label
                              className={`ratio--box custom--btn minbutton ${stepForm.subscriptionInterval === "month"
                                ? "darkbtn-hover"
                                : "ligghtbtn-hover transparent--btn"
                                }`}
                              onClick={() =>
                                handleSubscriptionChange("month")
                              }
                            >
                              <input
                                type="radio"
                                required
                                name="subcriptionoption"
                                id="month"
                                checked={stepForm.subscriptionInterval ===
                                  "month"}
                              />
                              <span>
                                {stepForm.subscriptionInterval ===
                                  "month" && <IoCheckmarkCircle />}{" "}
                                Monthly
                              </span>
                            </label>

                          </div>
                        </>
                      ) : null}

                      <div className="task--heading--input" style={{marginTop:"12px"}}>
                        <label className="mt-3 mb-2">
                          What dates would you like this job to be completed between?{" "}
                          <span className="mendotry-field">*</span>
                        </label>
                        
                        <br />

  <Space direction="vertical" size={12} style={{ width: '100%' }} className="page-one-element-one">
      <div style={{ display: 'flex', marginTop: '8px' }}>
      <p style={{ width: '50%', marginLeft:"20px", margin:"0"}}>Start Date</p>
        <p style={{ width: '50%', marginLeft:"15px", margin:"0"}}>End Date</p>
        
        {/* <Text type="secondary" strong  style={{ width: '50%', marginLeft:"15px"}}>Start Date</Text>
        <Text type="secondary" strong  style={{ width: '50%',marginLeft:"10px", textAlign: 'left' }}>End Date</Text> */}

      </div>

      <RangePicker
        // value={dates}
        value={[moment('11/20/2024', 'MM/DD/YYYY'), moment('11/27/2024', 'MM/DD/YYYY')]}

        onChange={handleChange1}
        // format="YYYY-MM-DD"

        format="MM/DD/YYYY"
        defaultValue={[moment().subtract(7, 'days'), moment()]}
        style={{ width: '100%' }}
        required
        allowClear
        // minDate={moment()}
        disabledDate={(current) => current && current.isBefore(moment(), 'day')}

      />
    </Space>

    <style jsx global>{`
        .ant-picker-dropdown {
          z-index: 9999 !important; /* Ensure the calendar is on top */
        }

        .custom-range-picker-dropdown {
          z-index: 9999 !important; /* Ensure the calendar is on top */
        }

        /* Make the RangePicker more mobile-friendly */
        .ant-picker {
          width: 100%;
          font-size: 14px; /* Adjust font size for better touch experience */
        }

        /* Ensure calendar dropdown is full-width on mobile devices */
        @media (max-width: 768px) {
          .ant-picker-dropdown {
            width: 100% !important;
            left: 0 !important;
            right: 0 !important;
            max-width: 100% !important;
          }

          /* Optionally, adjust font size for mobile */
          .ant-picker-input input {
            font-size: 14px;
          }
        }

        /* Make sure calendar is more responsive on smaller screens */
        @media (max-width: 480px) {
          .ant-picker-dropdown {
            width: 100% !important;
            max-width: 100% !important;
            font-size: 12px;
          }

          .ant-picker-input input {
            font-size: 12px;
            padding: 10px; /* Make it more touch-friendly */
          }
        }



        @media (max-width: 992px) {
  .ant-picker-panel:last-child .ant-picker-date-panel {
    display: none !important;
  }

  .ant-picker-panel:first-child
    .ant-picker-date-panel
    .ant-picker-header-next-btn,
  .ant-picker-panel:first-child
    .ant-picker-date-panel
    .ant-picker-header-super-next-btn {
    visibility: initial !important;
  }

  .ant-picker-panel.ant-picker-range-wrapper {
    min-width: 288px !important;
  }

  .ant-picker-presets {
    display: none !important;
  }
}
      `}</style>
                      </div>
                      {/* ====================================== */}


                      {/* ========================================= */}
                      {stepForm.oneTimePayment === "false" ? (
                      <div className="task--heading--input mt-2">
                        {stepForm.subscriptionInterval?<p>Your job will reoccur <b>{stepForm.subscriptionInterval=="week"? 'weekly':'monthly'}</b> from your selected start date and your credit card will be charged approximately 48 hours prior.</p>
:                        <p>Your job will reoccur <b>{`weekly/monthly`}</b> from your selected start date and your credit card will be charged approximately 48 hours prior.</p>
}

                      </div>
                    ) : null}
                    </div>

                  </div>
                </div>
              </div>
            )}
            {step === 2 && (
              <div className="step--container">
                                      {/* <Tooltip id={`jobStep2`} className="left-tooltip" style={{ width: "20%" }}>
                      Job Title (e.g., "Furniture Assesmbly," "Yard Cleanup," "Gutter Cleaning," "Picture Hanging," "Move Boxes")
                    </Tooltip> */}
                    {/* data-tooltip-id={`jobStep1`} */}

                <div className="task--heading--label" data-tooltip-id={`jobStep2`}  >
                  <label>2/6</label>
                  <h2 className="playFairDisplay heading-h1 text-dark">
                    Enter Job Title
                  </h2>
                  <p style={{margin:"3px"}}>
  e.g. Furniture Assembly, Yard Cleanup, Gutter Cleaning,
  <br className="hide-br"/>
  Picture Hanging, Move Boxes.
</p>
                </div>

                <div className="page-one-element-two task--heading--input" style={{marginTop:"29px"}}>
                  <label>
                    Title <span className="mendotry-field">*</span>
                  </label>
                  <div className="buget--members ">
                    <div>
                      <input
                        type="text"
                        name="title"
                        onChange={handleChange}
                        // value={stepForm.title}
                        value="I need my lawn mowing"

                        required
                      />
                     {error && <p style={{color:"red"}} className="error-message">{error}</p>}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {step === 3 && (
              <div className="step--container">
                <div className="task--heading--label" data-tooltip-id={`jobStep3`}>
                  <label>3/6</label>
                  <h2 className="playFairDisplay heading-h1 text-dark">
                    Select Job Type
                  </h2>
                  <p style={{margin:"3px"}}> e.g. Cleaning, Handyman, Moving Help
                  </p>
                </div>

                <div className="task--heading--input page-one-element-three" style={{marginTop:"30px"}}>
                  <label>
                    Select Job <span className="mendotry-field">*</span>
                  </label>
                  <CreatableSelect
                    options={jobList}
                    isClearable
                    className="select--custom"
                    placeholder={`I need help with`}
                    // value={selectedService}
                    // value="lawn mowing"
                    value={{ label: "Lawn Mowing", value: "lawn mowing" }}

                    onChange={handleSelectTask}
                    onInputChange={handleInputChange}
                    required
                  />
                </div>
              </div>
            )}
            {step === 4 && (
              <div className="step--container">
                <div className="task--heading--label"                      
                data-tooltip-id={`jobStep4`}
                >
                  <label>4/6</label>
                  <h2 className="playFairDisplay heading-h1 text-dark">
                    Location
                  </h2>
                  <p style={{margin:"3px"}}>
                  Side Hustl is always expanding! Please select a city  from  <br className="hide-br" />
                  the dropdown where our services are currently available.</p>
                 
                </div>
                <div className="page-one-element-four select--city" >
                  <div className=" task--heading--input mb-20px" style={{marginTop:"30px"}}>
                    <label>
                      Select Province <span className="mendotry-field">*</span>
                    </label>
                    <Select
                      options={provinceList}
                      // isClearable
                      isClearable={false}  // Change this to false

                      className="select--custom province--select"
                      // value={selectedProvince}
                      value={{ value: "BC", label: "British Columbia" }}

                      // { value: "BC", label: "British Columbia" },
                      placeholder={`Select Province`}
                      onChange={handleSelectProvince}
                      required
                    />
                  </div>

                  <div className="task--heading--input">
                    <label>
                      Select City <span className="mendotry-field">*</span>
                    </label>
                    <Select
                      options={selectedProvince?cityList:[]}
                      isClearable
                      className="select--custom"
                      // value={selectedCity}
                     // {"value": "Kamloops", "label": "Kamloops"}
                     value={{ value: "Kamloops", label: "Kamloops" }}

               
                      placeholder={`Select City`}
                      onChange={handleSelectCity}
                      required
                    />
                  </div>
                </div>
              </div>
            )}

            {step === 5 && (
              <div className="step--container">
                <div data-tooltip-id={`jobStep5`} className="task--heading--label">
                  <label>5/6</label>
                  <h2 className="playFairDisplay heading-h1 text-dark">
                    Select Budget
                  </h2>
                  <p style={{margin:"3px"}}>
                  Your budget helps us match you with the right talent and  <br className="hide-br" />
                  ensures you receive accurate offers within your price range.
                  </p>
                </div>
                <div className="task--heading--input" style={{marginTop:"30px"}}>
                  <div className="page-one-element-five buget--members">
                    <div>
                      <label>
                        Budget <span className="mendotry-field">*</span>
                      </label>

                      <div className="input--dolldar ">
                        {/* <span className="dollar-icon">$</span> */}
                        <span className="dollar-icon"> {!isOpenToOffers ? "$" : " "}</span>

                        {isOpenToOffers ? (
              <div className="input-like-box">Open to Offers</div>
            ):

                      (  
                      <input
                        required
                          type="text"
                          onChange={handleChange}
                          name="budget"
                          // value={displayBudget(stepForm.budget)}
                          value="65" 
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/^0+/, '');
                          }}

                          placeholder="0"
                          min="1"
                          
                        />
                        )}

                      </div>
                      {isOpenToOffers ?
                       (<>
            <p>
              If you know what your project budget should be,
              <br className="hide-br" />
              click "Enter Budget" to specify it.
            </p>
                      </>)
                      :

                      (<><p>Don't know  what your project budget should be?
                        <br className="hide-br" />

                        Click "Open to Offers" and allow a Hustlr to suggest a price.
                      </p></>)}
                      

                      <button type="button" className="custom--btn darkbtn-hover"             
                      onClick={handleToggleClick}
                      >
                <span>{isOpenToOffers ? "Enter Budget" : "Open to Offers"}</span>

              </button>

                    </div>
                  </div>
                </div>
                       <style jsx>{`
        .input-like-box {
           display: inline-block;
          width: 100%;
          padding: 8px;
          border: 1px solid #ccc;
         border-radius: 4px;
         background-color: #f9f9f9;
          color: #333;
          font-size: 1em;
          line-height: 2.7;
          box-sizing: border-box;
        }
      `}</style>

              </div>
            )}
            {step === 6 && (
              <div className="step--container">
                <div className="task--heading--label">
                  <label>6/6</label>
                  <h2
                    className="playFairDisplay heading-h1 text-dark hidedown-767"
                    style={{ height: "145px" }}
                  >
                    Add Your Description
                  </h2>
                  <h2 className="playFairDisplay heading-h1 text-dark hidedown-767 mt-35">
                    Add Your Images
                  </h2>
                </div>

                <div className="add--image-des">
                  <h2 className="playFairDisplay heading-h1 text-dark hideup-767">
                    Add Your Description
                  </h2>
                  <div className="page-one-element-six task--heading--input pb-4" style={{marginTop:"30px"}}>
                    <label>
                      Description <span className="mendotry-field">*</span>
                    </label>
                    <br />
                    <textarea
                      className="w-100 p-2"
                      name="description"
                      onChange={handleChange}
                      // value={stepForm.description}
                      value="My lawn 100 meter Square"

                      required
                    ></textarea>
                  </div>

                  <h2 className="playFairDisplay heading-h1 text-dark hideup-767">
                    Add Your Images
                  </h2>

                  <div className="task--heading--input">
                    <div className="upload--media">
                      <div className="upload--file">
                        <div className="btn btn-dark darkbtn-hover">
                          Upload Media
                        </div>
                        <input
                          type="file"
                          name="mediaupload"
                          multiple="multiple"
                          accept="image/png, image/gif, image/jpeg"
                          onChange={handleMediaUpload}
                        />
                      </div>

                      <div className="media--list--task">
                        {stepForm.job_image.map((image, index) => (
                          <div className="media--item" key={index}>
                            <p
                              className="remove--media"
                              onClick={() => removeImage(index)}
                            >
                              <IoCloseOutline />
                            </p>
                            <img
                              src={URL.createObjectURL(image)}
                              alt={`Image ${index + 1}`}
                            />
                          </div>
                        ))}
                      </div>

                      {uploadLimitExceeded && (
                        <p style={{ color: "red" }} className="pt-3">
                          Upload limit exceeded. You can upload a maximum of{" "}
                          {MAX_UPLOAD_LIMIT} images.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="task--steps-buttons mt-25" onClick={handleNext} >
              <button
                type="button"
                className="custom--btn ligghtbtn-hover"
                onClick={handlePrevious}
                disabled={step === 1}
              >
                <span>Previous</span>
              </button>

              <button type="submit"  className="custom--btn darkbtn-hover">
                <span>{step === 6 ? "Submit" : "Next"}</span>
              </button>
              {/* <button onClick={checkValue}>
                check
              </button> */}
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default IsLoadingHOC(DirectorCreateTask);
