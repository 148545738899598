import React from 'react'
import { BsChatRightText } from 'react-icons/bs'
import { Link } from "react-router-dom"
import IsLoadingHOC from './IsLoadingHOC'

const DirectorThankyouPage = () => {
  return (
    <>
      <div className='thankyou--container'>
        <div className='thankyou--col'>
          <h1 className='text--dark playFairDisplay'>Thank You!</h1>
          {/* <p>Thanks for hiring a Hustlr.</p> */}
         <p>Thanks you for sending an offer to the Hustlr.</p>
          <div className='button--thankyou'>
            {/* 
            <button className="custom--btn ligghtbtn-hover transparent--btn minbutton">
              <span>
                <BsChatRightText /> Chat With Hustler
              </span>
            </button>
            <button className="custom--btn ligghtbtn-hover transparent--btn minbutton">
              <span>
                InProgress Task
              </span>
            </button>

            <Link to='/'>Home</Link>


            */}

            <Link  
            to={{
              pathname: '/your-task-list',
            }}
            state={{ id: "in-progress" }}
            className="custom--btn ligghtbtn-hover transparent--btn minbutton">
              <span>
                Your jobs
              </span>
            </Link>

          </div>
        </div>
      </div>
    </>
  )
}

export default IsLoadingHOC(DirectorThankyouPage)