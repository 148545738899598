import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CanadianCity } from "../../utils/constants";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import { State, City } from "country-state-city";

import { Link, useNavigate } from "react-router-dom";
import IsLoadingHOC from "../../common/IsLoadingHOC";
import { authAxios, withoutAuthAxios } from "../../services/config";
import { toast } from "react-toastify";
import { PiEyeLight, PiEyeSlash } from "react-icons/pi";
import TermsandConditonModel from "../../common/TermsandConditonModel";
import {
  checkServices,
  // HustlerDescription,
  correctArrayFormat,
  dataWithoutIds,
  removeIds,
} from "../../utils/helper";
import { IoClose, IoCloseCircle, IoEyeOutline } from "react-icons/io5";
import usePasswordValidation from "../../common/usePasswordValidation";
import { FaCheck } from "react-icons/fa6";
// import { useServices } from "../../context/ServiceContext";
import { useServices } from "../../contexts/ServiceContext";



const HustlerSignup = (props) => {
  const servicesData = useServices();

  const { setLoading } = props;
  const navigate = useNavigate();

  const { toggleActiveForm } = props;

  const [selectedService, setSelectedService] = useState(null);
  const [selectedCategory, setselectedCategory] = useState(null);
  const [selectedProvince, setselectedProvince] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [specificCategory, setspecificCategory] = useState([]);
  const [provinceList, setprovinceList] = useState([]);
  const [cityList, setcityList] = useState([]);
  const [stateCode, setStateCode] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showTermsandConditonModel, setshowTermsandConditonModel] =
    useState(false);
  const [addMoreCategory, setAddMoreCategory] = useState(false);
  const [consent, setConsent] = useState(false);
  
  const handleCheckboxChange = (event) => {
    setConsent(event.target.checked);
  };


  const [allservices, setAllServices] = useState({
    count: 0,
    services: [],
  });
  const taskCatgeory = useSelector((state) => state.app?.services);
  const taskServices = useSelector((state) => state.app.tasks);
  const task = useSelector((state) => state);
  // https://hustlenode.makemagento.com/api/services/get-all-services

const [data, setData] = useState([])
  const getTaskListData = async () => {
    setLoading(true);
    await withoutAuthAxios()
      .get(`/services/get-all-services`)
      .then((response) => {
        const resData = response?.data.data;
        // setData(response?.data.data)
        setLoading(false);
        if (resData?.status == 1) {

          

          const services = [];
          resData?.data.map((item) => {
            services.push({
              label:  toCamelCase(item?.serviceName),
              value: toCamelCase(item?.serviceName),
            });
          });

          const category=[]

          resData?.data?.map((item)=>{
            item?.category?.map((cat)=>{
              category?.push(cat)
            })
          })

          // dispatch(CombineTaskOptions(resData))
          // dispatch(saveServices(RemovingDuplicatesServicesandCategories(services)))
          // dispatch(saveTaskOptions(RemovingDuplicatesServicesandCategories(category)))

          

        //  dispatch(saveServices(services));
          
        } else {
          toast.error(resData?.message?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    getTaskListData();
  }, []);











  const [showWindow, setshowWindow] = useState({
    basicDetails: true,
    verifyOtp: false,
    insertPassword: false,
  });

  const [categoriesModal, setCategoriesModal] = useState(false);

  const [formData, setformData] = useState({
    step: 1,
    email: "",
    fname: "",
    lname: "",
    phoneno: "",
    password: "",
    confirmpassword: "",
    category: [],
    serviceName: "",
    termsandConditon: "false",
    //price: 20,
    city: "",
    otp: "",
    userType: "HUSTLER",
  });

  const { step } = formData;

  const handleTogglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword((prevShowPassword) => !prevShowPassword);
    } else if (field === "confirmPassword") {
      setShowConfirmPassword(
        (prevShowConfirmPassword) => !prevShowConfirmPassword
      );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAlphabet=(e)=>{
    const { name, value } = e.target;
    // const regex = /^[A-Za-z ]*$/;
    const regex = name === "lname" ? /^[A-Za-z\- ]*$/ : /^[A-Za-z ]*$/;

  if(regex.test(value)){
    setformData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }
  }



  const handleNumber = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    if (sanitizedValue.match(/^[0-9]*$/)) {
      setformData((prev) => ({
        ...prev,
        [name]: sanitizedValue,
      }));
    }
  };
  
  const handlePaste = (e) => {
    const pastedText = e.clipboardData.getData("Text");
    
    // Remove + and anything after it if it's followed by a number
    let sanitizedValue = pastedText.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    
    // Check if the pasted number starts with the country code (e.g. +1)
    if (sanitizedValue.startsWith("1") && sanitizedValue.length > 10) {
      sanitizedValue = sanitizedValue.substring(1); // Remove the leading 1 (country code)
    }
  
    e.preventDefault(); // Prevent the default paste action
    
    // Update formData with the sanitized value
    setformData((prev) => ({
      ...prev,
      phoneno: sanitizedValue,
    }));
  };

  const handleTermsCheckbox = () => {
    if (formData.termsandConditon === true) {
      setformData((prev) => ({
        ...prev,
        termsandConditon: false,
      }));
      setshowTermsandConditonModel(true);
    } else {
      setformData((prev) => ({
        ...prev,
        termsandConditon: false,
      }));
      setshowTermsandConditonModel(true);
    }
  };

  const toCamelCase = (str) => {
    if(str){ 
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  };

  const handleMailChimp = (email) => {
    const formData = new FormData();
    formData.append('EMAIL', email);
  //  formData.append('group[35460][1]', 'true');
     formData.append('group[35460][2]', 'true');
    // if (!group1 && !group2) {
    //   errorNotification()
    //   return; // Prevent form submission if neither group is selected
    // }
    // if (group1 && group2){
    //   errorOneNotification()
    //   return; // Prevent form submission if neither group is selected

    // }
  

    fetch('https://sidehustl.us11.list-manage.com/subscribe/post?u=a446787384fb0789d8393c9ec&id=57bfaab8f4&f_id=004fd0e3f0', {
      method: 'POST',
      body: formData,
      mode: 'no-cors', // This allows cross-origin POST without handling the response.
    }).then(() => {
      // openNotification()
      // alert("this is mailchimp")
    }).catch(err => console.error('Error:', err));
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if(formData.phoneno.length<10){
      toast.warn('Phone number must be 10 digits')
    }else{
      if(!formData.email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)){
        toast.warn('Enter Correct Email Address')
        return false
      }
      if (formData.step === 2) {
        const finalCatgory = [];
        formData.category.map((item) => {
          finalCatgory.push({ label: toCamelCase(item?.label), value: toCamelCase(item?.value) });
        });
        const firstArray = {
          serviceName:  toCamelCase(formData.serviceName.label),
          category: finalCatgory,
        };
        const modifyArray = removeIds(allservices.services);
        const modifyArray2 = correctArrayFormat(modifyArray);
        const mergedArray = [...modifyArray2, firstArray];


        if (checkServices(mergedArray) == "not completed") {
          toast.info("Please fill in all categories and services.");
          return false;
        }

        const payload = {
          email: formData.email,
          fname: formData.fname,
          lname: formData.lname,
          phoneno: formData.phoneno,
          province: selectedProvince?.label,
          location: formData.city?.value,
          userType: "HUSTLER",
  
          hustlerDetails: { services: mergedArray },
          // description:HustlerDescription
        };
        setLoading(true);
        await withoutAuthAxios()
          .post("/users/register", payload)
          .then((response) => {
            const resData = response?.data;
            setLoading(false);
            if (resData.status == 1) {
              toast.success(resData?.message);
              setshowWindow({
                basicDetails: false,
                verifyOtp: true,
                insertPassword: false,
              });

              if (consent) {
                console.log(formData.email,"email checking")
                handleMailChimp(formData.email)
              } 
              // else {
              //   alert("Please provide consent to receive updates.");
              // }
              // navigate("/login");
            } else {
              toast.error(resData?.message);
            }
          })
          .catch((error) => {
            setLoading(false);
            toast.error(error?.response?.data?.message);
          });
      } else {
        setformData((prevState) => ({
          ...prevState,
          step: prevState.step + 1,
        }));
      }
    }
  };

  const handlePrevious = () => {
    if (formData.step > 1) {
      setformData((prevState) => ({
        ...prevState,
        step: prevState.step - 1,
      }));
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      email: formData.email,
      enteredOTP: Number(formData.otp),
    };
    await authAxios()
      .post(`/users/verify-otp`, payload)
      .then((response) => {
        const resData = response?.data;
        setLoading(false);
        if (resData.status == 1) {
          toast.success(resData?.message);
          setshowWindow({
            basicDetails: false,
            verifyOtp: false,
            insertPassword: true,
          });
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };
  const handleReSendOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      email: formData.email,
    };
    await authAxios()
      .post(`users/resend-otp`, payload)
      .then((response) => {
        const resData = response?.data;
        setLoading(false);
        if (resData.status == 1) {
          toast.success(resData?.message);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };
  const handleSetPassword = async (e) => {
    e.preventDefault();
    
    if (formData.password.length < 8 ||
      !/(?=.*[A-Z])/.test(formData.password) ||
      !/(?=.*[!@#$%^&*])/.test(formData.password) ||
      !/(?=.*[0-9])/.test(formData.password)) {
      toast.error("Password criteria do not match.");
      return;
    }
    if (formData.confirmpassword == formData.password) {
      const payload = {
        email: formData.email,
        password: formData.password,
        confirmPassword: formData.confirmpassword,
      };
      await authAxios()
        .post(`users/setup-password`, payload)
        .then((response) => {
          const resData = response?.data;
          setLoading(false);
          if (resData.status == 1) {
            toast.success(resData?.message);
            navigate(`/login`);
          } else {
            toast.error(resData?.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message);
        });
    } else {
      toast.error("The passwords entered do not match.");
    }
  };

  // const handleSelectService = (e) => {
  //   setformData((prev) => ({
  //     ...prev,
  //     serviceName: e,
  //     category:[]
  //   }));
  //   //setSelectedService(e);
  //  // setselectedCategory([]);
  // };

  const handleSingleService=(value,e)=>{
    if (e.action == "menu-close") {
      if (e.prevInputValue.length > 0) {
        const newOption = { label: e.prevInputValue, value: e.prevInputValue };
        setformData((prev) => ({
          ...prev,
          serviceName: newOption,
          category:[]
        }));   
      }
    }
  }

  const handleSelectCategory = (e) => {
    setformData((prev) => ({
      ...prev,
      category: e,
    }));
   // setselectedCategory(e);
  };
  const handleSingleCategory = (value, e) => {
    if (e.action === "menu-close") {
      if (e.prevInputValue.length > 0) {
        const newOption = { label: e.prevInputValue, value: e.prevInputValue };
        const exists = formData.category.some(option => option.value.toLowerCase() === newOption.value.toLowerCase());
  
        if (!exists) {
          setformData((prev) => ({
            ...prev,
            category: [...formData.category, newOption],
          }));
        }
      }
    }
  };

  const handleSelectCity = (e) => {
    setformData((prev) => ({
      ...prev,
      city: e,
    }));
    setSelectedCity(e);
  };

  const handleSelectProvince = (e) => {
    if (e) {
      setStateCode(e?.value);
      setselectedProvince(e);
      setSelectedCity([]);
    } else {
      setcityList([]);
      setSelectedCity([]);
      setStateCode("");
      setselectedProvince([]);
    }
  };

  useEffect(() => {
    // setprovinceList(
    //   State.getStatesOfCountry("CA").map(({ isoCode, name }) => ({
    //     value: isoCode,
    //     label: name,
    //   }))
    // );
    setprovinceList(
      [ { value: "BC", label: "British Columbia" },
        {value: "SK",label: "Saskatchewan"}
     ]
      );

  }, []);

  useEffect(() => {
    setcityList([])
if(stateCode=="BC"){
    setcityList(
      [ {"value": "Kamloops", "label": "Kamloops"},
        {"value": "Kelowna","label": "Kelowna"},
        {"value": "Peachland","label": "Peachland"},
        {"value": "Penticton","label": "Penticton"},
        {"value": "Vernon","label": "Vernon"},
        {"value": "West Kelowna","label": "West Kelowna"},
      ]
    )
  }
  else{

    setcityList(
      [ {value: "Saskatoon",label: "Saskatoon"}
      ]
    )


  }

    if (stateCode) {
      // setcityList(
      //   City.getCitiesOfState("CA", stateCode).map(({ name }) => ({
      //     value: name,
      //     label: name,
      //   }))
      // );
    }
  }, [stateCode]);

  const handleAddService = () => {
    setAllServices((prevState) => ({
      count: prevState.count + 1,
      services: [
        ...prevState.services,
        { id: prevState.count, serviceName: "", category: [] },
      ],
    }));

    setCategoriesModal(true)
  };

  const handleRemoveService = (indexToRemove) => {
    setAllServices((prevState) => {
      const updatedServices = prevState.services.filter((_, index) => index !== indexToRemove);
      return {
        count: Math.min(prevState.count, updatedServices.length),
        services: updatedServices
      };
    });

  };
  const handleAddServiceNameChange = (newValue, index) => {
    setAllServices((prevState) => {
      const updatedServices = [...prevState.services];
      updatedServices[index].serviceName = newValue;
      return { ...prevState, services: updatedServices };
    });

  };

  const handleInputAddServiceNameChange=(inputValue, actionMeta, index)=>{
    if (actionMeta.action == "menu-close") {
      if (actionMeta.prevInputValue.length > 0) {
        const newOption = { label: actionMeta.prevInputValue, value: actionMeta.prevInputValue };
        setAllServices((prevState) => {
          const updatedServices = [...prevState.services];
          updatedServices[index].serviceName = newOption;
          return { ...prevState, services: updatedServices };
        });
      }
    }

  }

  const handleAddCategoryChange = (newValue, index) => {
    setAllServices((prevState) => {
      const updatedServices = [...prevState.services];
      updatedServices[index].category = newValue;
      return { ...prevState, services: updatedServices };
    });
  };

  // const handleInputAddCategoryChange=(inputValue, actionMeta, index)=>{
  //    if (actionMeta.action == "menu-close") {
       
  //      if (actionMeta.prevInputValue.length > 0) {              
  //        const newOption = { label: actionMeta.prevInputValue, value: actionMeta.prevInputValue };

  //        setAllServices((prev) => {
  //            const updatedServices = [...prev.services];
  //            const updatedCategories = [...updatedServices[index].category, newOption];
  //            updatedServices[index] = {
  //                ...updatedServices[index],
  //                category: updatedCategories
  //            };
  //            return { ...prev, services: updatedServices };
  //        });
  //      }
  //    }
  //  }
  const [selectedServiceName, setSelectedServiceName] = useState(null);
const [categoryOptions, setCategoryOptions] = useState([]);
useEffect(() => {
  if (selectedServiceName) {
    // Filter categories based on the selected service name
    const filteredCategories = data
      .find(service => service.serviceName === selectedServiceName)
      ?.category || [];

    setCategoryOptions(filteredCategories);
  } else {
    setCategoryOptions([]);
  }
}, [selectedServiceName, data]);

const handleSelectService = (selectedOption) => {
  setSelectedServiceName(selectedOption ? selectedOption.value : null);
  setformData(prevState => ({
    ...prevState,
    serviceName: selectedOption
  }));
};


  const handleInputAddCategoryChange = (inputValue, actionMeta, index) => {
    if (actionMeta.action === "menu-close") {
      if (actionMeta.prevInputValue.length > 0) {
        const newOption = { label: actionMeta.prevInputValue, value: actionMeta.prevInputValue };
  
        setAllServices((prev) => {
          const updatedServices = [...prev.services];
          const existingCategories = updatedServices[index].category;
          
          // Check for duplicates (case insensitive)
          const exists = existingCategories.some(option => option.value.toLowerCase() === newOption.value.toLowerCase());
  
          if (!exists) {
            const updatedCategories = [...existingCategories, newOption];
            updatedServices[index] = {
              ...updatedServices[index],
              category: updatedCategories
            };
            return { ...prev, services: updatedServices };
          }
          return prev;
        });
      }
    }
  };
  
  const {
    hasValidLength,
    hasNumber,
    hasUpperCase,
    hasSpecialCharacter
  } = usePasswordValidation(formData.password);

  // const services = [
  //   {
  //     _id: "1",
  //     serviceName: "Appliance Help",
  //     category: [
  //       "Installing new appliances",
  //       "Troubleshooting and repairing common appliance issues",
  //       "Appliance maintenance",
  //       "Disposing of old appliances"
  //     ]
  //   },
  //   {
  //     _id: "2",
  //     serviceName: "Assembly & Installation",
  //     category: [
  //       "Assembling furniture",
  //       "Installing shelves, cabinets, and racks",
  //       "Setting up fitness equipment",
  //       "Installing light fixtures or ceiling fans",
  //       "Mounting TVs and other electronics"
  //     ]
  //   },
  //   {
  //     _id: "3",
  //     serviceName: "Cleaning Services",
  //     category: [
  //       "Deep cleaning kitchens and bathrooms",
  //       "Carpet and upholstery cleaning",
  //       "Dusting, vacuuming, and mopping",
  //       "Post-construction or renovation cleanup",
  //       "Organizing and decluttering"
  //     ]
  //   },
  //   {
  //     _id: "4",
  //     serviceName: "Curtain & Blind Setup",
  //     category: [
  //       "Installing curtain rods and blinds",
  //       "Measuring and fitting custom window treatments",
  //       "Repairing broken blinds or shades",
  //       "Cleaning curtains and blinds",
  //       "Replacing or updating hardware"
  //     ]
  //   },
  //   {
  //     _id: "5",
  //     serviceName: "Deck & Outdoor Assistance",
  //     category: [
  //       "Building or repairing decks and patios",
  //       "Power washing outdoor surfaces",
  //       "Staining or painting decks",
  //       "Installing outdoor lighting",
  //       "Setting up outdoor furniture or gazebos"
  //     ]
  //   },
  //   {
  //     _id: "6",
  //     serviceName: "Electrical Help",
  //     category: [
  //       "Installing or replacing light fixtures",
  //       "Troubleshooting wiring issues",
  //       "Setting up smart home devices",
  //       "Replacing outlets and switches",
  //       "Upgrading circuit breakers"
  //     ]
  //   },
  //   {
  //     _id: "7",
  //     serviceName: "Errands and Personal Assistance",
  //     category: [
  //       "Grocery shopping and delivery",
  //       "Post office runs and package delivery",
  //       "Scheduling appointments and reservations",
  //       "Helping with event planning and setup"
  //     ]
  //   },
  //   {
  //     _id: "8",
  //     serviceName: "Furniture Moving & Packing",
  //     category: [
  //       "Packing and labeling boxes for moving",
  //       "Assembling and disassembling furniture",
  //       "Loading and unloading moving trucks",
  //       "Rearranging furniture within a home"
  //     ]
  //   },
  //   {
  //     _id: "9",
  //     serviceName: "Garage Organization",
  //     category: [
  //       "Installing shelving and storage systems",
  //       "Decluttering and disposing of unwanted items",
  //       "Cleaning and sweeping garages",
  //       "Setting up workbenches and tool racks",
  //       "Organizing seasonal items like holiday decor"
  //     ]
  //   },
  //   {
  //     _id: "10",
  //     serviceName: "Gutter Cleaning",
  //     category: [
  //       "Removing leaves and debris from gutters",
  //       "Inspecting for leaks or damage",
  //       "Installing gutter guards",
  //       "Clearing downspouts",
  //       "Seasonal gutter maintenance"
  //     ]
  //   },
  //   {
  //     _id: "11",
  //     serviceName: "Hanging & Mounting",
  //     category: [
  //       "Hanging artwork and picture frames",
  //       "Mounting TVs and sound systems",
  //       "Installing mirrors securely",
  //       "Setting up curtain rods and blinds",
  //       "Hanging shelving units"
  //     ]
  //   },
  //   {
  //     _id: "12",
  //     serviceName: "Home Organization",
  //     category: [
  //       "Organizing closets and wardrobes",
  //       "Setting up pantry storage systems",
  //       "Arranging home offices",
  //       "Sorting and organizing kids' playrooms",
  //       "Creating filing systems for paperwork"
  //     ]
  //   },
  //   {
  //     _id: "13",
  //     serviceName: "Junk Removal",
  //     category: [
  //       "Hauling away old furniture and appliances",
  //       "Yard waste removal",
  //       "Construction debris cleanup",
  //       "Removing unwanted electronics and e-waste",
  //       "Cleaning out basements, attics, or garages"
  //     ]
  //   },
  //   {
  //     _id: "14",
  //     serviceName: "Minor Repairs",
  //     category: [
  //       "Fixing leaky faucets",
  //       "Patching small holes in walls",
  //       "Repairing doors or hinges",
  //       "Replacing broken tiles or grout",
  //       "Repairing screens or windows"
  //     ]
  //   },
  //   {
  //     _id: "15",
  //     serviceName: "Painting",
  //     category: [
  //       "Interior wall painting",
  //       "Touch-up and trim painting",
  //       "Exterior painting for fences and siding",
  //       "Staining or painting decks and patios"
  //     ]
  //   },
  //   {
  //     _id: "16",
  //     serviceName: "Plumbing",
  //     category: [
  //       "Fixing leaks or clogs",
  //       "Installing faucets, sinks, or toilets",
  //       "Replacing water heaters",
  //       "Cleaning or replacing pipes",
  //       "Setting up irrigation systems"
  //     ]
  //   },
  //   {
  //     _id: "17",
  //     serviceName: "Power Washing",
  //     category: [
  //       "Cleaning driveways and sidewalks",
  //       "Power washing house exteriors",
  //       "Cleaning decks and patios",
  //       "Removing graffiti",
  //       "Cleaning fences or outdoor furniture"
  //     ]
  //   },
  //   {
  //     _id: "18",
  //     serviceName: "Quick Fixes",
  //     category: [
  //       "Replacing light bulbs and batteries",
  //       "Tightening loose screws or handles",
  //       "Fixing squeaky doors or cabinets",
  //       "Securing loose floorboards",
  //       "Adjusting misaligned doors or drawers"
  //     ]
  //   },
  //   {
  //     _id: "19",
  //     serviceName: "Seasonal Jobs",
  //     category: [
  //       "Installing and removing holiday lights",
  //       "Cleaning and organizing seasonal decorations",
  //       "Preparing outdoor spaces for winter or summer",
  //       "Seasonal garden planting or cleanup",
  //       "Winterizing pipes and outdoor equipment"
  //     ]
  //   },
  //   {
  //     _id: "20",
  //     serviceName: "Snow Removal",
  //     category: [
  //       "Shoveling driveways and walkways",
  //       "Clearing snow from roofs",
  //       "Salting or de-icing paths",
  //       "Removing snow from vehicles",
  //       "Setting up snow removal equipment"
  //     ]
  //   },
  //   {
  //     _id: "21",
  //     serviceName: "Window Cleaning",
  //     category: [
  //       "Washing interior and exterior windows",
  //       "Cleaning window tracks and frames",
  //       "Removing hard water stains",
  //       "Cleaning screens and storm windows"
  //     ]
  //   },
  //   {
  //     _id: "22",
  //     serviceName: "Yard Work",
  //     category: [
  //       "Mowing lawns and edging",
  //       "Planting flowers, trees, or shrubs",
  //       "Weeding and mulching garden beds",
  //       "Trimming hedges or pruning trees",
  //       "Cleaning up leaves or storm debris"
  //     ]
  //   }
  // ];


  const services = [
    {
      id: "1",
      serviceName: "Appliance Help",
      category: [
        { label: "Installing new appliances", value: "Installing new appliances" },
        { label: "Troubleshooting and repairing common appliance issues", value: "Troubleshooting and repairing common appliance issues" },
        { label: "Appliance maintenance", value: "Appliance maintenance" },
        { label: "Disposing of old appliances", value: "Disposing of old appliances" }
      ]
    },
    {
      id: "2",
      serviceName: "Assembly & Installation",
      category: [
        { label: "Assembling furniture", value: "Assembling furniture" },
        { label: "Installing shelves, cabinets, and racks", value: "Installing shelves, cabinets, and racks" },
        { label: "Setting up fitness equipment", value: "Setting up fitness equipment" },
        { label: "Installing light fixtures or ceiling fans", value: "Installing light fixtures or ceiling fans" },
        { label: "Mounting TVs and other electronics", value: "Mounting TVs and other electronics" }
      ]
    },
    {
      id: "3",
      serviceName: "Cleaning Services",
      category: [
        { label: "Deep cleaning kitchens and bathrooms", value: "Deep cleaning kitchens and bathrooms" },
        { label: "Carpet and upholstery cleaning", value: "Carpet and upholstery cleaning" },
        { label: "Dusting, vacuuming, and mopping", value: "Dusting, vacuuming, and mopping" },
        { label: "Post-construction or renovation cleanup", value: "Post-construction or renovation cleanup" },
        { label: "Organizing and decluttering", value: "Organizing and decluttering" }
      ]
    },
    {
      id: "4",
      serviceName: "Curtain & Blind Setup",
      category: [
        { label: "Installing curtain rods and blinds", value: "Installing curtain rods and blinds" },
        { label: "Measuring and fitting custom window treatments", value: "Measuring and fitting custom window treatments" },
        { label: "Repairing broken blinds or shades", value: "Repairing broken blinds or shades" },
        { label: "Cleaning curtains and blinds", value: "Cleaning curtains and blinds" },
        { label: "Replacing or updating hardware", value: "Replacing or updating hardware" }
      ]
    },
    {
      id: "5",
      serviceName: "Deck & Outdoor Assistance",
      category: [
        { label: "Building or repairing decks and patios", value: "Building or repairing decks and patios" },
        { label: "Power washing outdoor surfaces", value: "Power washing outdoor surfaces" },
        { label: "Staining or painting decks", value: "Staining or painting decks" },
        { label: "Installing outdoor lighting", value: "Installing outdoor lighting" },
        { label: "Setting up outdoor furniture or gazebos", value: "Setting up outdoor furniture or gazebos" }
      ]
    },
    {
      id: "6",
      serviceName: "Electrical Help",
      category: [
        { label: "Installing or replacing light fixtures", value: "Installing or replacing light fixtures" },
        { label: "Troubleshooting wiring issues", value: "Troubleshooting wiring issues" },
        { label: "Setting up smart home devices", value: "Setting up smart home devices" },
        { label: "Replacing outlets and switches", value: "Replacing outlets and switches" },
        { label: "Upgrading circuit breakers", value: "Upgrading circuit breakers" }
      ]
    },
    {
      id: "7",
      serviceName: "Errands and Personal Assistance",
      category: [
        { label: "Grocery shopping and delivery", value: "Grocery shopping and delivery" },
        { label: "Post office runs and package delivery", value: "Post office runs and package delivery" },
        { label: "Scheduling appointments and reservations", value: "Scheduling appointments and reservations" },
        { label: "Helping with event planning and setup", value: "Helping with event planning and setup" }
      ]
    },
    {
      id: "8",
      serviceName: "Furniture Moving & Packing",
      category: [
        { label: "Packing and labeling boxes for moving", value: "Packing and labeling boxes for moving" },
        { label: "Assembling and disassembling furniture", value: "Assembling and disassembling furniture" },
        { label: "Loading and unloading moving trucks", value: "Loading and unloading moving trucks" },
        { label: "Rearranging furniture within a home", value: "Rearranging furniture within a home" }
      ]
    },
    {
      id: "9",
      serviceName: "Garage Organization",
      category: [
        { label: "Installing shelving and storage systems", value: "Installing shelving and storage systems" },
        { label: "Decluttering and disposing of unwanted items", value: "Decluttering and disposing of unwanted items" },
        { label: "Cleaning and sweeping garages", value: "Cleaning and sweeping garages" },
        { label: "Setting up workbenches and tool racks", value: "Setting up workbenches and tool racks" },
        { label: "Organizing seasonal items like holiday decor", value: "Organizing seasonal items like holiday decor" }
      ]
    },
    {
      id: "10",
      serviceName: "Gutter Cleaning",
      category: [
        { label: "Removing leaves and debris from gutters", value: "Removing leaves and debris from gutters" },
        { label: "Inspecting for leaks or damage", value: "Inspecting for leaks or damage" },
        { label: "Installing gutter guards", value: "Installing gutter guards" },
        { label: "Clearing downspouts", value: "Clearing downspouts" },
        { label: "Seasonal gutter maintenance", value: "Seasonal gutter maintenance" }
      ]
    },
    {
      id: "11",
      serviceName: "Hanging & Mounting",
      category: [
        { label: "Hanging artwork and picture frames", value: "Hanging artwork and picture frames" },
        { label: "Mounting TVs and sound systems", value: "Mounting TVs and sound systems" },
        { label: "Installing mirrors securely", value: "Installing mirrors securely" },
        { label: "Setting up curtain rods and blinds", value: "Setting up curtain rods and blinds" },
        { label: "Hanging shelving units", value: "Hanging shelving units" }
      ]
    },
    {
      id: "12",
      serviceName: "Home Organization",
      category: [
        { label: "Organizing closets and wardrobes", value: "Organizing closets and wardrobes" },
        { label: "Setting up pantry storage systems", value: "Setting up pantry storage systems" },
        { label: "Arranging home offices", value: "Arranging home offices" },
        { label: "Sorting and organizing kids' playrooms", value: "Sorting and organizing kids' playrooms" },
        { label: "Creating filing systems for paperwork", value: "Creating filing systems for paperwork" }
      ]
    },
    {
      id: "13",
      serviceName: "Junk Removal",
      category: [
        { label: "Hauling away old furniture and appliances", value: "Hauling away old furniture and appliances" },
        { label: "Yard waste removal", value: "Yard waste removal" },
        { label: "Construction debris cleanup", value: "Construction debris cleanup" },
        { label: "Removing unwanted electronics and e-waste", value: "Removing unwanted electronics and e-waste" },
        { label: "Cleaning out basements, attics, or garages", value: "Cleaning out basements, attics, or garages" }
      ]
    },
    {
      id: "14",
      serviceName: "Minor Repairs",
      category: [
        { label: "Fixing leaky faucets", value: "Fixing leaky faucets" },
        { label: "Patching small holes in walls", value: "Patching small holes in walls" },
        { label: "Repairing doors or hinges", value: "Repairing doors or hinges" },
        { label: "Replacing broken tiles or grout", value: "Replacing broken tiles or grout" },
        { label: "Repairing screens or windows", value: "Repairing screens or windows" }
      ]
    },
    {
      id: "15",
      serviceName: "Painting",
      category: [
        { label: "Interior wall painting", value: "Interior wall painting" },
        { label: "Touch-up and trim painting", value: "Touch-up and trim painting" },
        { label: "Exterior painting for fences and siding", value: "Exterior painting for fences and siding" },
        { label: "Staining or painting decks and patios", value: "Staining or painting decks and patios" }
      ]
    },
    {
      id: "16",
      serviceName: "Plumbing",
      category: [
        { label: "Fixing leaks or clogs", value: "Fixing leaks or clogs" },
        { label: "Installing faucets, sinks, or toilets", value: "Installing faucets, sinks, or toilets" },
        { label: "Replacing water heaters", value: "Replacing water heaters" },
        { label: "Cleaning or replacing pipes", value: "Cleaning or replacing pipes" },
        { label: "Setting up irrigation  systems", value: "Setting up irrigation  systems"}

      ]
    },
    {
      id: "17",
      serviceName: "Power Washing",
      category: [
        { label: "Cleaning driveways and sidewalks", value: "Cleaning driveways and sidewalks" },
        { label: "Power washing exterior ", value: "Power washing exterior" },
        { label: "Cleaning decks and patios", value: "Cleaning decks and patios" },
        { label: "Removing graffiti", value: "Removing graffiti" },
        { label: "Cleaning fences or outdoor furniture", value: "Cleaning fences or outdoor furniture" }
      ]
    },
    {
      id: "18",
      serviceName: "Quick Fixes",
      category: [
        { label: "Replacing light bulbs and batteries", value: "Replacing light bulbs and batteries" },
        { label: "Tightening loose screws or handles", value: "Tightening loose screws or handles" },
        { label: "Fixing squeaky doors or cabinets", value: "Fixing squeaky doors or cabinets" },
        { label: "Securing loose floorboards", value: "Securing loose floorboards" },
        { label: "Adjusting misaligned doors or drawers", value: "Adjusting misaligned doors or drawers" }

      ]
    },
    {
      id: "19",
      serviceName: "Seasonal Jobs",
      category: [
        { label: "Installing and removing holiday lights", value: "Installing and removing holiday lights" },
        { label: "Cleaning and organizing seasonal decorations", value: "Cleaning and organizing seasonal decorations" },
        { label: "Preparing outdoor spaces for winter or summer", value: "Preparing outdoor spaces for winter or summer" },
        { label: "Seasonal garden planting or cleanup", value: "Seasonal garden planting or cleanup" },
        { label: "Winterizing pipes and outdoor equipment", value: "Winterizing pipes and outdoor equipment" }
      ]
    },
    {
      id: "20",
      serviceName: "Snow Removal",
      category: [
        { label: "Shoveling driveways and walkways", value: "Shoveling driveways and walkways" },
        { label: "Clearing snow from roofs", value: "Clearing snow from roofs" },
        { label: "Salting or de-icing paths", value: "Salting or de-icing paths" },
        { label: "Removing snow from vehicles", value: "Removing snow from vehicles" },
        { label: "Setting up snow removal equipment", value: "Setting up snow removal equipment" }
      ]
    },
    {
      id: "21",
      serviceName: "Window Cleaning",
      category: [
        { label: "Washing interior and exterior windows", value: "Washing interior and exterior windows" },
        { label: "Cleaning window tracks and frames", value: "Cleaning window tracks and frames" },
        { label: "Removing hard water stains", value: "Removing hard water stains" },
        { label: "Cleaning screens and storm windows", value: "Cleaning screens and storm windows" }
      ]
    },
    {
      id: "22",
      serviceName: "Yard Work",
      category: [
        { label: "Mowing lawns and edging", value: "Mowing lawns and edging" },
        { label: "Planting flowers, trees, or shrubs", value: "Planting flowers, trees, or shrubs" },
        { label: "Weeding and mulching garden beds", value: "Weeding and mulching garden beds" },
        { label: "Trimming hedges or pruning trees", value: "Trimming hedges or pruning trees" },
        { label: "Cleaning up leaves or storm debris", value: "Cleaning up leaves or storm debris" }
      ]
    }
    ];
  

  useEffect(()=>{
    setData(servicesData)
  },[])



  return (
    <>
      <div>
        <div
          className={`form-container sign-up-container ${showWindow.verifyOtp === true ? "verify--otp--container" : ""
            } ${showWindow.insertPassword === true
              ? "password--confirm--container"
              : ""
            }`}
          style={{
            alignItems: "flex-start",
            paddingTop: "clamp(5px,3vw,50px)",
          }}
        >
          {showWindow.basicDetails && (
            <form onSubmit={handleSubmit}>
              <h2 className="mb-3">Become a Hustlr</h2>
              {step === 1 && (
                <>
                  <div className="form--group--login text-start">
                    <label htmlFor="Select your area">Select your area</label>
                    <Select
                      options={provinceList}
                      isClearable={false}
                      className="select--custom"
                      placeholder={`Select your province`}
                      value={selectedProvince}
                      onChange={handleSelectProvince}
                      required
                    />
                  </div>
                  <div className="form--group--login text-start">
                    <label htmlFor="Select your area">Select your area</label>
                    <Select
                      options={cityList}
                      isClearable
                      className="select--custom"
                      placeholder={`Select your city`}
                      value={selectedCity}
                      onChange={handleSelectCity}
                      required
                    />
                  </div>
                  <div className="flex--container">
                    <div className="form--group--login">
                      <label htmlFor="First Name">First Name</label>
                      <input
                        type="text"
                        name="fname"
                        placeholder="Enter first name"
                        value={formData.fname}
                        onChange={handleAlphabet}
                        // autoComplete="given-name"  // Enables browser autofill for first names

                        required
                      />
                    </div>
                    <div className="form--group--login">
                      <label htmlFor="Last Name">Last Name</label>
                      <input
                        type="text"
                        name="lname"
                        placeholder="Enter last name"
                        value={formData.lname}
                        onChange={handleAlphabet}
                        required
                      />
                    </div>
                  </div>
                  <div className="form--group--login">
                    <label htmlFor="Email">Email</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="form--group--login">
                    <label htmlFor="Phone Number">Phone Number</label>
                    <input
                      type="tel"
                      name="phoneno"
                      placeholder="Enter your phone number"
                      value={formData.phoneno}
                      onChange={handleNumber}
                      onPaste={handlePaste} // Add the onPaste handler

                      maxLength={10}
                      required
                    />
                  </div>
                </>
              )}

              {step === 2 && (
                <>

                  {/* add category */}
                  {/* {data.map((service, index)=>(
                    
                  <div className={`container--add--more ${allservices.count > 1 ? "active-scroll" : ""}`}>
                  <div className="row--add--more">
                    <div className="form--group--login text-start">
                      <label htmlFor="Choose a Category">
                        Choose a Service
                      </label>
                      <CreatableSelect
                        required
                        options={service?.serviceName}
                        isClearable
                        className="select--custom"
                        placeholder={`Your Job Title`}
                        value={formData.serviceName}
                        onChange={handleSelectService}
                        onInputChange={handleSingleService}
                      />
                    </div>

                    <div className="form--group--login text-start">
                      <label htmlFor="Choose a Category">
                        Choose a Service
                      </label>
                      <CreatableSelect
                        required
                        options={service?.category}
                        isClearable
                        isMulti
                        className="select--custom multiple--select--overflow"
                        placeholder={`Choose Services`}
                        value={formData.category}
                        onChange={handleSelectCategory}
                        onInputChange={handleSingleCategory}
                      />
                    </div>
                  </div>

                </div>

                  ))} */}

{/* {data.map((service, index) => ( */}
  <div
  //  key={service._id} 
   className={`container--add--more ${allservices.count > 1 ? "active-scroll" : ""}`}>
    <div className="row--add--more">
      <div className="form--group--login text-start">
        <label
        //  htmlFor={`service-name-${index}`}
         >
          Choose a Service
        </label>
        <CreatableSelect
          required
          options={data.map(s => ({ label: s.serviceName, value: s.serviceName }))}
          isClearable
          className="select--custom"
          placeholder="Your Job Title"
          value={formData.serviceName}
          onChange={handleSelectService}
          onInputChange={handleSingleService}
        />
      </div>

      <div className="form--group--login text-start">
        <label
        //  htmlFor={`service-category-${index}`}
         >
          Choose a Category
        </label>
        <CreatableSelect
          required
          options={categoryOptions.map(cat => ({ label: cat.label, value: cat.value }))}
          isClearable
          isMulti
          className="select--custom multiple--select--overflow"
          placeholder="Choose Services"
          value={formData.category}
          onChange={handleSelectCategory}
          onInputChange={handleSingleCategory}
        />
      </div>
    </div>
  </div>
{/* ))} */}




                  
                  <div
                    onClick={allservices.count === 0 ? handleAddService : () => setCategoriesModal(true)}
                    className="form--group--login text-start add--more--category"
                  >
                    <p>{allservices.count > 0 ? 'Add more here' : 'Add more skills'} {allservices.count > 0 ? <IoEyeOutline /> : null}</p>
                  </div>
                  {/* add category */}
                  <div className="form--group--login1 accept--terms">
                    <div className="checkbox--custom mb-0">
                      <label
                        htmlFor={`checkbox-terms`}
                        style={{ cursor: "pointer" }}
                      >
                        Read All Terms And Condition
                      </label>

                      <input
                        type="checkbox"
                        id={`checkbox-terms`}
                        value={formData.termsandConditon}
                        onClick={handleTermsCheckbox}
                        checked={formData.termsandConditon === true}
                        required
                      />
                      <span className="checkmark"></span>
                    </div>
                  </div>

                  <div className="form--group--login1 accept--terms mb-0">
                    <div
                      className="checkbox--custom mb-0"
                      style={{ width: "100%", textAlign: "left" }}
                    >
                      <label htmlFor="smsCheckbox" style={{ cursor: "pointer" }} className="wordkeep">
                        I consent to receive SMS updates and notifications from
                        SideHustl.
                      </label>

                      <input type="checkbox" id="smsCheckbox" required />
                      <span className="checkmark"></span>
                    </div>
                  </div>

                  <div className="form--group--login1 accept--terms ">
                  <div className="checkbox--custom" style={{ width: "100%", textAlign: "left" }}>
  <label htmlFor="consentCheckbox" style={{ cursor: "pointer" }} className="wordkeep">
  I consent to receive marketing emails and SMS notifications about updates, offers, and promotions.
  </label>
  <input 
    type="checkbox" 
    id="consentCheckbox" 
    checked={consent} 
    onChange={handleCheckboxChange} 
  />
  <span className="checkmark"></span>
</div>

      </div>

                </>
              )}

              <div
                className="form--group--login1 accept--terms"
                style={{ justifyContent: "center", gap: "10px" }}
              >
                {step === 1 ? null : (
                  <>
                    <button
                      type="button"
                      className="custom--btn ligghtbtn-hover transparent--btn"
                      onClick={handlePrevious}
                    >
                      <span>Previous</span>
                    </button>
                  </>
                )}
                <button type="submit" className="custom--btn darkbtn-hover">
                  <span>{step === 2 ? "Submit" : "Next"}</span>
                </button>
              </div>

              <p className="text-center dispri">
                Already have an account?{" "}
                <Link to="/login"state={{ id: "hustler" }} >
                  <b>Login here</b>
                </Link>
              </p>
            </form>
          )}

          {showWindow.verifyOtp && (
            <>
              <form onSubmit={handleVerifyOtp}>
                <h2 className="mb-3 mb-4" style={{ textAlign: "center" }}>
                  Verify One Time Password
                </h2>
                <div
                  className="form--group--login"
                  style={{ alignItems: "center" }}
                >
                  <label htmlFor="Password"></label>
                  <input
                    type="number"
                    name="otp"
                    placeholder="Enter One Time Password"
                    value={formData.otp}
                    onChange={handleChange}
                    required
                    style={{ width: "100%" }}
                  />

                  <button type="submit" className="custom--btn darkbtn-hover">
                    <span>Verify</span>
                  </button>
                </div>
              </form>

              <p
                onClick={handleReSendOtp}
                className="resend--password"
                style={{
                  cursor: "pointer",
                  fontWeight: "600",
                  textDecoration: "underline",
                  fontSize: "14px",
                  textAlign: 'center',
                  width: '100%'
                }}
              >
                Resend Code
              </p>

              <p style={{fontSize:'14px',marginBottom:'0px',textAlign: 'center', width: '100%'}}>Please verify your <b>email/SMS</b> one time password.</p>
            </>
          )}

          {showWindow.insertPassword && (
            <form onSubmit={handleSetPassword}>
              <h2 className="mb-3 mb-4" style={{ textAlign: "center" }}>
                Enter Password
              </h2>
              <div className="form--group--login">
                <label htmlFor="Password"></label>
                <div className="input-password relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Enter Password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                  <div
                    className="passwod--visibitly"
                    onClick={() => handleTogglePasswordVisibility("password")}
                  >
                    {showPassword ? <PiEyeLight /> : <PiEyeSlash />}
                  </div>
                </div>
              </div>
              <div
                className="form--group--login"
                style={{ alignItems: "center" }}
              >
                <div
                  className="input-password relative"
                  style={{ width: "100%" }}
                >
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmpassword"
                    placeholder="Enter Confirm Password"
                    value={formData.confirmpassword}
                    onChange={handleChange}
                    required
                    style={{ width: "100%" }}
                  />
                  <div
                    className="passwod--visibitly"
                    onClick={() =>
                      handleTogglePasswordVisibility("confirmPassword")
                    }
                  >
                    {showConfirmPassword ? <PiEyeLight /> : <PiEyeSlash />}
                  </div>
                </div>
                <button type="submit" className="custom--btn darkbtn-hover">
                  <span>Submit</span>
                </button>
              </div>

              <ul className="password--rules">
                <li className={`${hasValidLength && 'active'}`} >
                  {hasValidLength && hasValidLength === true ? <FaCheck className="check" /> : <IoClose className="close" />} Be a miminum of 8 characters
                </li>
                <li className={`${hasNumber && 'active'}`}>
                  {hasNumber && hasNumber === true ? <FaCheck className="check" /> : <IoClose className="close" />} Include at least one number (0-9)
                </li>
                <li className={`${hasUpperCase && 'active'}`}>
                  {hasUpperCase && hasUpperCase === true ? <FaCheck className="check" /> : <IoClose className="close" />} Include at least one uppercase letter (A-Z)
                </li>
                <li className={`${hasSpecialCharacter && 'active'}`}>
                  {hasSpecialCharacter && hasSpecialCharacter === true ? <FaCheck className="check" /> : <IoClose className="close" />} Include at least one special characters (!@#$%^&*)
                </li>
              </ul>
            </form>
          )}
        </div>

        {showTermsandConditonModel && (
          <TermsandConditonModel
            setformData={setformData}
            setshowTermsandConditonModel={setshowTermsandConditonModel}
          />
        )}
      </div>

      {categoriesModal && (


        allservices.count === 0 ? null : (

          <div className="modal--categories">
            <div className={`container--categories ${allservices.count > 3 ? 'custom--scroll' : ''}`}>
              {allservices.services.map((item, index) => (
                <div className="row--add--more" key={index}>
                  <div className="form--group--login text-start">
                    <CreatableSelect
                      required
                      isClearable
                      className="select--custom"
                      options={taskCatgeory}
                      placeholder={`Your Job Title`}
                      value={item.serviceName}
                      onChange={(newValue) =>
                        handleAddServiceNameChange(newValue, index)
                      }
                      onInputChange={(inputValue, actionMeta) =>
                        handleInputAddServiceNameChange(inputValue, actionMeta, index)
                      }
                    />
                  </div>
                  <div className="form--group--login text-start">
                    <CreatableSelect
                      isMulti
                      required
                      isClearable
                      options={taskServices}
                      className="select--custom multiple--select--overflow"
                      placeholder={`Choose Services`}
                      value={item.category}
                      onChange={(newValue) =>
                        handleAddCategoryChange(newValue, index)
                      }
                      onInputChange={(inputValue, actionMeta) =>
                        handleInputAddCategoryChange(inputValue, actionMeta, index)
                      }
                    />
                  </div>
                  <span className="remove--service" onClick={() => handleRemoveService(index)}><IoCloseCircle /></span>
                </div>
              ))}
            </div>
            <p onClick={handleAddService} style={{ cursor: 'pointer', paddingTop: '10px', width: 'fit-content' }}>Add Category +</p>

            <div className="group--btn">
              <button className="custom--btn transparent--btn mini-btn" style={{ color: '#000' }} onClick={() => setCategoriesModal(false)}>Cancel</button>

              {allservices.count > 0 ? <button className="custom--btn mini-btn" onClick={() => setCategoriesModal(false)}>Add</button>
                : null}
            </div>
          </div>
        )

      )}

    </>


  );
};

export default IsLoadingHOC(HustlerSignup);
