import React, { createContext, useContext, useState } from "react";

// Create a context for the services
const ServiceContext = createContext();

// Create a provider component
export const ServiceProvider = ({ children }) => {
  const [services] = useState(
     [
        {
          id: "1",
          serviceName: "Appliance Help",
          category: [
            { label: "Installing new appliances", value: "Installing new appliances" },
            { label: "Troubleshooting and repairing common appliance issues", value: "Troubleshooting and repairing common appliance issues" },
            { label: "Appliance maintenance", value: "Appliance maintenance" },
            { label: "Disposing of old appliances", value: "Disposing of old appliances" }
          ]
        },
        {
          id: "2",
          serviceName: "Assembly & Installation",
          category: [
            { label: "Assembling furniture", value: "Assembling furniture" },
            { label: "Installing shelves, cabinets, and racks", value: "Installing shelves, cabinets, and racks" },
            { label: "Setting up fitness equipment", value: "Setting up fitness equipment" },
            { label: "Installing light fixtures or ceiling fans", value: "Installing light fixtures or ceiling fans" },
            { label: "Mounting TVs and other electronics", value: "Mounting TVs and other electronics" }
          ]
        },
        {
          id: "3",
          serviceName: "Cleaning Services",
          category: [
            { label: "Deep cleaning kitchens and bathrooms", value: "Deep cleaning kitchens and bathrooms" },
            { label: "Carpet and upholstery cleaning", value: "Carpet and upholstery cleaning" },
            { label: "Dusting, vacuuming, and mopping", value: "Dusting, vacuuming, and mopping" },
            { label: "Post-construction or renovation cleanup", value: "Post-construction or renovation cleanup" },
            { label: "Organizing and decluttering", value: "Organizing and decluttering" }
          ]
        },
        {
          id: "4",
          serviceName: "Curtain & Blind Setup",
          category: [
            { label: "Installing curtain rods and blinds", value: "Installing curtain rods and blinds" },
            { label: "Measuring and fitting custom window treatments", value: "Measuring and fitting custom window treatments" },
            { label: "Repairing broken blinds or shades", value: "Repairing broken blinds or shades" },
            { label: "Cleaning curtains and blinds", value: "Cleaning curtains and blinds" },
            { label: "Replacing or updating hardware", value: "Replacing or updating hardware" }
          ]
        },
        {
          id: "5",
          serviceName: "Deck & Outdoor Assistance",
          category: [
            { label: "Building or repairing decks and patios", value: "Building or repairing decks and patios" },
            { label: "Power washing outdoor surfaces", value: "Power washing outdoor surfaces" },
            { label: "Staining or painting decks", value: "Staining or painting decks" },
            { label: "Installing outdoor lighting", value: "Installing outdoor lighting" },
            { label: "Setting up outdoor furniture or gazebos", value: "Setting up outdoor furniture or gazebos" }
          ]
        },
        {
          id: "6",
          serviceName: "Electrical Help",
          category: [
            { label: "Installing or replacing light fixtures", value: "Installing or replacing light fixtures" },
            { label: "Troubleshooting wiring issues", value: "Troubleshooting wiring issues" },
            { label: "Setting up smart home devices", value: "Setting up smart home devices" },
            { label: "Replacing outlets and switches", value: "Replacing outlets and switches" },
            { label: "Upgrading circuit breakers", value: "Upgrading circuit breakers" }
          ]
        },
        {
          id: "7",
          serviceName: "Errands and Personal Assistance",
          category: [
            { label: "Grocery shopping and delivery", value: "Grocery shopping and delivery" },
            { label: "Post office runs and package delivery", value: "Post office runs and package delivery" },
            { label: "Scheduling appointments and reservations", value: "Scheduling appointments and reservations" },
            { label: "Helping with event planning and setup", value: "Helping with event planning and setup" }
          ]
        },
        {
          id: "8",
          serviceName: "Furniture Moving & Packing",
          category: [
            { label: "Packing and labeling boxes for moving", value: "Packing and labeling boxes for moving" },
            { label: "Assembling and disassembling furniture", value: "Assembling and disassembling furniture" },
            { label: "Loading and unloading moving trucks", value: "Loading and unloading moving trucks" },
            { label: "Rearranging furniture within a home", value: "Rearranging furniture within a home" }
          ]
        },
        {
          id: "9",
          serviceName: "Garage Organization",
          category: [
            { label: "Installing shelving and storage systems", value: "Installing shelving and storage systems" },
            { label: "Decluttering and disposing of unwanted items", value: "Decluttering and disposing of unwanted items" },
            { label: "Cleaning and sweeping garages", value: "Cleaning and sweeping garages" },
            { label: "Setting up workbenches and tool racks", value: "Setting up workbenches and tool racks" },
            { label: "Organizing seasonal items like holiday decor", value: "Organizing seasonal items like holiday decor" }
          ]
        },
        {
          id: "10",
          serviceName: "Gutter Cleaning",
          category: [
            { label: "Removing leaves and debris from gutters", value: "Removing leaves and debris from gutters" },
            { label: "Inspecting for leaks or damage", value: "Inspecting for leaks or damage" },
            { label: "Installing gutter guards", value: "Installing gutter guards" },
            { label: "Clearing downspouts", value: "Clearing downspouts" },
            { label: "Seasonal gutter maintenance", value: "Seasonal gutter maintenance" }
          ]
        },
        {
          id: "11",
          serviceName: "Hanging & Mounting",
          category: [
            { label: "Hanging artwork and picture frames", value: "Hanging artwork and picture frames" },
            { label: "Mounting TVs and sound systems", value: "Mounting TVs and sound systems" },
            { label: "Installing mirrors securely", value: "Installing mirrors securely" },
            { label: "Setting up curtain rods and blinds", value: "Setting up curtain rods and blinds" },
            { label: "Hanging shelving units", value: "Hanging shelving units" }
          ]
        },
        {
          id: "12",
          serviceName: "Home Organization",
          category: [
            { label: "Organizing closets and wardrobes", value: "Organizing closets and wardrobes" },
            { label: "Setting up pantry storage systems", value: "Setting up pantry storage systems" },
            { label: "Arranging home offices", value: "Arranging home offices" },
            { label: "Sorting and organizing kids' playrooms", value: "Sorting and organizing kids' playrooms" },
            { label: "Creating filing systems for paperwork", value: "Creating filing systems for paperwork" }
          ]
        },
        {
          id: "13",
          serviceName: "Junk Removal",
          category: [
            { label: "Hauling away old furniture and appliances", value: "Hauling away old furniture and appliances" },
            { label: "Yard waste removal", value: "Yard waste removal" },
            { label: "Construction debris cleanup", value: "Construction debris cleanup" },
            { label: "Removing unwanted electronics and e-waste", value: "Removing unwanted electronics and e-waste" },
            { label: "Cleaning out basements, attics, or garages", value: "Cleaning out basements, attics, or garages" }
          ]
        },
        {
          id: "14",
          serviceName: "Minor Repairs",
          category: [
            { label: "Fixing leaky faucets", value: "Fixing leaky faucets" },
            { label: "Patching small holes in walls", value: "Patching small holes in walls" },
            { label: "Repairing doors or hinges", value: "Repairing doors or hinges" },
            { label: "Replacing broken tiles or grout", value: "Replacing broken tiles or grout" },
            { label: "Repairing screens or windows", value: "Repairing screens or windows" }
          ]
        },
        {
          id: "15",
          serviceName: "Painting",
          category: [
            { label: "Interior wall painting", value: "Interior wall painting" },
            { label: "Touch-up and trim painting", value: "Touch-up and trim painting" },
            { label: "Exterior painting for fences and siding", value: "Exterior painting for fences and siding" },
            { label: "Staining or painting decks and patios", value: "Staining or painting decks and patios" }
          ]
        },
        {
          id: "16",
          serviceName: "Plumbing",
          category: [
            { label: "Fixing leaks or clogs", value: "Fixing leaks or clogs" },
            { label: "Installing faucets, sinks, or toilets", value: "Installing faucets, sinks, or toilets" },
            { label: "Replacing water heaters", value: "Replacing water heaters" },
            { label: "Cleaning or replacing pipes", value: "Cleaning or replacing pipes" },
            { label: "Setting up irrigation  systems", value: "Setting up irrigation  systems"}
    
          ]
        },
        {
          id: "17",
          serviceName: "Power Washing",
          category: [
            { label: "Cleaning driveways and sidewalks", value: "Cleaning driveways and sidewalks" },
            { label: "Power washing exterior ", value: "Power washing exterior" },
            { label: "Cleaning decks and patios", value: "Cleaning decks and patios" },
            { label: "Removing graffiti", value: "Removing graffiti" },
            { label: "Cleaning fences or outdoor furniture", value: "Cleaning fences or outdoor furniture" }
          ]
        },
        {
          id: "18",
          serviceName: "Quick Fixes",
          category: [
            { label: "Replacing light bulbs and batteries", value: "Replacing light bulbs and batteries" },
            { label: "Tightening loose screws or handles", value: "Tightening loose screws or handles" },
            { label: "Fixing squeaky doors or cabinets", value: "Fixing squeaky doors or cabinets" },
            { label: "Securing loose floorboards", value: "Securing loose floorboards" },
            { label: "Adjusting misaligned doors or drawers", value: "Adjusting misaligned doors or drawers" }
    
          ]
        },
        {
          id: "19",
          serviceName: "Seasonal Jobs",
          category: [
            { label: "Installing and removing holiday lights", value: "Installing and removing holiday lights" },
            { label: "Cleaning and organizing seasonal decorations", value: "Cleaning and organizing seasonal decorations" },
            { label: "Preparing outdoor spaces for winter or summer", value: "Preparing outdoor spaces for winter or summer" },
            { label: "Seasonal garden planting or cleanup", value: "Seasonal garden planting or cleanup" },
            { label: "Winterizing pipes and outdoor equipment", value: "Winterizing pipes and outdoor equipment" }
          ]
        },
        {
          id: "20",
          serviceName: "Snow Removal",
          category: [
            { label: "Shoveling driveways and walkways", value: "Shoveling driveways and walkways" },
            { label: "Clearing snow from roofs", value: "Clearing snow from roofs" },
            { label: "Salting or de-icing paths", value: "Salting or de-icing paths" },
            { label: "Removing snow from vehicles", value: "Removing snow from vehicles" },
            { label: "Setting up snow removal equipment", value: "Setting up snow removal equipment" }
          ]
        },
        {
          id: "21",
          serviceName: "Window Cleaning",
          category: [
            { label: "Washing interior and exterior windows", value: "Washing interior and exterior windows" },
            { label: "Cleaning window tracks and frames", value: "Cleaning window tracks and frames" },
            { label: "Removing hard water stains", value: "Removing hard water stains" },
            { label: "Cleaning screens and storm windows", value: "Cleaning screens and storm windows" }
          ]
        },
        {
          id: "22",
          serviceName: "Yard Work",
          category: [
            { label: "Mowing lawns and edging", value: "Mowing lawns and edging" },
            { label: "Planting flowers, trees, or shrubs", value: "Planting flowers, trees, or shrubs" },
            { label: "Weeding and mulching garden beds", value: "Weeding and mulching garden beds" },
            { label: "Trimming hedges or pruning trees", value: "Trimming hedges or pruning trees" },
            { label: "Cleaning up leaves or storm debris", value: "Cleaning up leaves or storm debris" }
          ]
        }
        ]
      );

  return (
    <ServiceContext.Provider value={services}>
      {children}
    </ServiceContext.Provider>
  );
};

// Custom hook to use the ServiceContext
export const useServices = () => {
  return useContext(ServiceContext);
};
