import React from 'react'
import WhyHustler from '../component/WhyHustler'
import { Link } from 'react-router-dom'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Marquee from "react-fast-marquee";

const AboutUs = () => {

    const investors = [
        { id: 1, name: 'Investor 1', image: './assets/images/investors/investor-1.png' },
        { id: 2, name: 'Investor 2', image: './assets/images/investors/investor-2.png' },
        { id: 3, name: 'Investor 1', image: './assets/images/investors/investor-1.png' },
        { id: 4, name: 'Investor 2', image: './assets/images/investors/investor-2.png' },
        { id: 5, name: 'Investor 1', image: './assets/images/investors/investor-1.png' },
        { id: 6, name: 'Investor 2', image: './assets/images/investors/investor-2.png' },
        { id: 7, name: 'Investor 1', image: './assets/images/investors/investor-1.png' },
        { id: 8, name: 'Investor 2', image: './assets/images/investors/investor-2.png' },
        { id: 9, name: 'Investor 1', image: './assets/images/investors/investor-1.png' },
        { id: 10, name: 'Investor 2', image: './assets/images/investors/investor-2.png' },
        { id: 11, name: 'Investor 1', image: './assets/images/investors/investor-1.png' },
        { id: 12, name: 'Investor 2', image: './assets/images/investors/investor-2.png' },
        { id: 13, name: 'Investor 1', image: './assets/images/investors/investor-1.png' },
        { id: 14, name: 'Investor 2', image: './assets/images/investors/investor-2.png' },
        { id: 15, name: 'Investor 1', image: './assets/images/investors/investor-1.png' },
        { id: 16, name: 'Investor 2', image: './assets/images/investors/investor-2.png' },
    ];

    return (
        <>
            <section
                className="banner--top--about"
                style={{ position: "relative", minHeight: 'clamp(400px, 27vw, 500px)', paddingTop: 'clamp(200px, 12vw, 230px)' }}
            >
                <div
                    className="container"
                    style={{ zIndex: "2", position: "relative" }}
                >
                    <div className="row">
                        <div className="about--us--banner--content">
                            <h1
                                className="playFairDisplay text-center heading-h1 mb-4"
                                data-aos="fade-up"
                                data-aos-duration="1000"
                            >
About SideHustl
</h1>


                            <h5
                                className="text-center text-white mb-20px"
                                data-aos="fade-up"
                                data-aos-offset="300"
                                data-aos-duration="1300"
                                
                            >
Streamlining the gig economy by linking tasks with qualified individuals. 
</h5>
                        </div>
                    </div>
                </div>
            </section>
            {/* <hr style={{ borderTop: "10px solid #fff", margin: "20px 0" }} /> */}

            <section className='about--content '>
            {/* <hr style={{ borderTop: "10px solid #FFFFF", margin: "20px 0" }} /> */}

                {/* <h2
                    className='playFairDisplay heading-h2 text-center mb-4'
                    data-aos="fade-up"
                    data-aos-duration="1200"
                >
What is SideHustl? 
</h2> */}
<h5
                className="playFairDisplay 
                text-left 
                 mb-4 mt-25"
                 data-aos="fade-up"
                 data-aos-duration="1000"
             
                 
              >
What is SideHustl? 
              </h5> 

                <p
    className='text-justify'
    data-aos="fade-up"
    data-aos-duration="1000"
>
SideHustl connects busy individuals, professionals, and anyone seeking task completion (
    <a href="https://www.sidehustl.ca/become-a-creatr" target="_blank" rel="noopener noreferrer">
        Creatrs
    </a>
    )
    with skilled and reliable workers who are ready to take on jobs, big or small (
    <a href="https://www.sidehustl.ca/become-a-hustlr/" target="_blank" rel="noopener noreferrer">
        Hustlrs
    </a>
    ).
    </p>
    <p
        data-aos="fade-up"
        data-aos-duration="1000"
    
    >
    From household chores to business support, SideHustl is the platform that matches the right person, to the right job, at the right price.</p>


    {/* <hr style={{ borderTop: "10px solid #FFFFF", margin: "20px 0" }} /> */}
    {/* <hr style={{ borderTop: "10px solid #FFFFF", margin: "35px 0" }} /> */}


                {/* <h2
                    className='playFairDisplay heading-h2 text-center mb-3'
                    data-aos="fade-up"
                    data-aos-duration="1200"
                >
The SideHustl Way
</h2> */}

 <h5
                className="playFairDisplay 
                text-left 
                 mb-4 mt-0"
                 data-aos="fade-up"
                 data-aos-duration="1000"
             
                 
              >
                The SideHustl Way

              </h5> 
              
              {/* <h2 className='playFairDisplay heading-h2 text-center' 
              data-aos="fade-up" 
              data-aos-delay="100" 
              data-aos-duration="1000">The SideHustl Way
</h2> */}


                <p
                    className='text-justify'
                    data-aos="fade-up"
                    data-aos-delay="10" 

                    data-aos-duration="1000"
                >
A side hustle is paid work to supplement one's primary job. We exist to help our communities bring their side hustle dreams to reality by helping to bring people and skills together.
</p>
<p
                    className='text-justify'
                    data-aos="fade-up"
                    data-aos-delay="10" 

                    data-aos-duration="1000"
                >
Whether you’re a Creatr wishing to check looming tasks off of a to-do list, or a Hustlr willing to make an extra hundred bucks on a Saturday, SideHustl makes it happen with:
</p>
<div
  data-aos="fade-up"
  data-aos-delay="10" 

  data-aos-duration="1000"

>
                <ul                     
>
                    <li style={{ marginTop: '10px' }}><strong>Effortless Connections:</strong>  We make it easy to find the right match whether you’re offloading tasks or offering your time and energy with our unique bidding process.</li>
                    <li style={{ marginTop: '10px' }}><strong>Trusted Interactions:</strong>  Our platform is built on trust, with verified profiles and reviews to ensure a safe and reliable experience you would recommend to friends and family.</li>
                    <li style={{ marginTop: '10px' }}><strong>An Accessible Community: </strong>  We believe in making opportunity accessible to everyone in a community where hard work is rewarded and freedom is possible.</li>
                </ul>
                </div>

                <hr style={{ borderTop: "10px solid #FFFFF", margin: "20px 0" }} />

                {/* <h2
                    className='playFairDisplay heading-h2 text-center mb-4'
                    data-aos="fade-up"
                    data-aos-delay="10" 

                    data-aos-duration="1000"
                >
How It All Started
</h2> */}
<h5
                className="playFairDisplay 
                text-left 
                 mb-4 mt-0"
                 data-aos="fade-up"
                 data-aos-duration="1000"
             
                 
              >
How It All Started

              </h5> 

                <p
                    className='text-justify'
                    data-aos="fade-up"
                    data-aos-delay="10" 

                    data-aos-duration="1200"
                >
When SideHustl’s founder, Dan, encountered the frustration of finding a cleaning company for his Kelowna summer home, he got curious about finding a better way to seek skilled professionals.             
   </p>
                <p
                    className='text-justify'
                    data-aos="fade-up"
                    data-aos-delay="10" 

                    data-aos-duration="1000"
                >
Inspired to bridge the gap between busy individuals and eager, hard-working hustlers, Dan envisioned a platform where odd jobs could be easily posted and completed.                </p>
                <p
                    className='text-justify'
                    data-aos="fade-up"
                    data-aos-delay="0" 
                    data-aos-duration="1000"
                >

Today, SideHustl is offering a seamless avenue for both Creatrs and Hustlrs to connect, ensuring that endless to-do lists are efficiently tackled by qualified and dedicated individuals looking to earn some extra cash on the side.
                </p>

                <hr style={{ borderTop: "10px solid #FFFFF", margin: "20px 0" }} />

                <h5
                className="playFairDisplay 
                text-left 
                 mb-4 mt-0"
                 data-aos="fade-up"
                 data-aos-duration="1000"
             
                 
              >
Who Pays What
              </h5> 

                {/* <h2
                    className='playFairDisplay heading-h2 text-center mb-4 '
                    data-aos="fade-up"
                    data-aos-delay="10" 

                    data-aos-duration="1000"
                >
Who Pays What
</h2> */}

                <p
                    className='text-justify'
                    data-aos="fade-up"
                    data-aos-delay="10" 

                    data-aos-duration="1000"
                >
SideHustl is built on the principle of value. That means it is free to join and no payment is exchanged until after the job is done. Here’s an example:
</p>
                <p
                    className='text-justify'
                    data-aos="fade-up"
                    data-aos-delay="10" 

                    data-aos-duration="1000"
                >
Chris, the Creatr, assigns the dump run they need done to Hunter, the Hustlr, for $40.  
</p>

<p
                    className='text-justify'
                    data-aos="fade-up"
                    data-aos-delay="10" 

                    data-aos-duration="1000"
                >
Once Hunter accepts the job, Chris’s credit card is charged $40 plus a small fee and held securely with Stripe while the job is in progress.</p>

                <p
                    className='text-justify'
                    data-aos="fade-up"
                    data-aos-delay="10" 

                    data-aos-duration="1000"
                >
Hunter completes the job to Chris’s satisfaction and receives the $40.
</p>
<p
                    className='text-justify'
                    data-aos="fade-up"
                    data-aos-delay="10" 

                    data-aos-duration="1000"
                >
Chris and Hunter leave a review on each other’s public profile and rate their experiences.
</p>


            </section>


            {/* <section>

                <div className='about--content'>
                    <h2
                        className='playFairDisplay heading-h2 text-center mb-4'
                        data-aos="fade-up"
                        data-aos-duration="1200"
                    >
                        SideHustl
                    </h2>

                    <p
                        className='text-justify'
                        data-aos="fade-up"
                        data-aos-duration="1300"
                    >
                        SideHustl connects busy individuals, professionals, and anyone seeking task completion (Creatrs) with skilled and reliable workers who are ready to take on jobs, big or small (Hustlrs). 


                    </p>

                    <p
                        className='text-justify'
                        data-aos="fade-up"
                        data-aos-duration="1350"
                    >
From household chores to business support, SideHustl is the platform that matches the right person, to the right job, at the right price.
</p>

                    <div
                        className='counter--item'
                        data-aos="fade-up"
                        data-aos-duration="1000"
                    >
                        <div className='item-counter'>
                            <h3 className='playFairDisplay'>1250+</h3>
                            <p>Happy Hustlrs</p>
                        </div>
                        <div className='item-counter'>
                            <h3 className='playFairDisplay'>5000+</h3>
                            <p>Happy Creatrs</p>
                        </div>
                        <div className='item-counter'>
                            <h3 className='playFairDisplay'>1250+</h3>
                            <p>Cities</p>
                        </div>
                    </div>

                    <h2
                        className='playFairDisplay heading-h2 text-center mb-4'
                        data-aos="fade-up"
                        data-aos-duration="1000"
                    >
                        Our Journey
                    </h2>
                    <p
                        className='text-justify'
                        data-aos="fade-up"
                        data-aos-duration="1200"
                    >

                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</p>

                    <p
                        className='text-justify'
                        data-aos="fade-up"
                        data-aos-duration="1300"
                    >
                        Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
                    </p>

                </div>
            </section> */}

            {/* <section
                className="video--home--container py-50"
            >
                <div className="custom--container m-auto">
                    <div className="video--home--content">
                        <div className="video--content" style={{
                            marginTop: '0px',
                            padding: '0px'
                        }}>
                            <h2
                                className="text-white playFairDisplay heading-h1 text-center"
                                data-aos="fade-up"
                                data-aos-delay="300"
                                data-aos-duration="1300"
                            >
                                We are trying to make Everyday life easier.
                            </h2>
                            <div className="video--info flex">
                                <div
                                    className="v--info--box text-center"
                                    data-aos="fade-up"
                                    data-aos-delay="300"
                                    data-aos-duration="1800"
                                >
                                    <img src="./assets/images/secure.png" alt="cost" />
                                    <h4 className="text-white">Safety</h4>
                                    <p>We make sure your information and data are protected</p>
                                </div>
                                <div
                                    className="v--info--box text-center"
                                    data-aos="fade-up"
                                    data-aos-delay="300"
                                    data-aos-duration="2100"
                                >
                                    <img src="./assets/images/verify.png" alt="cost" />
                                    <h4 className="text-white">Quality</h4>
                                    <p>
                                        We stand behind our Hustlrs and ensure the job is well done
                                    </p>
                                </div>
                                <div
                                    className="v--info--box text-center"
                                    data-aos="fade-up"
                                    data-aos-delay="300"
                                    data-aos-duration="2100"
                                >
                                    <img src="./assets/images/community.png" alt="cost" />
                                    <h4 className="text-white">Community</h4>
                                    <p>
                                        We are giving you the freedom of choice. You get to choose
                                        who you want to work with and when
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <section className='our-investors py-100 overflow-hidden'>




            </section> */}
            {/* <section className="py-100" style={{ backgroundColor: "#f8f9fa" }}>
        <div className="custom--container m-auto text-center">
          <h3
            className="playFairDisplay heading-h3"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
</h3>
<h3
            className="playFairDisplay heading-h3"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
Join The SideHustl Community 
</h3>

<div  
            data-aos-offset="300"
            data-aos-duration="1500"

style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "0 auto",
              marginTop: "15px",
              gap: "20px"  

            }}>
          <Link
            to="/signup"
            className="custom--btn mt-25 darkbtn-hover"
            data-aos="fade-up"
            state={{ id: "director" }}
          >
            <span>Become a Creatr</span>
          </Link>
          <Link
            to="/signup"
            className="custom--btn mt-25 darkbtn-hover"
            data-aos="fade-up"
          >
            <span>Become a Hustlr</span>
          </Link>
          </div>

        </div>
      </section> */}

      <section className="py-100 pt-0 " style={{ backgroundColor: "#f8f9fa" }}>
        <div className="custom--container m-auto text-center" style={{paddingTop:"25px", marginTop:"25px"}}>
{/* <h5
            className="playFairDisplay heading-h2 "
            data-aos="fade-up"
            data-aos-duration="1000"
            style={{marginTop:"25px", marginBottom:"25px"}}

          >
Join The SideHustl Community 
</h5> */}
<h3 className='playFairDisplay' 
data-aos="fade-up"
data-aos-duration="1000"
style={{ fontSize: '28px', color: '#333', marginBottom:"25px"}}

> 

 Join The SideHustl Community 
 </h3>


<div  
            data-aos-offset="300"
            data-aos-duration="1500"

style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "0 auto",
              marginTop: "15px",
              gap: "20px"  // Added gap between buttons

            }}>
          <Link
            to="/signup"
            className="custom--btn  darkbtn-hover"
            data-aos="fade-up"
            // data-aos-offset="300"
            // data-aos-duration="1500"
            style={{
              // marginTop: "70px",
              marginBottom: "-40px"
            }}
            state={{ id: "director" }}

          >
            <span>Become a Creatr</span>


          </Link>
          <Link
            to="/signup"
            className="custom--btn  darkbtn-hover"
            data-aos="fade-up"
            // data-aos-offset="300"
            // data-aos-duration="1500"
            // style={{
            //   display: "flex",
            //   alignItems: "center",
            //   justifyContent: "center",
            //   margin: "0 auto",
            //   marginTop: "15px",
            // }}
            style={{
              marginBottom: "-40px"
            }}

          >
                        <span>Become a Hustlr</span>

          </Link>
          </div>

        </div>
      </section>
        </>
    )
}

export default AboutUs