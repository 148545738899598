import React from "react";

const Pagination = ({ postsPerPage, totalPosts, currentPage, paginate }) => {
  const pageNumbers = [];
  

  for (let i = 0; i < Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i + 1);
  }

  const handlePageChange = (pageNumber) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    paginate(pageNumber);
  };
  return ( 
    <>
      {pageNumbers && pageNumbers.length>0 &&(
        <div className="pagination--container">
          <div
            className="pagination--buttons prev"
            onClick={() => handlePageChange(currentPage - 1)}
          >
            {currentPage === 1 ? (
              <>
                {/* <button
                type="button"
                className="custom--btn darkbtn-hover minbutton"
                disabled
              >
                <span>Prev</span>
              </button>
              */}

              </>
             
           
            ) : (
              <button
                type="button"
                className="custom--btn darkbtn-hover minbutton"
              >
                <span>Prev</span>
              </button>
            )}
          </div>
          {pageNumbers.length === currentPage ?
          //  ""
          <div>
           {currentPage} / {Math.ceil(totalPosts / postsPerPage)} pages
           </div>
           
           :
        
        ( <div className="numbers--pages">

          {/* <p>
              {currentPage} / { totalPosts/postsPerPage}
            </p> */}

            <p>
  {currentPage} / {Math.ceil(totalPosts / postsPerPage)} pages
</p>

            </div>)}

          <div
            className="pagination--buttons next"
            onClick={() => handlePageChange(currentPage + 1)}
          >
            {pageNumbers.length === currentPage ? (
              <> 
               
               {/* 
            
              <button
                type="button"
                className="custom--btn ligghtbtn-hover transparent--btn minbutton"
                disabled
              >
                <span>Next</span>
              </button>
              */}

              </>
            ) : (
              
              <button
                type="button"
                className="custom--btn ligghtbtn-hover transparent--btn minbutton"
              >
                <span>Next</span>
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Pagination;
