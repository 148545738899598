import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { saveUser, setAccessToken } from "../../Redux/Reducers/authSlice";
import IsLoadingHOC from "../../common/IsLoadingHOC";
import { withoutAuthAxios } from "./../../services/config";
import socketIOClient from "socket.io-client";
import ContactAdmin from "../../common/ContactAdmin";
import {
  saveServices,
  saveTaskOptions,
  CombineTaskOptions,
} from "../../Redux/Reducers/appSlice";
import { PiEyeLight, PiEyeSlash } from "react-icons/pi";
import {
  removeDuplicates,
  RemovingDuplicatesServicesandCategories,
  toCamelCase,
} from "../../utils/helper";
import { useLocation } from 'react-router-dom';

const ENDPOINT = process.env.REACT_APP_BASEURL;


function Login(props) {
  const location = useLocation();
  console.log(location,"location")
  const id = location.state?.id;
  console.log(id,"idLogin")

  const { setLoading } = props;
  const initialFormData = {
    email: "",
    password: "",
  };
  const socketRef = useRef();
  socketRef.current = socketIOClient(ENDPOINT);
  const token = useSelector((state) => state.auth.accessToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);
  const [showContactadminModel, setshowContactadminModel] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  useEffect(() => {
    socketRef.current = socketIOClient(ENDPOINT);
  }, []);

  const getTaskListData = async () => {
    setLoading(true);
    await withoutAuthAxios()
      .get(`/services/get-all-services`)
      .then((response) => {
        const resData = response?.data;
        setLoading(false);
        if (resData?.status == 1) {
          // dispatch(saveTaskOptions(resData?.data));

          const services = [];
          resData?.data.map((item) => {
            services.push({
              label: toCamelCase(item?.serviceName),
              value: toCamelCase(item?.serviceName),
            });
          });

          const category = [];

          resData?.data?.map((item) => {
            item?.category?.map((cat) => {
              category?.push(cat);
            });
          });

          dispatch(CombineTaskOptions(resData));

          dispatch(
            saveServices(RemovingDuplicatesServicesandCategories(services))
          );
          dispatch(
            saveTaskOptions(RemovingDuplicatesServicesandCategories(category))
          );
        } else {
          toast.error(resData?.message?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    getTaskListData();
    if (token) {
      // navigate("/home");
    }
  }, []);

  function matchesPath(path, redirectPath) {
    const regexPath = path.replace(/:[^\/]+/g, '[^/]+'); // Replace dynamic segments with regex wildcard
    const regex = new RegExp(`^${regexPath}$`); // Create a regex pattern for the path
    return regex.test(redirectPath); // Test the redirectPath against the regex
  }
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password.length < 8) {
      toast.error("Invalid password");
      return;
    }
    try {
      setLoading(true);
      const response = await withoutAuthAxios().post("/users/login", formData);
      const resData = response.data;
      setLoading(false);
      if (resData.status === 1) {
        const redirectPath = sessionStorage.getItem("redirectPath") || "/home";
        const redirectPath1 = sessionStorage.getItem("redirectPath1");
        if (redirectPath1 && redirectPath1 === "/home/incoming-jobs") {
          navigate(redirectPath1);
        }
        navigate("/home");
        if (resData.data.userType == "ADMIN") {
          toast.error("Invalid credentials");
        } 
        else{
          socketRef.current.emit("login", resData.data.userId);
          dispatch(saveUser(resData.data));
          dispatch(setAccessToken(resData.data.token));

          if (resData.data.userType == "HUSTLER") {
            localStorage.setItem("status", resData.data.status);

            localStorage.setItem(
              "Province",
              resData.data.hustlerDetails.province
            );
            localStorage.setItem(
              "CityName",
              JSON.stringify([resData.data.hustlerDetails.location])
            );
          }
          toast.success("Logged in successfully");
          setFormData(initialFormData);

          if (redirectPath === "/your-task-list/completed") {
            // Navigate to the path and pass state as a second argument
            navigate("/your-task-list", { state: { id: "completed-task" } });

            // Clear the stored path
            sessionStorage.removeItem("redirectPath");
          } else if (redirectPath === "/your-task-list/in-progress") {
            navigate("/your-task-list", { state: { id: "in-progress" } });
            sessionStorage.removeItem("redirectPath");
          } else if (redirectPath === "/your-task-list/in-closed") {
            navigate("/your-task-list", { state: { id: "in-closed" } });
            sessionStorage.removeItem("redirectPath");
          } else if (redirectPath === "/hustlr-jobs/completed-jobs") {
            navigate("/hustlr-jobs", { state: { id: "completed-jobs" } });
            sessionStorage.removeItem("redirectPath");
          } else if (redirectPath === "/hustlr-jobs/closed-jobs") {
            // alert("hi")
            navigate("/hustlr-jobs", { state: { id: "closed-jobs" } });
            sessionStorage.removeItem("redirectPath");
          } else if (redirectPath === "/hustlr-jobs/in-progress") {
            navigate("/hustlr-jobs", { state: { id: "upcoming" } });
            sessionStorage.removeItem("redirectPath");
          } else if (redirectPath === "/hustlr-jobs/cancelled-jobs") {
            navigate("/hustlr-jobs", { state: { id: "cancelled-jobs" } });
            sessionStorage.removeItem("redirectPath");
          }
          // =====================================
          else if (redirectPath === "/home/incoming-jobs") {
            navigate("/home", { state: { id: "incoming-jobs" } });
            sessionStorage.removeItem("redirectPath");
          } else if (redirectPath1 === "/home/incoming-jobs") {
            navigate("/home", { state: { id: "incoming-jobs" } });
            sessionStorage.removeItem("redirectPath");
          } else if (redirectPath === "/home/interested-jobs") {
            navigate("/home", { state: { id: "interested-jobs" } });
            sessionStorage.removeItem("redirectPath");
          } else if (redirectPath === "/home/saved-jobs") {
            navigate("/home", { state: { id: "saved-jobs" } });
            sessionStorage.removeItem("redirectPath");
          } 
          else if (redirectPath === "/home/new-jobs") {
            navigate("/home", { state: { id: "new-jobs" } });
            sessionStorage.removeItem("redirectPath");
          }
          else if (redirectPath === "/create-task") {
            if (resData.data.userType == "DIRECTOR"){
            navigate("/create-task");
            sessionStorage.removeItem("redirectPath");
            }
            else{
              navigate("/home");
              sessionStorage.removeItem("redirectPath");
            }
          }

          else if (redirectPath.startsWith("/task/")) {
            if (resData.data.userType == "DIRECTOR"){
              navigate(redirectPath);  // Correctly pass the variable

            }

            sessionStorage.removeItem("redirectPath");
          }
          else if (redirectPath.startsWith("/job-details/")) {
            if (resData.data.userType == "HUSTLER"){
              navigate(redirectPath);  // Correctly pass the variable

            }
            sessionStorage.removeItem("redirectPath");
          }
        else {
            sessionStorage.removeItem("redirectPath");

          }

        }
      }
    } catch (error) {
      if (error.response?.data?.errors?.[0]?.email) {
        toast.error(error.response?.data?.errors?.[0]?.email);
      } else {
        toast.error(error.response?.data?.message);
      }

      setLoading(false);
      if (
        error.response.data.message ==
        "Your account is frozen. Contact admin to reactivate your account."
      ) {
        setshowContactadminModel(true);
      } else {
      }
    }
  };

  const closeModal = () => {
    setshowContactadminModel(false);
  };

  return (
    <>
      <div className="login--container login--new-design">
        <div className="signup-form">
          <Link to="/" className="text-center">
            <img
              src="./assets/images/logo.png"
              class="mb-3 w-full logo--form"
              alt="logo"
            />
          </Link>
          <div className={`form--main--container`} id="container">
            <div className="form-container sign-in-container">
              <form onSubmit={handleSubmit}>
                <h2 className="text-center">Login</h2>
                <div className="form--group--login">
                  <label htmlFor="Email">Email</label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form--group--login">
                  <label htmlFor="Password">Password</label>
                  <div className="input-password">
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Enter your password"
                      value={formData.password}
                      onChange={handleChange}
                      required
                    />
                    <div
                      className="passwod--visibitly"
                      onClick={handleTogglePasswordVisibility}
                    >
                      {showPassword?<PiEyeLight />:<PiEyeSlash />}
                    </div>
                  </div>
                </div>
                <div className="forgot--password">
                  <Link to="/forgot-password">Forgot Password</Link>
                </div>
                <button type="submit" className="custom--btn darkbtn-hover">
                  <span>Login</span>
                </button>
                {/* {/ <p className='text-center dispri'>Don't have an account? <Link to="/signup" className='text-white'><b>Register here</b></Link></p> /} */}
              </form>
            </div>
            <div className="overlay-container">
              <div className="overlay">
                <div className="overlay-panel overlay-right">
                  <h4 className="mb-3">Don't have an account? Register here</h4>
                  {/* {/ <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> /} */}
                  <Link
                    to="/signup" 
                    state={{ id: id }}
                    className="btn-dark btn darkbtn-hover custom--btn"
                    id="signUp"
                  >
                    <span>Register here</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showContactadminModel && <ContactAdmin closeModal={closeModal} />}
    </>
  );
}

export default IsLoadingHOC(Login);
