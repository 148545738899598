import React from "react";
import { IoWarningOutline } from "react-icons/io5";

const WarningPopup = ({privacybreach}) => {
  return (
    <div className="warning--modal--container">
      <div className="warning--modal--box">
        <IoWarningOutline className="icon--warning" />
        <h2>Warning</h2>
        <p>
        {privacybreach ? privacybreach : "Please avoid sharing personal information or using any offensive language during conversations to ensure continued access to your account."}
        </p>
      </div>
    </div>
  );
};

export default WarningPopup;
