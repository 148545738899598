import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { useTour } from '../../contexts/TourContext';

import { AiOutlineCalendar } from "react-icons/ai";
import { TbMoneybag } from "react-icons/tb";
import { GrLocation } from "react-icons/gr";
import { BiTimeFive } from "react-icons/bi";
import IsLoadingHOC from "../../common/IsLoadingHOC";
import { toast } from "react-toastify";
import { authAxios } from "../../services/config";
import { setReportFormatDate } from "../../assets/Helper";
import { IoIosPeople } from "react-icons/io";
import moment from "moment";
import DeleteCreatedTask from "./DeleteTask";
import {
  MdPublic ,
  MdEmojiPeople,
  MdOutlineRemoveRedEye,
  MdReviews,
} from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsFillChatLeftTextFill, BsListTask } from "react-icons/bs";
import EditcreateTask from "./EditcreateTask";
import ConfirmProgressTask from "./ConfirmProgressTask";
import AddReviews from "../../common/AddReviews";
import HustlerAddReview from "../../common/HustlerAddReview";
import DeleteProgressTaskModal from "../../common/Modal/DeleteProgressTaskModal";
import { IoCheckmarkCircle, IoCloseCircleSharp ,IoEyeSharp} from "react-icons/io5";
import Pagination from "../../common/Pagination";
import { setParticipantDetails } from "../../Redux/Reducers/chatSlice";
import { useDispatch } from "react-redux";
import DirectorDeniedJobModel from "../../common/Modal/DirectorDeniedJobModel";
import {
  BudgetFormat,
  handleDecimalNumber,
  payloadBudget,
  serviceFeePercentage,
} from "../../utils/helper";
import { useSocket } from "../../contexts/SocketContext";
import { GoArrowRight } from "react-icons/go";
import RejectedJobReason from "../become-a-hustler/RejectedJobReason";
import { IoIosCloseCircle, IoIosCloseCircleOutline } from "react-icons/io";
import { LuPartyPopper } from "react-icons/lu";
import PaymentForm from "./PaymentForm";
// import BeforePaymentForm from "./BeforePaymentForm";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import DirectorCounterOfferModel from "./DirectorCounterOfferModel";
import { PiEye } from "react-icons/pi";
import TaskCompletionRejectedModal from "./TaskCompletionRejectedModal";
import PublicJobModel from "../../common/PublicJobModel";
import DeclinedJobCompletedModal from "./DeclinedJobCompletedModal";
import ConfirmDeniedJobModel from "../../common/ConfirmDeniedJobModel";
import DirectorJobFrequently from "../../common/DirectorJobFrequently";
import EndSubsciption from "../../common/Modal/EndSubsciption";
import AnotherHustlrLists from "../../common/AnotherHustlrLists";
const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`
);


function DirectorJobsStart() {
  return (
<div
  key={1}
  className="created--task--lists process--task--list hover--effect"
  style={{ position: "relative" }}
>
  <div className="new--jobs-h">
    <span>
      <BsListTask /> New Jobs
    </span>
    <Link
      to="/task/123"
      style={{
        color: "#b0754e",
        textDecoration: "none",
      }}
    >
      Applicants: <b>1</b>
    </Link>
  </div>

  <div className="task--work">
    <div className="task--items--header">
      <div className="info--task--data">
        <Link
          to="/task/123"
          style={{
            color: "#212529",
            textDecoration: "none",
          }}
        >
          <h2 className="task--items--heading hover-text">I need my lawn mowing</h2>
        </Link>
        <div className="service--category--task">
          <h3>lawn mowing</h3>
        </div>
      </div>

      <div className="task--items--edit--delete info--data--button">
        <div className="button--lists-view">
          <button className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn">
            <span>
              <FiEdit /> Edit Job
            </span>
          </button>
          <button className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn">
            <span>
              <IoCloseCircleSharp /> Cancel Job
            </span>
          </button>
          <button className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn page-four-element">
            <span>
              <IoEyeSharp /> View Applicants
            </span>
          </button>
        </div>
      </div>
    </div>

    <div className="task--items--content">
      <p>
        <span className="task--items--content--icon">
          <AiOutlineCalendar />
        </span>
        Nov-19-24 to Nov-20-24
      </p>
      <p>
        <span className="task--items--content--icon">
          <TbMoneybag />
        </span>{" "}
        Budget: $65
      </p>
      <p>
        <span className="task--items--content--icon">
          <GrLocation />
        </span>{" "}
        Area: Kamloops
      </p>
    </div>

    <div className="task--items-footer">
        My lawn is 100 meter square 
    </div>
  </div>

  {/* <div className="task--items-down--footer new--jobs-h">
    <p>
      Please note that this is a <b>monthly</b> job, and payment will be made on
      a <b>monthly</b> basis upon completion.
    </p>
  </div> */}
</div>
  )
}

export default DirectorJobsStart
