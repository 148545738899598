import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { authAxios } from "../services/config";
import { toast } from "react-toastify";
import IsLoadingHOC from "../common/IsLoadingHOC";
import { BsCalendarEvent } from "react-icons/bs";
import { TbMoneybag } from "react-icons/tb";

const CreateTask = (props) => {
  const { setLoading } = props;

  const [startDate, setStartDate] = useState(new Date());

  const [data, setdata] = useState({
    title: "",
    description: "",
    location: "",
    budget: "",
    dueDate: `${startDate}`,
    attachments: "",
    status: "",
    img_url: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setdata((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, value } = e.target;

    const filename = e.target.files[0];

    setdata((prev) => ({
      ...prev,
      [name]: filename,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    /*
    const formData = new FormData();

    formData.append("title",data.title)
    formData.append("description",data.description)
    formData.append("location",data.location)
    formData.append("budget",data.budget)
    formData.append("dueDate",data.dueDate)
    formData.append("attachments",data.attachments)
    formData.append("status",data.status)
    formData.append("img_url",data.img_url)
    */

    setLoading(true);

    await authAxios()
      .post(`/task/create-tasks`, data)
      .then((response) => {
        setLoading(false);
        const resData = response.data;
        if (resData.status == 1) {
          toast.success(resData.message);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };

  return (
    <div className="create--task--container">
      <div className="inner-container">
        <div className="form--task--container">
          <div className="form--task--inner">
                <h1 className="mb-3 playFairDisplay heading-h1" data-aos="fade-up" data-aos-duration="900">Create Task</h1>
                <form onSubmit={handleSubmit} data-aos="fade-up" data-aos-duration="1300">
                  <div className="row g-3">
                    <div className="col-12">
                      <label for="your-name" className="form-label">
                        Title
                      </label>
                      <input
                        type="text"
                        name="title"
                        value={data.title}
                        onChange={handleChange}
                        className="form-control"
                        id="your-name"
                        required
                      />
                    </div>

                    <div className="col-md-6">
                      <label for="your-email" className="form-label">
                        Budget
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="your-email"
                        name="budget"
                        value={data.budget}
                        onChange={handleChange}
                        required
                      />
                      <div className="icon--input">
                      <TbMoneybag />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label for="your-subject" className="form-label">
                        Due Date
                      </label>
                      <br />

                      <DatePicker
                        className="form-control"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        required
                      />
                      <div className="icon--input">
                      <BsCalendarEvent />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label for="your-email" className="form-label">
                        Attachments
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        id="your-email"
                        name="attachments"
                        onChange={handleFileChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <label for="your-email" className="form-label">
                        ImgUrl
                      </label>
                      <input
                        type="file"
                        name="img_url"
                        onChange={handleFileChange}
                        className="form-control"
                        id="your-email"
                      />
                    </div>

                    <div className="col-md-12">
                      <label className="form-label d-block mb-2">Status</label>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="statusOpen"
                          name="status"
                          onChange={handleChange}
                          checked={data.status === "open"}
                          value="open"
                        />
                        <label className="form-check-label" htmlFor="statusOpen">
                          Open
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="statusClose"
                          name="status"
                          onChange={handleChange}
                          checked={data.status === "close"}
                          value="close"
                        />
                        <label className="form-check-label" htmlFor="statusClose">
                          Close
                        </label>
                      </div>
                    </div>

                    <div className="col-12">
                      <label for="your-message" className="form-label">
                        Description
                      </label>
                      <textarea
                        className="form-control"
                        id="your-message"
                        name="description"
                        value={data.description}
                        onChange={handleChange}
                        rows="5"
                        required
                      />
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-md-6">
                          <button
                            type="submit"
                            className="btn-dark btn darkbtn-hover custom--btn"
                          >
                            <span>Create Task</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
          </div>
        </div>
        <div className="conetnt--task--container">
          asdfasdf
        </div>
      </div>
    </div>
  );
};

export default IsLoadingHOC(CreateTask);
