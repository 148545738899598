const metadataConfig = {
    Landinghomepage: {
      title: "Find Same-Day Help with Odd Jobs – SideHustl",
      description:
        "Whether you need help with odd jobs or want to offer your services, SideHustl connects you with local hustlrs for same-day, efficient, and reliable assistance.",
      keywords: "odd jobs help, help with odd jobs, app to hire help for odd jobs, odd jobs help near me, local odd jobs help",
    },
    beforeHustlrPage: {
      title: "Become a Hustlr & Earn Extra Cash – SideHustl",
      description:
        "Earn extra cash with SideHustl by taking on flexible side hustles and odd jobs that fit your schedule. Ideal for boosting your income with simple tasks.",
      keywords: "earn extra cash, earn cash, ways to earn extra cash, how to earn extra cash",
    },
    beforeCreatrPage: {
      title: "Find Local Handyman, Moving Help, & Odd Jobs – SideHustl",
      description:
        "Use SideHustl to find a local handyman, offload your to-do list, and free up time with trusted Hustlrs ready to help. Simple, fast, and reliable.",
      keywords: "local handyman, local handyman services, same day handyman, same day moving help",
    },
  };
  
  export default metadataConfig;
  