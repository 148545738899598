// src/context/TourContext.js
import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Make sure to import useNavigate
import Notifications from '../common/Notifications';

const TourContext = createContext();

export const useTour = () => useContext(TourContext);

export const TourProvider = ({ children }) => {
  const navigate = useNavigate(); // Initialize the navigate function
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [isTourRunning, setIsTourRunning] = useState(false);

  // Define steps that span across three pages

  const steps = [
    { target: '.page-one-element', content: 'Welcome to Page One!', path: '/create-task' },
    { target: '.page-two-element', content: 'Here’s a feature on Page Two!', path: '/your-task-list',placement: 'top', },
    { target: '.page-three-element', content: 'three, this is Page Three!', path: '' },
    { target: '.page-four-element', content: 'four, this is Page Three!', path: '/your-task-list' },
    { target: '.page-five-element', content: 'five, this is Page Three!', path: '' },



  ];


  const startTour = () => {
    setCurrentStepIndex(0);
    setIsTourRunning(true);
    console.log(steps[0].path,"steps[0].path")
    navigate(steps[0].path); // Navigate to the first page
  };

  const nextStep = () => {
    const nextIndex = currentStepIndex + 1;
    if (nextIndex < steps.length) {
      setCurrentStepIndex(nextIndex);
      // console.log(steps[nextIndex].path,"steps[2].path")
      if(steps[nextIndex]?.path){
        navigate(steps[nextIndex]?.path); // Navigate to the next page

      }
    }
  };


  const resetTour = () => {
    setCurrentStepIndex(0);
    setIsTourRunning(false);
    navigate(steps[0].path);
  };

  return (
    <TourContext.Provider
      value={{ currentStepIndex, startTour, nextStep, resetTour, isTourRunning, steps }}
    >
      {children}

    </TourContext.Provider>
  );
};


// // src/context/TourContext.js
// import React, { createContext, useContext, useState } from 'react';

// const TourContext = createContext();

// export const useTour = () => useContext(TourContext);

// export const TourProvider = ({ children }) => {
//   const [currentStepIndex, setCurrentStepIndex] = useState(0);
//   const [isTourRunning, setIsTourRunning] = useState(false);

//   // Define steps that span across three pages
//   const steps = [
//     { target: '.page-one-element', content: 'Welcome to Page One!' },
//     { target: '.page-two-element', content: 'Here’s a feature on Page Two!' },
//     { target: '.page-three-element', content: 'Finally, this is Page Three!' },
//   ];

//   const startTour = () => {
//     setCurrentStepIndex(0);
//     setIsTourRunning(true);
//   };

//   const nextStep = () => setCurrentStepIndex((prev) => (prev + 1 < steps.length ? prev + 1 : prev));
//   const resetTour = () => {
//     setCurrentStepIndex(0);
//     setIsTourRunning(false);
//   };

//   return (
//     <TourContext.Provider
//       value={{ currentStepIndex, startTour, nextStep, resetTour, isTourRunning, steps }}
//     >
//       {children}
//     </TourContext.Provider>
//   );
// };
