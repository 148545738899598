import React, { useEffect, useState } from "react";
import { FaDollarSign } from "react-icons/fa";
import HustlerReviews from "./HustlerReviews";
import { BsChatRightText } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import { authAxios } from "../../services/config";
import { toast } from "react-toastify";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { useSelector } from "react-redux";
import ProfileReviews from "../../common/ProfileReview";

const HustlerProfileView = () => {
  const param = useParams();

  const { id } = param;
  const userType = useSelector((state) => state?.auth?.user?.userType)

  const [userDetail, setuserDetail] = useState([]);
  const [comments, setcomments] = useState([]);
  const [Favoritehustlers, setFavoritehustlers] = useState([]);

  const fetchuserDetail = async () => {
    await authAxios()
      .get(`users/get-specific-user/${id}`)
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          setuserDetail(resData?.data);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const fetchallReview = async () => {
    await authAxios()
      .get(`review/specific-user-reviews/${id}`)
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          setcomments(resData.data);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const CheckFavoriteHustler = async () => {
    await authAxios()
      .get(`/fav-hustler/get-fav-hustler`)
      .then((response) => {
        const resData = response?.data;
        setFavoritehustlers(resData?.data?.hustlers);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const handleToggleFavorite = async () => {
    const CheckFavorite = Favoritehustlers?.some(
      (item) => item?.user?.id === id
    );

    if (CheckFavorite) {
      await authAxios()
        .delete(`/fav-hustler/remove-favorites/${id}`)
        .then((response) => {
          const resData = response.data;
          CheckFavoriteHustler();
          toast.success(resData.message);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      await authAxios()
        .post(`/fav-hustler/add-to-fav/${id}`)
        .then((response) => {
          const resData = response.data;
          CheckFavoriteHustler();
          toast.success(resData.message);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };

  useEffect(() => {
    fetchuserDetail();
    fetchallReview();
    if (userType == "DIRECTOR") {
      CheckFavoriteHustler();
    }

  }, []);

  return (
    <>
      <div className="h-100px"></div>
      {userDetail.userType === "HUSTLER" ? (
        <section className="container--profile--view intersted--hustler--profile">
          <div className="profile--view--header">
            <div className="profile--info">
              <div className="thumbnail--profile">
                <div className="thumbnail--profile--image">
                  <img
                    src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${userDetail?.profilePic}`}
                    alt=""
                  />
                </div>
                <div className="thumbnail--info">
                  <h2 style={{ marginBottom: "0px" }}>
                    {userDetail?.fname} {userDetail?.lname}
                  </h2>
                  <p className="location--hustler">
                    {userDetail?.hustlerDetails?.province},{" "}
                    {userDetail?.hustlerDetails?.location}
                  </p>
                  <div>
                    <p className="search--category mb-1">
                      <img
                        src="/assets/images/hustler--taskdone.png"
                        width={15}
                      />{" "}
                      {userDetail?.hustlerDetails?.completedTask} Completed Jobs
                    </p>

                    <p className="starting--price mb-1">
                      <img src="/assets/images/cancel.png" width={15} /> {""}
                      {userDetail?.hustlerDetails?.cancelledTask || 0} Cancelled
                      Jobs
                    </p>
                    {/* <button onClick={handleAddFavoriteItem}>Add to Favorites</button> */}
                  </div>
                </div>
              </div>
            </div>
            {userDetail?.status=="active"?
            <div className="add--favorite">
              <button
                onClick={handleToggleFavorite}
                className="custom--btn ligghtbtn-hover transparent--btn minbutton"
              >
                <span>
                  {Favoritehustlers?.some((item) => item?.user?.id === id)
                    ? "Remove from Favorites"
                    : "Add to Favorites "}
                </span>
              </button>
              <Link
                to={`/hustlr-booking/${userDetail?._id}`}
                className="custom--btn btn-dark darkbtn-hover minbutton text-center"
              >
                <span>
                  Hire Now
                </span>
              </Link>
            </div>:
            <div className="profile--description mb-3">
                <h4 className="playFairDisplay">
                {"The Hustler is not active to take new jobs."}
                </h4>
              </div>
            
            }
          </div>

          <div className="profile--sarvice--category">
            {userDetail?.hustlerDetails?.services.length > 0 && userDetail?.hustlerDetails?.services.map((service, index, array) => (
              <span key={service._id}>{service.serviceName}{index !== array.length - 1 && ', '}</span>
            ))}
          </div>

          <ul className="category--child">
            {userDetail?.hustlerDetails?.services.map(service => (
              <>
                {service.category.map(category => (
                  <li key={category._id}>
                    {category.label}
                  </li>
                ))}
              </>
            ))}
          </ul>

          <div className="profile--content--area">
            <div className="profile--content--leftside" style={{width:'100%'}}>
              <div className="profile--description mb-3">
                <p>{userDetail.description}</p>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="container--profile--view intersted--hustler--profile">
          <div className="profile--view--header">
            <div className="profile--info">
              <div className="thumbnail--profile">
                <div className="thumbnail--profile--image">
                  <img
                    src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${userDetail?.profilePic}`}
                    alt=""
                  />
                </div>
                <div className="thumbnail--info">
                  <h2>
                    {userDetail?.fname} {userDetail?.lname}
                  </h2>
                  <p className="location--hustler">
                    {userDetail?.directorDetails?.province},{" "}
                    {userDetail?.directorDetails?.location}
                  </p>
                  <div>
                    <p className="search--category mb-1">
                      <img
                        src="/assets/images/hustler--taskdone.png"
                        width={15}
                      />{" "}
                      {userDetail?.directorDetails?.completedTask || 0}{" "}
                      Completed Jobs
                    </p>

                    <p className="starting--price mb-1">
                      <img src="/assets/images/cancel.png" width={15} /> {""}
                      {userDetail?.directorDetails?.cancelledTask || 0} Closed
                      Jobs
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="profile--content--area">
            <div className="profile--content--leftside">
              <div className="profile--description mb-3">
                <p>{userDetail.description}</p>
              </div>
            </div>
          </div>
        </section>
      )}
      <div className="custom--container m-auto">
        <ProfileReviews userDetail={userDetail} comments={comments} />
      </div>
    </>
  );
};

export default HustlerProfileView;