import React from "react";

const DeclinedJobCompletedModal = ({
    showDeclinedJobTaskModal,
    setshowDeclinedJobTaskModal,
}) => {
    return (
        <div>
            <div
                className={`deletemodal modal ${showDeclinedJobTaskModal ? "show" : ""}`}
                tabIndex="-1"
                role="dialog"
            >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header flex-column">
                            <h4 className="modal-title w-100 text-left">
                                Your Reason for Job not Completed
                            </h4>
                            <button
                                type="button"
                                className="close"
                                onClick={() =>
                                    setshowDeclinedJobTaskModal({ model: false, reason: "" })
                                }
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body pt-3 pb-4">
                            <ul className="list-group">
                                <li className="list-group-item">
                                    <strong>Your Reason :</strong> {showDeclinedJobTaskModal.reason}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`modal-backdrop ${showDeclinedJobTaskModal ? "show" : ""}`}
            ></div>
        </div>
    );
};

export default DeclinedJobCompletedModal;
