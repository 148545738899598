import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { authAxios } from "../../services/config";
import { toast } from "react-toastify";
import { IoIosCloseCircle } from "react-icons/io";
import { handleDecimalNumber, removeDuplicates } from "../../utils/helper";
import { Link, useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";

const FilterRightSide = ({
  userDetails,
  onRemoveActiveClass,
  handleTabClick,
}) => {
  const handleCloseButtonClick = () => {
    onRemoveActiveClass();
  };


  const [allSerivceCategory, setallSerivceCategory] = useState({
    serviceName: [],
    category: []
  })

  const location = useLocation();



  const handleSetServiceandCategory = () => {
    const ServiceArr = userDetails?.hustlerDetails?.services?.map((item) => item?.serviceName)

    const NewArr = []

    userDetails?.hustlerDetails?.services?.map((item) => {
      item?.category?.map((category) => {
        NewArr.push(category?.label)
      })
    })

    setallSerivceCategory((prev) => ({
      ...prev,
      serviceName: removeDuplicates(ServiceArr),
      category: removeDuplicates(NewArr)
    }))
  }

  useEffect(() => {
    handleSetServiceandCategory()

  }, [userDetails?.hustlerDetails?.services])



  return (
    <>
      <div className="close c-pointer" onClick={handleCloseButtonClick}>
        <IoIosCloseCircle />
      </div>
      <div className="your--area">
        <form>
          <div className="form-group mb-3">
            {/* <label htmlFor="exampleInputEmail1" className="mb-2">
              Category
            </label> */}
            <label className="mb-2 text--dark playFairDisplay" style={{ fontSize: '22px' }}>
              Your Skills
            </label>
            <div className="task--icon">
              <ul style={{
                listStyle: 'none',
                padding: '0px',
                display: 'flex',
                gap: '6px',
                flexWrap: 'wrap',
              }}>


                {allSerivceCategory && allSerivceCategory?.serviceName?.map((item, index) => (
                  <>
                    <li
                      key={index}
                      style={{
                        background: 'rgba(0, 0, 0, 0.0509803922)',
                        borderRadius: '50px',
                        padding: '5px 7px',
                        color: 'var(--darkblack)',
                        fontSize: '14px',
                        fontWeight: '500',
                      }}
                      data-tooltip-id={`tooltip-${index}`}
                    >
                      <strong>
                        {item}
                        {/* {item.length > 12 ? `${item.substring(0, 12)}...` : item} */}
                      </strong>
                    </li>
                    {item.length > 12 && (
                    <Tooltip id={`tooltip-${index}`} place="top" style={{ width: '210px'}}>
                      {item}
                    </Tooltip>
                    )}
                  </>
                ))}
              </ul>
            </div>
          </div>

          <div className="category--select--your">
            <label htmlFor="exampleInputEmail1" className="mb-2">
              Services
            </label>
            <ul>

              {/* 
              {userDetails.hustlerDetails?.category &&
                userDetails.hustlerDetails?.category.length > 0 &&
                userDetails.hustlerDetails?.category.map((item, index) => (
                  <li key={index + 1}>{item.label}</li>
                ))}

              {userDetails?.hustlerDetails?.services.map((item) => {
                return item?.category.map((category) => {
                  return <li key={category._id}>{category?.label}</li>;
                });
              })}
              */}

              {
                allSerivceCategory && allSerivceCategory?.category?.map((item, index) => (
                  <>
                    <li key={index + 1} data-tooltip-id={`services-tooltip-${index}`}>
                      {/* {item.length > 12 ? `${item.substring(0, 12)}...` : item} */}
                      {item}
                      </li>
                    
                    
                    {item.length > 12 && (
                    <Tooltip id={`services-tooltip-${index}`} place="top" style={{ width: '210px'}}>
                      {item}
                    </Tooltip>
                    )}
                  </>
                ))
              }


            </ul>
          </div>
        </form>
      </div>

      <div className="your--area">
        <label className="mb-2 text--dark playFairDisplay" style={{ fontSize: '22px' }}>
          Your Jobs
        </label>

        {/* <div
          className="task--status"
          onClick={() => handleTabClick("interested-jobs")}
          style={{ cursor: "pointer" }}
        >
          <div className="task--icon">
            <img src="/assets/images/interestshare.png" />
          </div>
          <div className="task--numbers">
            <p>Total Applied Jobs</p>
            <p>
              <strong>{userDetails?.hustlerDetails?.appliedTask}</strong>
            </p>
          </div>
        </div> */}

        {location.pathname === "/home" ? (
          <div
            className="task--status"
            onClick={() => handleTabClick("interested-jobs")}
            style={{ cursor: "pointer" }}
          >
            <div className="task--icon">
              <img src="/assets/images/interestshare.png" />
            </div>
            <div className="task--numbers">
              <p>Total Applied Jobs</p>
              <p>
                <strong>{userDetails?.hustlerDetails?.appliedTask}</strong>
              </p>
            </div>
          </div>
        ) : (
          <Link
            to="/home"
            state={{ id: "interested-jobs" }}
            className="task--status"
            style={{ cursor: "pointer", color: "#000", textDecoration: "none" }}
          >
            <div className="task--icon">
              <img src="/assets/images/interestshare.png" />
            </div>
            <div className="task--numbers">
              <p>Total Applied Jobs</p>
              <p>
                <strong>{userDetails?.hustlerDetails?.appliedTask}</strong>
              </p>
            </div>
          </Link>
        )}



        <Link
          to={{
            pathname: "/hustlr-jobs",
          }}
          state={{ id: "completed-jobs" }}
          className="task--status"
          style={{ cursor: "pointer", color: "#000", textDecoration: "none" }}
        >
          <div className="task--icon">
            <img src="/assets/images/completetask.png" />
          </div>
          <div className="task--numbers">
            <p>Completed Jobs</p>
            <p>
              <strong>{userDetails?.hustlerDetails?.completedTask}</strong>
            </p>
          </div>
        </Link>

        <Link
          to="/hustlr-jobs"
          state={{ id: "upcoming" }}
          className="task--status"
          style={{ cursor: "pointer", color: "#000", textDecoration: "none" }}
        >
          <div className="task--icon">
            <img src="/assets/images/pendingtask.png" />
          </div>
          <div className="task--numbers">
            <p>In Progress Jobs</p>
            <p>
              <strong>{userDetails?.hustlerDetails?.inProgressTask}</strong>
            </p>
          </div>
        </Link>

        <Link
          to="/hustlr-jobs"
          state={{ id: "cancelled-jobs" }}
          className="task--status"
          style={{ cursor: "pointer", color: "#000", textDecoration: "none" }}
        >
          <div className="task--icon">
            <img src="/assets/images/cancelled.png" />
          </div>
          <div className="task--numbers">
            <p>Cancelled Jobs</p>
            <p>
              <strong>{userDetails?.hustlerDetails?.cancelledTask}</strong>
            </p>
          </div>
        </Link>
      </div>

      <div className="your--area">
        {/*  
        <div className="task--status">
          <div className="task--icon">
            <img src="/assets/images/nexttask.png" />
          </div>
          <div className="task--numbers">
            <p>Next Task On</p>
            <p>
              <strong>12 Nov at 12:00 PM</strong>
            </p>
          </div>
        </div>
        */}

        <label className="mb-2 text--dark playFairDisplay" style={{ fontSize: '22px' }}>
          Your Rating
        </label>

        <div className="task--status">
          <div className="task--icon">
            <img src="/assets/images/ratingprofile.png" />
          </div>
          <div className="task--numbers">
            <p> Rating</p>
            <p>
              <strong>
                {handleDecimalNumber(userDetails?.hustlerDetails?.ratings)}
              </strong>
            </p>
          </div>
        </div>

        <div className="task--status">
          <div className="task--icon">
            <img src="/assets/images/ratingprofile.png" />
          </div>
          <div className="task--numbers">
            <p> Professionalism</p>
            <p>
              <strong>
                {handleDecimalNumber(userDetails?.hustlerDetails?.behaviorAvg)}
              </strong>
            </p>
          </div>
        </div>

        <div className="task--status">
          <div className="task--icon">
            <img src="/assets/images/ratingprofile.png" />
          </div>
          <div className="task--numbers">
            <p> Skills</p>
            <p>
              <strong>
                {handleDecimalNumber(userDetails?.hustlerDetails?.skillsAvg)}
              </strong>
            </p>
          </div>
        </div>

        <div className="task--status">
          <div className="task--icon">
            <img src="/assets/images/ratingprofile.png" />
          </div>
          <div className="task--numbers">
            <p> Communication</p>
            <p>
              <strong>
                {handleDecimalNumber(
                  userDetails?.hustlerDetails?.communicationAvg
                )}
              </strong>
            </p>
          </div>
        </div>

        <div className="task--status">
          <div className="task--icon">
            <img src="/assets/images/ratingprofile.png" />
          </div>
          <div className="task--numbers">
            <p> Availability</p>
            <p>
              <strong>
                {handleDecimalNumber(
                  userDetails?.hustlerDetails?.availabilityAvg
                )}
              </strong>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterRightSide;
