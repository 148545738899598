import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { RiDeleteBin6Line } from "react-icons/ri";
import {
  AiFillHeart,
  AiOutlineCalendar,
  AiOutlineHeart,
  AiOutlineInfoCircle,
} from "react-icons/ai";
import { TbMoneybag } from "react-icons/tb";
import { GrLocation } from "react-icons/gr";
import { GoArrowRight, GoCheckbox } from "react-icons/go";
import { Tooltip } from "react-tooltip";
const HustlerHomeSave = () => {
  const setReportFormatDate = (date) => {
    // Format your date here
    return new Date(date).toLocaleDateString();
  };

  // Static data for tasks
  const wishlistJobs = [
    {
      user: {
        _id: "1",
        fname: "John",
        lname: "Doe",
        profilePic: "john_profile_pic.jpg",
        directorDetails: {
          ratings: 4.5,
        },
      },
      title: "Task 1 Title",
      serviceName: "Service 1",
      dueDate: "2024-12-05",
      endDate: "2024-12-10",
      budget: 100,
      cityName: "New York",
      description: "This is a description for Task 1",
      applications: 5,
      oneTimePayment: false,
      subscriptionInterval: "Monthly",
    },
    {
      user: {
        _id: "2",
        fname: "Jane",
        lname: "Smith",
        profilePic: "jane_profile_pic.jpg",
        directorDetails: {
          ratings: 3.8,
        },
      },
      title: "Task 2 Title",
      serviceName: "Service 2",
      dueDate: "2024-12-15",
      endDate: "2024-12-20",
      budget: 200,
      cityName: "Los Angeles",
      description: "This is a description for Task 2",
      applications: 3,
      oneTimePayment: true,
      subscriptionInterval: "Weekly",
    },
    // Add more static tasks as needed
  ];

  return (
    <div className="list--tasks">
        <div
        //   key={index}
          className="created--task--lists process--task--list"
        >
          <div
            className="start--work"
            style={{ zIndex: "auto" }}
          >
            <span>
              <GoCheckbox /> Saved Job
            </span>
          </div>
          <div className="task--work">
            <div
              className="task--items--header"
              style={{ marginBottom: "0px" }}
            >
              <div className="task--add--info">
                <div className="user--task--info">
                  <div className="task--info--img">
                    <Link
                    //   to={`/user-info/${item?.user?._id}`}
                    >
                      <img
                        src="../assets/images/hustler-profile-img/hustler--profile-1.png"

                        // src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${item?.user?.profilePic}`}
                      />
                    </Link>
                  </div>
                  <div className="task--info--details">
                    <span>
                      CREATR MALIK
                      {/* {item?.user?.fname}{" "}
                      {item?.user?.lname}{" "} */}
                    </span>
                    <p>
                      <img 
                      src="../assets/images/hustler-star.png" 

                      />{" "}
                      5.0
                      {/* {handleDecimalNumber(
                        item?.user?.directorDetails?.ratings
                      )}{" "} */}
                    </p>
                  </div>
                </div>
                {/* <h3>
                  {item?.title}
                  </h3> */}
              </div>
              <div className="task--items--edit--delete">
                <Tooltip
                  id="remove-wishlist-task"
                  className="tooltip--task--wishlist"
                />
                <div
                  className="delete--taks--items"
                  data-tooltip-id="remove-wishlist-task"
                  data-tooltip-content="Remove from wishlist"
                //   onClick={() =>
                //     handleDeleteWishlistitem(item?.taskId)
                //   }
                >
                  <RiDeleteBin6Line />
                </div>
                <div className="edit--taks--items">
                  
                {
                // UserStatus == "inactive"
                true
                 ? 
                (
                  <div className="edit--taks--items">
                    <button
                      className="page-two custom--btn ligghtbtn-hover 
                    transparent--btn
                    "
                      data-tooltip-id="inactiveTooltip"

                      // onClick={() =>
                      //   handleAddShowInterestItem(item)
                      // }
                    >
                      <span>Show Interest </span>
                    </button>
                    <Tooltip
                      id="inactiveTooltip"
                      place="top"
                      effect="solid"
                    >
                      Your account is currently inactive. Please update <br/> your status to "active" in your profile to show interest.
                      {/* User is inactive, action disabled */}
                    </Tooltip>
                  </div>
                ):(
                  <>
                  <button
                    // onClick={() =>
                    //   handleAddShowInterestItem(item)
                    // }
                    className="page-two custom--btn ligghtbtn-hover transparent--btn"
                  >
                    <span>Show Interest </span>
                  </button>
                  </>
                )}
                </div>
              </div>
            </div>

            <div className="task--items--details">
              <div
                className="task--detail-full"
                style={{ width: "100%" }}
              >
                <h2>
                  I need my lawn mowing
                    {/* {item?.title} */}
                    </h2>
                <div className="service--category--task">
                  <h3>
                    lawn mowing
                    {/* {item?.serviceName} */}

                  </h3>
                </div>
              </div>
            </div>

            <div className="task--items--content">
              <p>
                <span className="task--items--content--icon">
                  <AiOutlineCalendar />
                </span>
                Nov-12-24 to Nov-16-24


                {/* {setReportFormatDate(item?.dueDate)} */}
                {/* { item?.endDate?
         (
          <> {setReportFormatDate(item?.dueDate)} to  {setReportFormatDate(item?.endDate)}</>)
         :(setReportFormatDate(item?.dueDate))
         } */}
              </p>
              <p>
                <span className="task--items--content--icon">
                  <TbMoneybag />
                </span>{" "}
                Budget: $ 50.00
                {/* {BudgetFormat(`${item?.budget}`)} */}
                {/* {item?.budget == 0?
               <>
                Budget: Open to Offer
                </>
                :
                <>

                Budget: $
                {BudgetFormat(
                  `${item?.budget}`
                )}
                </>
              } */}


                {/* | Members:{" "}
              {item?.members} */}
              </p>
              <p>
                <span className="task--items--content--icon">
                  <GrLocation />
                </span>{" "}
                Area: Kamloops
                {/* {item?.cityName} */}
              </p>
            </div>
            <div className="task--items-footer">
              <p>
                {/* {item?.description?.length > 250
                  ? `${item?.description?.substring(
                      0,
                      250
                    )}...`
                  : `${item?.description}`} */}
              </p>
            </div>
            <div className="task--items-application">
              <p>
                <img src="../assets/images/hustler--taskdone.png" />{" "}
                {/* {item?.applications} */}
                 Applications
              </p>
            </div>
          </div>
          {true
        //   item?.oneTimePayment 
          ? null : (
            <div className="task--items-down--footer start--work">
              <p>
                Please note that this is a{" "}
                {/* <b>{item?.subscriptionInterval}ly</b> job, */}
                and payment will be made on a{" "}
                {/* <b>{item?.subscriptionInterval}ly</b> basis */}
                upon completion.{" "}
              </p>
            </div>
          )}
        </div>
  </div>

  );
};

export default HustlerHomeSave;
