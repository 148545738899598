import React, { useEffect, useState } from "react";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { authAxios } from "../../services/config";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../common/IsLoadingHOC";
import { FaCheck, FaRegPaperPlane } from "react-icons/fa";
import { useSelector } from "react-redux";
import { setReportFormatDate } from "../../assets/Helper";
import { TbMoneybag } from "react-icons/tb";
import { AiOutlineCalendar } from "react-icons/ai";
import { GrLocation } from "react-icons/gr";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import {
  BudgetFormat,
  dateFormat,
  displayBudget,
  handleDecimalNumber,
} from "../../utils/helper";
import TaskMedia from "../../common/TaskMedia";
import { useDispatch } from "react-redux";
import { setParticipantDetails } from "../../Redux/Reducers/chatSlice";
import { IoIosArrowRoundBack, IoIosCloseCircle } from "react-icons/io";
import { IoCheckmarkCircle } from "react-icons/io5";
import { BiTimeFive } from "react-icons/bi";
import { GoArrowRight } from "react-icons/go";
import { BsFillChatLeftTextFill } from "react-icons/bs";
import moment from "moment";
import { FaEye } from 'react-icons/fa'; // Import Font Awesome icon
import {
  MdPublic ,
  MdEmojiPeople,
  MdOutlineRemoveRedEye,
  MdReviews,
} from "react-icons/md";

// import AddReviews from "../../common/AddReviews";
import AddReviews from "../../common/AddReviews";
import { useSocket } from "../../contexts/SocketContext";




const Taskpropsal = (props) => {
  const param = useParams();
  const socket = useSocket();

  const { setLoading } = props;
  const { id } = param;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showReviewModel, setshowReviewModel] = useState(false);
  const [showReviewModelH, setshowReviewModelH] = useState(false);
  const [taskReviewDetails, settaskReviewDetails] = useState({});

  const [allId, setallId] = useState({
    hustlerId: "",
    taskId: "",
    intervalId: "",
    subscriptionId: "",
    reason: "",
  });


  const goBack = () => {
    window.history.back();

    
//     if status:"open"
// /your-task-list
// if status: "assigned"

//      navigate("/your-task-list", { state: { id: "in-progress" } });

// if status: "completed"
//      navigate("/your-task-list", { state: { id: "completed-task" } });


  };
  const [isVisible, setIsVisible] = useState(true); // State to toggle visibility

  // Function to toggle visibility
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };


  const location = useLocation();
  const statusId = location?.state?.statusId;
  const prevBtn = location?.state?.id;


  const [appliedHustler, setappliedHustler] = useState([]);

  const [Taskdetails, setTaskdetails] = useState([]);

  const fetchTaskHustler = async () => {
    setLoading(true);
    await authAxios()
      .get(`/taskproposal/get-all-proposal/${id}`)
      .then((response) => {
        const resData = response.data;
        setLoading(false);
        if (resData.status == 1) {
          setappliedHustler(resData.data);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };

  
  const fetchTask = async () => {
    setLoading(true);
    await authAxios()
      .get(`task/get-specific-task/${id}`)
      .then((response) => {
        const resData = response.data;
        setLoading(false);
        if (resData.status == 1) {
          setTaskdetails(resData.data);
          settaskReviewDetails(resData.data)
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };


  const [review,setReview]=useState()
  const GetReview = async () => {
    setLoading(true);
    await authAxios()
      .get(`/review/get-user-review/${id}`)
      .then((response) => {
        const resData = response.data;
        setLoading(false);
        if (resData.status == 1) {
          setReview(resData.data)
          // setTaskdetails(resData.data);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };


  const handleSendMessage = (item) => {

    const payload = {
      participant_id: item?.hustlerId?._id,
      task_id: item?._id,
      profilePic: item?.hustlerId?.profilePic,
      participant_name: item?.hustlerId?.fname + " " + item?.hustlerId?.lname,
      showWelcomeMessage: true,
    };
    dispatch(setParticipantDetails(payload));
    navigate("/messages");
  };

  useEffect(() => {
    GetReview()
    fetchTaskHustler();
    fetchTask();
  }, [id]);


  const handleRedirect = () => {
    if (Taskdetails?.status === "open") {
      // Redirect to your task list
      navigate("/your-task-list");
    } else if (Taskdetails?.status === "assigned") {
      // Redirect to task list with 'in-progress' state
      navigate("/your-task-list", { state: { id: "in-progress" } });
    } else if (Taskdetails?.status === "completed") {
      // Redirect to task list with 'completed-task' state
      navigate("/your-task-list", { state: { id: "completed-task" } });
    }
  };
 

  const handleAddOpenModal = (item) => {
    setallId((prev) => ({
      ...prev,
      hustlerId: item?.hustlerId._id,
      taskId: item?._id,
    }));

    setshowReviewModel(true);
  };

  const handleEditReview = async (item) => {
    const payload = {
      rating: item.rating,
      behavior: item.behavior,
      availability: item.availability,
      skills: item.skills,
      communication: item.communication,
      comment: item.comment,
    };
    const taskId = item.taskId;
    setLoading(true);
    await authAxios()
      .put(`/review/update-review/${taskId}`, payload)
      .then((response) => {
        setLoading(false);
        const resData = response.data;
        if (resData.status == 1) {
          toast.success(resData?.message);
          // fetchCompletedtask(1);
          settaskReviewDetails([]);

          setshowReviewModel(false);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };
  const handleSubmitReview = async (item) => {
    // alert("hi")
    const payload = {
      reviewReceiver: allId.hustlerId,
      rating: item.rating,
      behavior: item.behavior,
      availability: item.availability,
      skills: item.skills,
      communication: item.communication,
      comment: item.comment,
      taskId: allId.taskId,
    };
    setLoading(true);
    await authAxios()
      .post("/review/create-review", payload)
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          socket.emit("directorReviewed", resData.data.review);
          setLoading(false);
          toast.success(resData?.message);
          // fetchCompletedtask(1);

          setshowReviewModel(false);
        } else {
          setLoading(false);
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);

        toast.error(error?.response?.data?.message);
      });
  };


  const handleViewOpenModal = (item) => {
    // console.log(item,"itemitem")
    fetchSpecificTaskReview(item?._id);
    setshowReviewModel(true);
  };

  const fetchSpecificTaskReview = async (id) => {
    setLoading(true);
    await authAxios()
      .get(`/review/specific-task-reviews/${id}`)
      .then((response) => {
        setLoading(false);
        const resData = response.data;
        if (resData.status == 1) {
          settaskReviewDetails(resData?.data[0]);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response?.data?.message);
      });
  };




  return (
    <>
      <div>
        <section
          className="director--search--hustler pt-117 hustler--home--page"
          style={{ minHeight: "400px" }}
        >
          <div className="custom--container m-auto pt-5 pb-5">
            <div className="container--filter">
              <div className="filter--data--search" style={{maxWidth: "100%"}}>
                <div className="task--lists--profile--based">
                  <div className="director--list--task">
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="upcoming"
                        role="tabpanel"
                        aria-labelledby="upcoming-tab"
                      >
                        <div
                          className={`list--tasks upper--task--list ${
                            prevBtn && "back--btn-active"
                          }`}
                        >
                          <div className="created--task--lists p-0 bg-white border-0" style={{width:'100%'}}>
                            {/* <button                              
                             onClick={goBack}
                            >
                            <IoIosArrowRoundBack /> Back

                            </button> */}
                            <div
                              className="flex"
                              style={{ minWidth: "150px" }}
                              // onClick={goBack}

                              onClick={() => {

                                if (Taskdetails?.status == "open") {
                                  navigate("/your-task-list");
                                } else if (Taskdetails?.status == "in-progress") {
                                  // navigate("/your-task-list/in-progress");

                                  navigate("/your-task-list", { state: { id: "in-progress" } })
                                } 
                                else if (Taskdetails?.status == "assigned") {
                                  // navigate("/your-task-list/in-progress");
                                  navigate("/your-task-list", { state: { id: "in-progress" } })
                                } 
                                else if (Taskdetails?.status == "completed") {
                                  navigate("/your-task-list", { state: { id: "completed-task" } });
                                }
                                else if (Taskdetails?.status == "close") {
                                  // navigate("/your-task-list", { state: { id: "completed-task" } });
                                  navigate("/your-task-list", { state: { id: "in-closed" } });

                                }
                              }}
                      
                            >
                              <button
                                className="custom--btn mb-3 darkbtn-hover mini-btn"
                                style={{ marginLeft: "auto" }}
                              >
                                <span>
                                  <IoIosArrowRoundBack /> Back
                                </span>
                              </button>
                            </div>


                            <h1
                              className="text--dark playFairDisplay heading-h1 mb-3"
                              style={{ fontSize: "clamp(35px, 3vw, 50px)", wordBreak:'keep-all' }}
                            >
                              {Taskdetails?.title}
                            </h1>
                            <div
                              className="task--items--header"
                              style={{
                                flexDirection: "initial",
                                flexWrap: "wrap",
                              }}
                            >
                              <h3>
                                {Taskdetails?.serviceName}
                                </h3>
                                
                              {/* {Taskdetails.serviceCategories &&
                                Taskdetails.serviceCategories.map(
                                  (item, index) => <h3 key={index}>{item}</h3>
                                )} */}

                              {/* <div className="task--items--edit--delete">
                                <div className="edit--taks--items flex">
                                  <button className="custom--btn">
                                    <span>
                                      <RiDeleteBin6Line />
                                    </span>
                                  </button>
                                  <button className="custom--btn">
                                    <span>
                                      <FiEdit />
                                    </span>
                                  </button>
                                </div>
                              </div> */}
                            </div>
                          </div>
                          {prevBtn && prevBtn === "view-task-hire" ? (
                            <div
                              className="flex"
                              style={{ minWidth: "150px" }}
                              onClick={goBack}
                            >
                              <button
                                className="custom--btn mb-3 darkbtn-hover mini-btn"
                                style={{ marginLeft: "auto" }}
                              >
                                <span>
                                  <IoIosArrowRoundBack /> Back
                                </span>
                              </button>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                            {/* //===================================== */}
                   
                    {review?.map((reviewItem, index)=>(        
                      <div id="reviews-profile">
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div></div>
                      <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}} >       
                      <FaEye
                          style={{ cursor: 'pointer', color: 'black' ,fontSize:"28px" }} // Black eye icon
                          onClick={toggleVisibility} // Click to toggle visibility
                        />
                        &nbsp;&nbsp;&nbsp;
                        {/* <h3>Review  hustler for this job</h3> */}
                        <h4> Hustlr Review</h4>
                       </div>

      </div>

      {/* Conditionally render the comments section */}

      {isVisible && (
        <div className="comments--profile--lists">
          <div className="reviews--section">
            {/* <div className="no--comments">
              <h5 style={{ textAlign: 'center', color: '#ada7a7' }}>No Reviews</h5>
            </div> */}

            <div className="user--comment--items">
              <div className="user--comment--header">
                <div className="user--comment--details">
                  <img
                    // src="profile_image_placeholder.jpg"
                    src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${reviewItem?.reviewSender?.profilePic}`}

                    alt=""
                    className="user--profile--img"
                    style={{
                      height: '80px',
                      width: '80px',
                      borderRadius: '10px',
                      objectFit: 'cover',
                    }}
                  />
                  <div className="user--comment--detail">
                    {/* <h3>John Doe</h3> */}
                    <h3>{`${reviewItem.reviewSender.fname} ${reviewItem.reviewSender.lname}`}</h3>

                    <span className="user--rating--comment">
                      <img src="/assets/images/hustler-star.png" alt="rating" />
                      {reviewItem.rating}

                    </span>
                    <span>
                    {" "}{" "}
                                {setReportFormatDate(reviewItem?.createdAt)}
                    {/* <span>{new Date(reviewItem.createdAt).toLocaleDateString()} */}

                    </span>

                  </div>
                </div>
                <div className="view--task--comment">
                  <span style={{ cursor: 'pointer' }}>
                    <svg width="24" height="24"></svg>
                  </span>
                </div>
              </div>

              <div className="user--comment--description">
                <p>{reviewItem?.comments}</p>
                {/* <span
                  className="read-more-toggle"
                  style={{
                    cursor: 'pointer',
                    color: '#1f1f28',
                    textDecoration: 'underline',
                    fontWeight: '600',
                  }}
                >
                  Read More
                </span> */}
              </div>
            </div>
          </div>
        </div>
      )}
    
    </div>))}


    




                          {/* /====================================================== */}

                         { Taskdetails?.status=="completed"?        <div>

                          <div className="task--items--edit--delete completed-jobs">
                              {Taskdetails?.directorReviewed ? (
                                <button
                                  onClick={() => handleViewOpenModal(Taskdetails)}
                                  className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                                >
                                  <span>
                                    <MdOutlineRemoveRedEye /> My Review
                                  </span>
                                </button>
                                
                              ) : (
                                <button
                                  // onClick={() => {handleAddOpenModal(Taskdetails)
                                  //   settaskReviewDetails(0)}}
                                    onClick={() => {
                                      handleAddOpenModal(Taskdetails);
                                      settaskReviewDetails(0);
                                   }}
                                
                                  className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                                >
                                  <span>
                                    <MdReviews /> Add Review 
                                  </span>
                                </button>
                              )}
                            </div>                          
                            </div>:""
                            }
                          {/* ============================================== */}




                    <ul
                      className="nav nav-pills mb-3"
                      id="pills-tab"
                      role="tablist"
                    ></ul>

                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="upcoming"
                        role="tabpanel"
                        aria-labelledby="upcoming-tab"
                      >
                        <div className="list--tasks">
                          <div className="created--task--lists bg-white border-0 p-0">
                            <div className="task--items-down--footer task--para pb-2 pt-4">
                              <p>
                                <span className="task--items--content--icon">
                                  <TbMoneybag />
                                </span>
                                {Taskdetails?.budget==0?
                                <>
                                 Budget: Open to Offer
                                
                                </>:
                                <>
                                                                Budget: $
                                {BudgetFormat(`${Taskdetails?.budget}`)}

                                </>
}
                                {/* | Members: 05 */}
                              </p>
                              {/* <p>
                                <span className="task--items--content--icon">
                                  <AiOutlineCalendar />
                                </span>
                                Job Date: {dateFormat(Taskdetails.dueDate)}
                              </p> */}

                            {/* {  Taskdetails.endDate?<p>
                                <span className="task--items--content--icon">
                                  <AiOutlineCalendar />
                                </span>
                                End Date: {dateFormat(Taskdetails.endDate)}
                              </p>
                              :""} */}
                              {Taskdetails.endDate ? (
  <p>
    <span className="task--items--content--icon">
      <AiOutlineCalendar />
    </span>
     Date Range: {`${dateFormat(Taskdetails.dueDate)} to ${dateFormat(Taskdetails.endDate)}`}
  </p>
) :                              (  

  <p>
    <span className="task--items--content--icon">
      <AiOutlineCalendar />
    </span>
    Job Date: {dateFormat(Taskdetails.dueDate)}  
    
    </p>

)
}


                              <p>
                                <span className="task--items--content--icon">
                                  <GrLocation className="location" />
                                </span>
                                Area: {Taskdetails?.cityName}
                              </p>
                            </div>
                          </div>

                          {Taskdetails?.finalPrice ||
                          Taskdetails?.updatedDate ? (
                            <div
                              className="created--task--lists border-0"
                              style={{
                                padding: "15px",
                                backgroundColor: "#f5f5f5",
                                marginBottom: "0px",
                              }}
                            >
                              <div className="task--items-down--footer task--para pt-0 final--biding">
                                {Taskdetails?.finalPrice && (
                                  <>
                                   {Taskdetails.oneTimePayment==true &&  
                                   
                                   <p
                                    className={`${
                                      Taskdetails?.updatedDate ? "" : "mb-0"
                                    }`}
                                  >
                                    <span className="task--items--content--icon">
                                    <AiOutlineCalendar />
                                    </span>
                                    <b>Final Date:</b>
                                  <span>{dateFormat(Taskdetails?.latestCounterOffer
                                          ?.finalDate?Taskdetails?.latestCounterOffer
                                          ?.finalDate:Taskdetails?.taskProposal?.proposalDate) }</span>
                                  </p> 
                                  }

                                  
                                 
                                  {/* <br/> */}

                                  <p
                                    className={`${
                                      Taskdetails?.updatedDate ? "" : "mb-0"
                                    }`}
                                  >
                                    <span className="task--items--content--icon">
                                      <TbMoneybag />
                                    </span>
                                    <b>Final Bid:</b> $
                                    {BudgetFormat(`${Taskdetails?.finalPrice}`)}
                                    {/* | Members: 05 */}
                                  </p>

                                 {Taskdetails?.stripePaymentAmount? <p
                                    className={`${
                                      Taskdetails?.updatedDate ? "" : "mb-0"
                                    }`}
                                  >
                                    <span className="task--items--content--icon">
                                      <TbMoneybag />
                                    </span>
                                    <b>Service (18%):</b> $
                                    {BudgetFormat(`${(Taskdetails?.stripePaymentAmount -Taskdetails?.finalPrice)?.toFixed(2)}`)}
                                    {/* | Members: 05 */}
                                  </p>:""
                                  }
                  {Taskdetails?.stripePaymentAmount?
                                  <p
                                    className={`${
                                      Taskdetails?.updatedDate ? "" : "mb-0"
                                    }`}
                                  >
                                    <span className="task--items--content--icon">
                                      <TbMoneybag />
                                    </span>
                                    <b>Total Amount:</b> $
                                    {BudgetFormat(`${(Taskdetails?.stripePaymentAmount).toFixed(2)}`)}
                                    {/* | Members: 05 */}
                                  </p>:""

                                  }


                                  </>
                                )}
                                
                              </div>
                            </div>
                          ) : null}

                          <TaskMedia imagePaths={Taskdetails?.attachments} />

                          <div className="created--task--lists p-0 border-0 bg-white pb-3 mb-0">
                            <div className="task--items-footer">
                              <h3
                                className="playFairDisplay"
                                style={{ fontSize: "22px" }}
                              >
                                Job Description:
                              </h3>
                              <p>{Taskdetails?.description}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {Taskdetails?.hustlerId !== null ? 
                    (
                      <>

                        {Taskdetails.taskCompletionId?.hustlerMarkedCompleted ||
                        Taskdetails.stripePaymentStatus === true ? (
                          <>
                            <div className="task--status">
                              <div className="haading--task--list pt-4">
                                <div className="task--items--header mb-0">
                                  {/* <h3>Hired Hustler</h3> */}
                                </div>
                              </div>
                              <div className="filter--data--value interested--hustlers--task">
                                <div className="search--list--items">
                                  <div className="header--search--list--items member--task--list">
                                    <div className="thumb--header--search--list--items">
                                      <Link
                                        to={`/user-info/${Taskdetails?.hustlerId?._id}`}
                                      >
                                        <img
                                          src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${Taskdetails?.hustlerId.profilePic}`}
                                          alt="Profile"
                                          style={{
                                            width: "65px",
                                            aspectRatio: 1,
                                            borderRadius: "10px",
                                            objectFit: "cover",
                                            objectPosition: "center",
                                          }}
                                        />
                                      </Link>
                                    </div>


                                    <div className="thumb--header--search--list--start">
                                      <div className="div-1">
                                        <h3 className="hustler--category" style={{display:'block'}}>
                                          <span>
                                            {" "}
                                            {Taskdetails?.hustlerId?.fname}{" "}
                                          </span>

                                          {Taskdetails?.hustlerId?.lname}
                                        </h3>
                                        <div className="thumb--header--search--list--middle">
                                          <p>
                                            <img src="/assets/images/hustler-star.png" />{" "}
                                            {handleDecimalNumber(
                                              Taskdetails?.hustlerId
                                                ?.hustlerDetails?.ratings
                                            )}
                                          </p>
                                          <p>
                                            <img src="/assets/images/hustler--taskdone.png" />{" "}
                                            {
                                              Taskdetails?.hustlerId
                                                ?.hustlerDetails?.completedTask
                                            }{" "}
                                            Completed Tasks
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="thumb--header--search--list--end">

                                      <p className="mb-0">
                                        <FaCheck style={{ color: "green" }} />{" "}
                                        Hired Hustlr
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}
                      </>
                    ) :
                     (
                      <>
                        <div className="tab-content" id="pills-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="upcoming"
                            role="tabpanel"
                            aria-labelledby="upcoming-tab"
                          >
                            <div className="list--tasks">
                              <div className="haading--task--list pt-4">
                                <div className="task--items--header mb-0">
                                  <h3>Available Applicants</h3>
                                  <p>Total: {appliedHustler?.length || 0}</p>
                                </div>
                              </div>
                              {/* name rating  members */}
                              {appliedHustler &&
                                appliedHustler.length > 0 &&
                                appliedHustler.map((item, index) => (
                                  <div
                                    className={`filter--data--value interested--hustlers--task ${
                                      item?.cancellation === null
                                        ? ""
                                        : "active--cancellation"
                                    }`}
                                    key={index}
                                  >
                                    <div className="search--list--items">
                                      <div className="header--search--list--items member--task--list">
                                        <div className="thumb--header--search--list--items">
                                          <img
                                            src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${item.userDetails.profilePic}`}
                                            alt="Profile"
                                            style={{ width: "65px" }}
                                          />
                                        </div>

                                        <div className="thumb--header--search--list--start">
                                          <div className="div-1">
                                            <h3 className="hustler--category" style={{display:'block'}}>
                                              <span>
                                                {" "}
                                                {item?.userDetails?.fname}{" "}
                                              </span>

                                              {item?.userDetails?.lname}
                                            </h3>
                                            <div className="thumb--header--search--list--middle">
                                              <p>
                                                <img src="/assets/images/hustler-star.png" />{" "}
                                                {handleDecimalNumber(
                                                  item?.userDetails
                                                    ?.hustlerDetails?.ratings
                                                )}
                                              </p>
                                              <p>
                                                <img src="/assets/images/hustler--taskdone.png" />{" "}
                                                {
                                                  item?.userDetails
                                                    ?.hustlerDetails
                                                    ?.completedTask
                                                }{" "}
                                                Completed Tasks
                                              </p>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="thumb--header--search--list--middle">
                                          <p>
                                            Bid: $
                                            {BudgetFormat(`${item?.offer}`)}
                                            {/* | Members: {item?.taskDetails?.members} */}
                                          </p>
                                        </div>

                                        { item?.userDetails?.status=="inactive"?   
                                        <div className="thumb--header--search--list--end thumb--header--search--list--proposal" style={{maxWidth:'211px !important'}}>

  {/* <Link
                                            // to={`/intersted-hustlr-profile/${item.hustlerId}/${id}`}
                                            // className="custom--btn ligghtbtn-hover transparent--btn text-center"
                                            style={{
                                              fontSize: "clamp(14px,2vw,16px)",
                                              paddingLeft: "10px",
                                              paddingRight: "10px",
                                            }}
                                          > */}
                                            <span>
                                              {/* <FaRegPaperPlane /> */}
                                              {" "}
                                              <h6 className="playFairDisplay">
                                              {"This hustler is not active"}
                                              </h6>
                                            </span>
                                          {/* </Link> */}
                                        </div>:

<div className="thumb--header--search--list--end thumb--header--search--list--proposal" style={{maxWidth:'211px !important'}}>
  <Link
    to={`/intersted-hustlr-profile/${item.hustlerId}/${id}`}
    className="custom--btn ligghtbtn-hover transparent--btn text-center"
    style={{
      fontSize: "clamp(14px,2vw,16px)",
      paddingLeft: "10px",
      paddingRight: "10px",
    }}
  >
    <span>
      <FaRegPaperPlane />{" "}
      {item?.cancellation === null
        ? "View Proposal"
        : "Rejected Proposal"}
    </span>
  </Link>
</div>

                                        
                                        }

                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {showReviewModel && (
        <AddReviews
          SensitiveWords={null}
          taskReviewDetails={taskReviewDetails}
          handleEditReview={handleEditReview}
          handleSubmitReview={handleSubmitReview}
          setshowReviewModel={setshowReviewModel}
          settaskReviewDetails={settaskReviewDetails}

        />
      )}

    </>
  );
};

export default IsLoadingHOC(Taskpropsal);
