import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { withoutAuthAxios, authAxios } from "../../services/config";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import IsLoadingHOC from "../../common/IsLoadingHOC";
import { State, City } from "country-state-city";
import Select from "react-select";
import { PiEyeLight, PiEyeSlash } from "react-icons/pi";
import TermsandConditonModel from "../../common/TermsandConditonModel";
import { FaCheck } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import usePasswordValidation from "../../common/usePasswordValidation";
// import { DirectorDescripion } from "../../utils/helper";
const DirectorSignup = (props) => {
  const { setLoading } = props;
  const navigate = useNavigate();
  const [provinceList, setprovinceList] = useState([]);
  const [cityList, setcityList] = useState([]);
  const [selectedProvince, setselectedProvince] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [stateCode, setStateCode] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showTermsandConditonModel, setshowTermsandConditonModel] =
    useState(false);
  const [showWindow, setshowWindow] = useState({
    basicDetails: true,
    verifyOtp: false,
    insertPassword: false,
  });
  const [formData, setformData] = useState({
    email: "",
    fname: "",
    lname: "",
    phoneno: "",
    password: "",
    confirmpassword: "",
    category: "",
    city: "",
    termsandConditon: false,
    otp: "",
    userType: "DIRECTOR",
  });

  const [consent, setConsent] = useState(false);
  
  const handleCheckboxChange = (event) => {
    setConsent(event.target.checked);
  };

  const handleTogglePasswordVisibility = (field) => {
    if (field === 'password') {
      setShowPassword((prevShowPassword) => !prevShowPassword);
    } else if (field === 'confirmPassword') {
      setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleNumber = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    if (sanitizedValue.match(/^[0-9]*$/)) {
      setformData((prev) => ({
        ...prev,
        [name]: sanitizedValue,
      }));
    }
  };
  const handlePaste = (e) => {
    const pastedText = e.clipboardData.getData("Text");
    
    // Remove + and anything after it if it's followed by a number
    let sanitizedValue = pastedText.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    
    // Check if the pasted number starts with the country code (e.g. +1)
    if (sanitizedValue.startsWith("1") && sanitizedValue.length > 10) {
      sanitizedValue = sanitizedValue.substring(1); // Remove the leading 1 (country code)
    }
  
    e.preventDefault(); // Prevent the default paste action
    
    // Update formData with the sanitized value
    setformData((prev) => ({
      ...prev,
      phoneno: sanitizedValue,
    }));
  };

  const handleTermsCheckbox = () => {
    if (formData.termsandConditon === true) {
      setformData((prev) => ({
        ...prev,
        termsandConditon: false,
      }));
      setshowTermsandConditonModel(true)
    } else {
      setformData((prev) => ({
        ...prev,
        termsandConditon: false,
      }));
      setshowTermsandConditonModel(true)
    }
  }


  const handleAlphabet=(e)=>{
    const { name, value } = e.target;
    // const regex = /^[A-Za-z ]*$/;
    const regex = name === "lname" ? /^[A-Za-z\- ]*$/ : /^[A-Za-z ]*$/;

  if(regex.test(value)){
    setformData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }
    

  }

  const handleMailChimp = (email,fname,lname,phoneno) => {
    // Create FormData object for Mailchimp
    const formData = new FormData();
    // formData.append('EMAIL', email);
    formData.append('EMAIL', email);

    // formData.append('MMERGE1', name);
    formData.append('MMERGE1', `${fname} ${lname}`);

    // formData.append('MMERGE1', fname,lname);

    // formData.append('MMERGE2', phone);
    formData.append('MMERGE2', phoneno);

    // formData.append('MMERGE3', subscribeDate);
    const currentDate = new Date();

// Extracting day, month, and year
const day = String(currentDate.getDate()).padStart(2, '0'); // Ensures day is 2 digits
const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
const year = currentDate.getFullYear();

// Append to formData as individual fields
formData.append('MMERGE3[day]', day);
formData.append('MMERGE3[month]', month);
formData.append('MMERGE3[year]', year);

formData.append('MMERGE4', 'DIRECTOR'); // Or dynamic value based on user input
formData.append('group[35460][1]', 'true');

    // if (userType.creator) {
    //   formData.append('group[35460][1]', 'true');
    // }
    // if (userType.hustler) {
    //   formData.append('group[35460][2]', 'true');
    // }
    // Submit the form using Fetch API

    fetch(
      'https://sidehustl.us11.list-manage.com/subscribe/post?u=a446787384fb0789d8393c9ec&id=57bfaab8f4&f_id=0067d0e3f0',
      {
        method: 'POST',
        body: formData,
        mode: 'no-cors',
      }
    )
      .then(() => {
        // alert('Subscription successful!');
      })
      .catch((err) => console.error('Error:', err));
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(formData.phoneno.length<10){
      toast.warn('Phone number must be 10 digits')
    }else{
      if(!formData.email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)){
        toast.warn('Enter Correct Email Address')
        return false
      }
      const payload = {
        fname: formData.fname,
        lname: formData.lname,
        province: selectedProvince?.label,
        location: selectedCity?.value,
        email: formData.email,
        phoneno: formData.phoneno,
        // description:  DirectorDescripion  ,
        userType: "DIRECTOR",
      };
  
     setLoading(true);
     await withoutAuthAxios()
       .post("/users/register", payload)
       .then((response) => {
         setLoading(false);
         const resData = response.data;
         if (resData.status == 1) {

           toast.success(resData.message);
           setshowWindow({
             basicDetails: false,
             verifyOtp: true,
             insertPassword: false,
           });
          //  handleMailChimp(formData.email)
           if (consent) {
            console.log(formData.email,"email checking")
            // handleMailChimp(formData.email)
            handleMailChimp(formData.email,formData.fname,formData.lname,formData.phoneno)

          } 


         } else {
           setLoading(false);
           toast.error(resData.message);
         }
       })
       .catch((error) => {
         setLoading(false)
         toast.error(error.response.data.message);
       });
    } 
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      email: formData.email,
      enteredOTP: Number(formData.otp),
    };
    await authAxios()
      .post(`/users/verify-otp`, payload)
      .then((response) => {
        const resData = response?.data;
        setLoading(false);
        if (resData.status == 1) {
          toast.success(resData?.message);
          setshowWindow({
            basicDetails: false,
            verifyOtp: false,
            insertPassword: true,
          });
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };
  const handleReSendOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      email: formData.email,
    };
    await authAxios()
      .post(`users/resend-otp`, payload)
      .then((response) => {
        const resData = response?.data;
        setLoading(false);
        if (resData.status == 1) {
          toast.success(resData?.message);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };
  const handleSetPassword = async (e) => {
    e.preventDefault();

    if (formData.password.length < 8 ||
      !/(?=.*[A-Z])/.test(formData.password) ||
      !/(?=.*[!@#$%^&*])/.test(formData.password) ||
      !/(?=.*[0-9])/.test(formData.password)) {
      toast.error("Password criteria do not match.");
      return;
    }


    if (formData.confirmpassword == formData.password) {
      const payload = {
        email: formData.email,
        password: formData.password,
        confirmPassword: formData.confirmpassword,
      };
      await authAxios()
        .post(`users/setup-password`, payload)
        .then((response) => {
          const resData = response?.data;
          setLoading(false);
          if (resData.status == 1) {
            toast.success(resData?.message);
            navigate(`/login`);
          } else {
            toast.error(resData?.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message);
        });
    } else {
      toast.error("The passwords entered do not match.");
    }
  };

  const handleSelectProvince = (e) => {
    if (e) {
      setStateCode(e?.value);
      setselectedProvince(e);
      setSelectedCity([]);
    } else {
      setcityList([]);
      setSelectedCity([]);
      setStateCode("");
      setselectedProvince([]);
    }
  };

  const handleSelectCity = (e) => {
    setformData((prev) => ({
      ...prev,
      city: e,
    }));
    setSelectedCity(e);
  };

  useEffect(() => {
    // setprovinceList(
    //   State.getStatesOfCountry("CA").map(({ isoCode, name }) => ({
    //     value: isoCode,
    //     label: name,
    //   }))
    // );
    setprovinceList(
      [ { value: "BC", label: "British Columbia" },
        {value: "SK",label: "Saskatchewan"}
     ]
      );
  }, []);

  useEffect(() => {
    setcityList([])
if(stateCode=="BC"){
    setcityList(
      [ {"value": "Kamloops", "label": "Kamloops"},
        {"value": "Kelowna","label": "Kelowna"},
        {"value": "Peachland","label": "Peachland"},
        {"value": "Penticton","label": "Penticton"},
        {"value": "Vernon","label": "Vernon"},
        {"value": "West Kelowna","label": "West Kelowna"},
      ]
    )
  }
  else{

    setcityList(
      [ {value: "Saskatoon",label: "Saskatoon"}
      ]
    )
  }


    if (stateCode) {
      // setcityList(
      //   City.getCitiesOfState("CA", stateCode).map(({ name }) => ({
      //     value: name,
      //     label: name,
      //   }))
      // );
    }
  }, [stateCode]);

  const {
    hasValidLength,
    hasNumber,
    hasUpperCase,
    hasSpecialCharacter
  } = usePasswordValidation(formData.password);

  return (
    <div style={{backgroundColor:"#fffff"}}>
      <div className={`form-container sign-in-container ${showWindow.verifyOtp === true ? 'verify--otp--container' : ''} ${showWindow.insertPassword === true ? 'password--confirm--container' : ''}`}>
        {showWindow.basicDetails && (
          <form onSubmit={handleSubmit}>
            <h2>Become a Creatr</h2>

            <div className="form--group--login">
              <label htmlFor="First Name">First Name</label>
              <input
                type="text"
                name="fname"
                placeholder="Enter first name"
                value={formData.fname}
                onChange={handleAlphabet}
                required
              />
            </div>
            <div className="form--group--login">
              <label htmlFor="Last Name">Last Name</label>
              <input
                type="text"
                name="lname"
                placeholder="Enter last name"
                value={formData.lname}
                onChange={handleAlphabet}
                required
              />
            </div>

            <div className="form--group--login text-start">
              <label htmlFor="Select your area">Select your area</label>
              <Select
                options={provinceList}
                isClearable={false}
                className="select--custom"
                placeholder={`Select your province`}
                value={selectedProvince}
                onChange={handleSelectProvince}
                required
              />
            </div>


            <div className="form--group--login text-start">
              <label htmlFor="Select your area">Select your area</label>
              <Select
                // options={cityList}
                options={selectedProvince?cityList:[]}

                isClearable
                className="select--custom"
                placeholder={`Select your city`}
                value={selectedCity}
                onChange={handleSelectCity}
                required
              />
            </div>

            <div className="form--group--login">
              <label htmlFor="Email">Email</label>
              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form--group--login">
              <label htmlFor="Phone Number">Phone Number</label>
              <input
                type="tel"
                name="phoneno"
                placeholder="Enter your phone number"
                value={formData.phoneno}
                onChange={handleNumber}
                onPaste={handlePaste} // Add the onPaste handler

                maxLength={10}
                required
              />
            </div>

            <div className="form--group--login1 accept--terms" >
              <div className="checkbox--custom mb-0">
                <label
                  htmlFor={`checkbox-terms`}
                  style={{ cursor: "pointer" }}
                >
                  Read Terms and Conditions.
                </label>

                <input
                  type="checkbox"
                  id={`checkbox-terms`}
                  value={formData.termsandConditon}
                  onClick={handleTermsCheckbox}
                  checked={formData.termsandConditon === true}
                  required
                />
                <span className="checkmark"></span>
              </div>
            </div>

            <div className="form--group--login1 accept--terms mb-0">
              <div className="checkbox--custom mb-0" style={{ width: '100%', textAlign:'left' }}>
                <label
                  htmlFor="smsCheckbox1"
                  style={{ cursor: "pointer" }}
                  className="wordkeep"
                >
                  I consent to receive SMS updates and notifications from SideHustl.
                </label>

                <input
                  type="checkbox"
                  id="smsCheckbox1"
                  required
                />
                <span className="checkmark"></span>
              </div>
            </div>

            <div className="form--group--login1 accept--terms">
                  <div className="checkbox--custom" style={{ width: "100%", textAlign: "left" }}>
  <label htmlFor="consentCheckbox" style={{ cursor: "pointer" }} className="wordkeep">
  I consent to receive marketing emails and SMS notifications about updates, offers, and promotions.
  </label>
  <input 
    type="checkbox" 
    id="consentCheckbox" 
    checked={consent} 
    onChange={handleCheckboxChange} 
  />
  <span className="checkmark"></span>
</div>

      </div>

            <button type="submit" className="custom--btn darkbtn-hover">
              <span>Sign Up</span>
            </button>
            <p className="text-center dispri">
              Already have an account?{" "}
              <Link to="/login">
                <b>Login here</b>
              </Link>
            </p>
          </form>
        )}

        {showWindow.verifyOtp && (
          <>
            <form onSubmit={handleVerifyOtp}>
              <h2 className="mb-3 mb-4" style={{ textAlign: "center" }}>
                Verify One Time Password
              </h2>
              <div className="form--group--login" style={{ alignItems: 'center' }}>
                <label htmlFor="Password"></label>
                <input
                  type="number"
                  name="otp"
                  placeholder="Enter One Time Password"
                  value={formData.otp}
                  onChange={handleChange}
                  required
                  style={{ width: '100%' }}
                />

                <button type="submit" className="custom--btn darkbtn-hover">
                  <span>Verify</span>
                </button>
              </div>
            </form>

            <p onClick={handleReSendOtp} className="resend--password" 
            // style={{ cursor: 'pointer', fontWeight: '600', textDecoration: 'underline', fontSize: '14px' }}
            style={{
              cursor: "pointer",
              fontWeight: "600",
              textDecoration: "underline",
              fontSize: "14px",
              textAlign: 'center',
              width: '100%'
            }}
            >
              Resend Code
            </p>
            <p 
            style={{fontSize:'14px',marginBottom:'0px',textAlign: 'center', width: '100%'}}
            // style={{fontSize:'14px',marginBottom:'0px'}}
            >Please verify your <b>email/SMS</b> OTP.</p>
          </>
        )}
        {showWindow.insertPassword && (
          <form onSubmit={handleSetPassword}>
            <h2 className="mb-3 mb-4" style={{ textAlign: "center" }}>
              Enter Password
            </h2>
            <div className="form--group--login">
              <label htmlFor="Password"></label>
              <div className="input-password relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  placeholder="Enter Password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
                <div className="passwod--visibitly" onClick={() => handleTogglePasswordVisibility('password')}>
                  {showPassword ? <PiEyeLight /> : <PiEyeSlash />}
                </div>
              </div>
            </div>
            <div className="form--group--login" style={{ alignItems: 'center' }}>
              <div className="input-password relative" style={{ width: '100%' }}>
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  name="confirmpassword"
                  placeholder="Enter Confirm Password"
                  value={formData.confirmpassword}
                  onChange={handleChange}
                  required
                  style={{ width: '100%' }}
                />
                <div className="passwod--visibitly" onClick={() => handleTogglePasswordVisibility('confirmPassword')}>
                  {showConfirmPassword ? <PiEyeLight /> : <PiEyeSlash />}
                </div>
              </div>
              <button type="submit" className="custom--btn darkbtn-hover">
                <span>Submit</span>
              </button>
            </div>

              <ul className="password--rules">
                <li className={`${hasValidLength && 'active'}`} >
                  {hasValidLength && hasValidLength === true ? <FaCheck className="check" /> : <IoClose className="close" />} Be a miminum of 8 characters
                </li>
                <li className={`${hasNumber && 'active'}`}>
                  {hasNumber && hasNumber === true ? <FaCheck className="check" /> : <IoClose className="close" />} Include at least one number (0-9)
                </li>
                <li className={`${hasUpperCase && 'active'}`}>
                  {hasUpperCase && hasUpperCase === true ? <FaCheck className="check" /> : <IoClose className="close" />} Include at least one uppercase letter (A-Z)
                </li>
                <li className={`${hasSpecialCharacter && 'active'}`}>
                  {hasSpecialCharacter && hasSpecialCharacter === true ? <FaCheck className="check" /> : <IoClose className="close" />} Include at least one special characters (!@#$%^&*)
                </li>
              </ul>

          </form>
        )}
      </div>
      {showTermsandConditonModel && (
        <TermsandConditonModel
          setformData={setformData}
          setshowTermsandConditonModel={setshowTermsandConditonModel}
        />
      )}
    </div>
  );
};

export default IsLoadingHOC(DirectorSignup);
