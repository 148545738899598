// src/components/Tour.js
import React from 'react';
import Joyride from 'react-joyride';
import { useTour } from '../contexts/TourContext';

const Tour = () => {
  const { currentStepIndex, isTourRunning, steps, nextStep, resetTour } = useTour();

  return (
    <Joyride
      steps={steps}
      stepIndex={currentStepIndex}
      run={isTourRunning}
      continuous
      showProgress
      showSkipButton
      callback={(data) => {
        const { action, index, type } = data;
        if (type === 'step:after' && action === 'next') {
          nextStep();
        }
        if (type === 'tour:end') {
          resetTour();
        }
      }}
      styles={{ options: { zIndex: 10000 } }}
    />
  );
};

export default Tour;


// // src/components/Tour.js
// import React from 'react';
// import Joyride from 'react-joyride';
// // import { useTour } from '../context/TourContext';
// import { useTour } from '../contexts/TourContext'


// const Tour = () => {
//   const { currentStepIndex, isTourRunning, steps, nextStep, resetTour } = useTour();

//   return (
//     <Joyride
//       steps={steps}
//       stepIndex={currentStepIndex}
//       run={isTourRunning}
//       continuous
//       showProgress
//       showSkipButton
//       callback={(data) => {
//         const { action, index, type } = data;
//         if (type === 'step:after' && action === 'next') {
//           nextStep();
//         }
//         if (type === 'tour:end') {
//           resetTour();
//         }
//       }}
//       styles={{ options: { zIndex: 10000 } }}
//     />
//   );
// };

// export default Tour;
