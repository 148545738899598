import { React, useEffect } from "react";
import { BsChatRightText } from "react-icons/bs";
import { Link } from "react-router-dom";
import IsLoadingHOC from "./IsLoadingHOC";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Error404 = () => {

  const { accessToken } = useSelector((state) => state.auth);
  const navigate = useNavigate(); 
  const location = useLocation();
  useEffect(() => {
    // if (accessToken) {
    //   navigate("/login");
    // }

    sessionStorage.setItem("redirectPath", location.pathname);
    if (!accessToken) {
      sessionStorage.setItem("redirectPath1", location.pathname);
    }
    setTimeout(() => {

      if(accessToken){

        if (location.pathname === "/your-task-list/completed") {
          // Navigate to the path and pass state as a second argument
          navigate("/your-task-list", { state: { id: "completed-task" } });
          // sessionStorage.removeItem("redirectPath");
        } else if (location.pathname === "/your-task-list/in-progress") {
          navigate("/your-task-list", { state: { id: "in-progress" } });
          // sessionStorage.removeItem("redirectPath");
        } else if (location.pathname === "/your-task-list/in-closed") {
          navigate("/your-task-list", { state: { id: "in-closed" } });
          // sessionStorage.removeItem("redirectPath");
        } else if (location.pathname === "/hustlr-jobs/completed-jobs") {
          navigate("/hustlr-jobs", { state: { id: "completed-jobs" } });
          sessionStorage.removeItem("redirectPath");
        } else if (location.pathname === "/hustlr-jobs/closed-jobs") {
          // alert("hi")
          navigate("/hustlr-jobs", { state: { id: "closed-jobs" } });
          // sessionStorage.removeItem("redirectPath");
        } else if (location.pathname === "/hustlr-jobs/in-progress") {
          navigate("/hustlr-jobs", { state: { id: "upcoming" } });
          // sessionStorage.removeItem("redirectPath");
        } else if (location.pathname === "/hustlr-jobs/cancelled-jobs") {
          navigate("/hustlr-jobs", { state: { id: "cancelled-jobs" } });
          // sessionStorage.removeItem("redirectPath");
        }
        // =====================================
        else if (location.pathname === "/home/incoming-jobs") {
          navigate("/home", { state: { id: "incoming-jobs" } });
          // sessionStorage.removeItem("redirectPath");
        } else if (location.pathname === "/home/incoming-jobs") {
          navigate("/home", { state: { id: "incoming-jobs" } });
          // sessionStorage.removeItem("redirectPath");
        } else if (location.pathname === "/home/interested-jobs") {
          navigate("/home", { state: { id: "interested-jobs" } });
          // sessionStorage.removeItem("redirectPath");
        } else if (location.pathname === "/home/saved-jobs") {
          navigate("/home", { state: { id: "saved-jobs" } });
          // sessionStorage.removeItem("redirectPath");
        } else if (location.pathname === "/home/new-jobs") {
          navigate("/home", { state: { id: "new-jobs" } });
          // sessionStorage.removeItem("redirectPath");
        }

        // else if (location.pathname.startsWith("/task/")) {
        //   if (resData.data.userType == "DIRECTOR"){
        //     navigate(location.pathname);  // Correctly pass the variable
        //   }

        //   sessionStorage.removeItem("redirectPath");
        // }
        // else if (location.pathname.startsWith("/job-details/")) {
        //   if (resData.data.userType == "HUSTLER"){
        //     navigate(redirectPath);  // Correctly pass the variable

        //   }
        //   sessionStorage.removeItem("redirectPath");
        // }
      else {
        navigate("/login");

        }
      }
      //================================
      if(location.pathname === "/create-task"){
        navigate("/login",{ state: { id: "director" } });
      }
      else{
        navigate("/login",{ state: { id: "hustler" } });
      }
     //============================================


    }, 0);
  }, [location.pathname, navigate]);

  return (
    <>
      <div className="thankyou--container">
        <div className="thankyou--col error--page">
          <img src="/assets/images/404-img.png" />
          <h1 className="text--dark playFairDisplay">000000ps</h1>
          <p>Page Not Found</p>
          <div className="button--thankyou">
            <Link
              to="/home"
              className="custom--btn ligghtbtn-hover transparent--btn minbutton"
            >
              <span>Home</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default IsLoadingHOC(Error404);
